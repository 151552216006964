
import React, {FC, useEffect, useState, Fragment} from 'react';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Login } from './components/login';
import { App } from './components/app';
import { Nomatch } from './components/common/nomatch';
import { Auth } from './services/auth';
import { Spinner } from 'react-bootstrap';

const Main: FC<{}> = () => {
    return ( <BrowserRouter>
       <Switch>
           <Route exact path="/">
             <Login />
           </Route>
           <ProtectedRoute>
             <App />
           </ProtectedRoute>
           <Route path="*">
             <Nomatch />
           </Route>
         </Switch>
      </BrowserRouter>
      )
}

const ProtectedRoute: FC<{}> = ({children}) => {
  return <Route path="/app" render={({location}) => {
    return Auth.isLoggedIn() ? children : <SyncRoute>{children}</SyncRoute>
  }}>
    
  </Route>
}

const SyncRoute: FC<{}> = ({children}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  useEffect(() => {
    const [sync] = Auth.sync(setSuccess, setLoading, setError);
    sync();
  }, []);
  if(loading){
    return <div className="sync-container">
      <h5>Sincronizzazione utente, attendere...</h5>
      <Spinner animation="border" variant="primary"></Spinner>
      </div>
  }
  if(error || !success){
    return <Redirect to={{pathname: '/'}}></Redirect>;
  }
  return <Fragment>{children}</Fragment>;
}

export default Main;
