
import React, { FC, useState, useEffect, useContext, useCallback } from 'react';
import { Row, Col, Button, Card, Badge, Alert } from 'react-bootstrap';
import { Loading } from '../common/loading';
import { buildGetFetch, buildPostFetch } from '../../services/base';
import { getAllExamCapabilities, EnumEntity } from '../../services/enums';
import { ExamContext } from './examcontext';
import { FaClock, FaTrash } from 'react-icons/fa';
import { EnumRow } from '../common/enumrow';
import Switch from 'react-switch';
import { Exam } from '../../types/exam';
import { Report } from '../../types/report';
import { Auth } from '../../services/auth';
import { RemindersService } from '../../services/reminderservice';

const ExamRecall: FC<{}> = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [report, setReport] = useState<Report>();
    const ctx:any = useContext(ExamContext);

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch(`/user/exam/${ctx.exam.id}/report`, { silent:true }, setReport, setLoading);
        fetch();
        return () => {
            cancel();
        };
    }, [ctx]);        

    console.log("report",report);
    return(
        <div>
            { /* bugid#8180 !loading && !report &&
                <Alert variant="warning">
                    <Alert.Heading>Attenzione</Alert.Heading>
                    <p>E' necessario prima predisporre il parere medico</p>
                </Alert>
            */}
            {!loading &&  
                <Row>                    
                    <Col>
                        { report && report.owner.id===Auth.user()?.id &&
                            <Card border="info" className="mb-2">
                                <Card.Header>
                                    <div>Impostazioni di richiamo</div>                            
                                </Card.Header>
                                <Card.Body>
                                    <ExamRecallSettings />
                                </Card.Body>
                            </Card>
                        }
                        { (!report || (report && report.owner.id!==Auth.user()?.id)) &&
                            <Card border="info" className="mb-2">
                                <Card.Header>
                                    <div>Impostazioni di richiamo</div>                            
                                </Card.Header>
                                <Card.Body>
                                    <Alert variant="warning" style={{marginTop:'20px'}}>
                                        <Alert.Heading>Attenzione</Alert.Heading>
                                        <p>I richiami sono stabiliti dal medico refertante</p>
                                    </Alert>
                                </Card.Body>
                            </Card>
                        }
                    </Col>
                    <Col>
                        <Card className="mb-2">
                            <Card.Header>
                                <div>Gestione permessi paziente</div>                            
                            </Card.Header>
                            <Card.Body>
                                <ExamCapabilityContainer />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    )
}

const ExamRecallSettings: FC<{}> = () => {
    const { patientData, isClosed, exam, onDataUpdate } = useContext(ExamContext);
    const [updating, setUpdating] = useState<boolean>(false);
    const [reminder, setReminder] = useState<number>(1);
    const ctx:any = useContext(ExamContext);

    const onSetReminder = () => {
        setUpdating(true);
        if(reminder==0)
            RemindersService.incrementReminders();
        const [fetch] = buildPostFetch(`/user/exam/${exam.id}/reminder/on`, {
            reminder
        });
        fetch().then(() => {
            setUpdating(false);
            onDataUpdate({
                examPath: "reminder",
                examValue: reminder
            });
        }).catch(() => {
            setUpdating(false);
        });
    }

    const onUnsetReminder = () => {
        setUpdating(true);
        if(exam.reminder==0)
            RemindersService.decrementReminders();
        const [fetch] = buildPostFetch(`/user/exam/${exam.id}/reminder/off`, {});
        fetch().then(() => {
            setUpdating(false);
            onDataUpdate({
                examPath: "reminder",
                examValue: null
            });
        }).catch(() => {
            setUpdating(false);
        });
    }

    return (
        <div className="mt-4">
            <Loading show={updating} backdrop={true}/>
            {!patientData.address?.email ? 
                <Alert variant="warning">
                    <Alert.Heading>Attenzione</Alert.Heading>
                    <p>Il paziente non ha una mail valida. Non può essere impostato un reminder</p>
                </Alert>
            : 
                <div>
                    <p>
                        Abilita il richiamo per una visita di controllo. 
                        Il paziente verrà in automatico notificato un mese prima della data per fissare una nuova visita di controllo
                    </p>
                    { exam.reminder!=null ?
                        <div>
                            <p>
                                <label>Richiamo impostato: </label> 
                                { exam.reminder==0 ?
                                    <Badge variant="info" className="ml-2">
                                        Subito
                                    </Badge>
                                    :
                                    <Badge variant="info" className="ml-2">
                                        {exam.reminder} 
                                        { exam.reminder>1 && <span>&nbsp;giorni</span>} {exam.reminder===1 && <span>&nbsp;giorno</span>} 
                                    </Badge>
                                }
                            </p>
                            {!isClosed && <Button variant="warning" type="button" onClick={onUnsetReminder}><FaTrash/> Rimuovi reminder</Button>}
                        </div>
                    : 
                        (isClosed ? <Alert>
                            <p>
                                Nessun richiamo impostato
                            </p>
                            </Alert> 
                        : 
                        <div>
                            <div className="form-group">
                                <label htmlFor="reminder-span">Richiamo</label>
                                <select defaultValue={reminder} onChange={(e: any) => {setReminder(e.target.value)}} id="reminder-span" className="form-control" name="reminder-span">
                                    <option value="0" label="Subito"/>
                                    <option value="15" label="15 giorni"/>
                                    <option value="30" label="1 mese"/>
                                    <option value="90" label="3 mesi"/>
                                    <option value="180" label="6 mesi"/>
                                    <option value="365" label="12 mesi"/>
                                    <option value="730" label="24 mesi"/>
                                </select>
                            </div>
                            <div className="form-group">
                                <Button variant="info" type="button" onClick={onSetReminder}><FaClock/> Conferma richiamo</Button>
                            </div>
                        </div>
                        )
                    }
                </div>
            }
        </div>            
    )
}

interface ExamCapabilityContainerProps {
}

const ExamCapabilityContainer: FC<ExamCapabilityContainerProps> = () => {
    const [switching, setSwitching] = useState(false);
    const { exam, isClosed } = useContext(ExamContext);
    const [enums, setEnums] = useState<Array<EnumEntity>>([]);
    const [capabilities, setCapabilities] = useState<any>({});
    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<Array<any>>(`/exam/${exam.id}/capability/`, {});
        Promise.all([getAllExamCapabilities(), fetch()]).then(([_c, patientCaps]) => {
            //@ts-ignore
            setEnums(_c);
            let caps: any = {};
            if (patientCaps !== null) {
                if(patientCaps.length!=0) {
                    patientCaps.forEach((cap: any) => {
                        caps[cap.capability.id] = true;
                    });
                }
            }
            setCapabilities(caps);
        })
        return () => {
            cancel();
        };
    }, [exam.id]);

    const activateAll= useCallback(() => {
        //console.log("enum",enums);
        //console.log("capabilities",capabilities);
        if(enums) {
            let oldCapabilities:any=Object.assign({},capabilities);

            let newCapabilites:any={};
            enums.forEach((cap:any) => {
                if(cap.id==4) return null; // bugid#6754                
                newCapabilites[cap.id] = true;                    
            });
            setCapabilities(newCapabilites);
            //console.log("n capabilities",newCapabilites);

            enums.forEach((cap:any) => {
                if(cap.id==4) return null; // bugid#6754
                //console.log("cap",cap);
                if(!oldCapabilities[cap.id]) {
                    const [fetch] = buildPostFetch(`/exam/${exam.id}/capability/${cap.id}/on`, {});
                    fetch()
                }
            })
        }
    },[enums,capabilities]);

    const onSwitchChange = useCallback((code: number, checked: boolean) => {
        setSwitching(true);
        if (checked) {
            //on
            const [fetch] = buildPostFetch(`/exam/${exam.id}/capability/${code}/on`, {});
            fetch().then(() => {
                setCapabilities((prev: any) => {
                    const clone = Object.assign({}, prev);
                    clone[code] = checked;
                    return clone;
                });
            }).catch(() => {

            }).finally(() => {
                setSwitching(false);
            });
        } else {
            //off
            const [fetch] = buildPostFetch(`/exam/${exam.id}/capability/${code}/off`, {});
            fetch().then(() => {
                setCapabilities((prev: any) => {
                    const clone = Object.assign({}, prev);
                    clone[code] = checked;
                    return clone;
                });
            }).catch(() => {

            }).finally(() => {
                setSwitching(false);
            });
        }
    }, [exam.id]);

    if (enums.length === 0) {
        return <div className="mt-4"><Loading show={true} message="Caricamento accessi paziente" /></div>
    }

    return (
        <div className="mb-3 mt-4">
            <Button variant="info" className="mt-3" onClick={activateAll}>
                Attiva tutti
            </Button>
            <hr/>
            {enums.map((c) => {
                if(c.id==4) return null; // bugid#6754
                return (<div key={c.code} className="mb-2 shadow-sm p-2 d-flex">
                    <Switch disabled={switching || isClosed} checked={capabilities[c.id] === true} onChange={(checked: boolean) => { onSwitchChange(c.id, checked) }}></Switch>
                    <div>
                        <EnumRow entity={c} />
                    </div>
                </div>)
            })}
        </div>);
}

export { ExamRecall };
