import React, { useState, useContext, useCallback, useEffect, Fragment, useRef } from 'react';
import { Alert, Card, Form } from 'react-bootstrap';
import { MdSend } from 'react-icons/md';
import { toast } from 'react-toastify';
import { buildGetFetch, buildPostFetch } from '../../services/base';
import { formatTS } from '../../utils/dateutils';
import { Loading } from '../common/loading';
import { LoadingButton } from '../common/loadingbutton';
import { ExamContext } from './examcontext';
import { EXAM_PERMISSION_ENUM } from '../../types/enums';
import { Auth } from '../../services/auth';
import { UnreadMessagesService } from '../../services/messageservice';

let cachedMessages:any=[]
let tout:any=null;

const Chat = () => {

    const messageInput = useRef<HTMLTextAreaElement>(null);
    const recipientSelect = useRef<HTMLSelectElement>(null);
    const messageList = useRef<HTMLDivElement>(null);
    const examCtx:any = useContext(ExamContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [messages,setMessages] = useState<any>([]);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [invitations, setInvitations] = useState<any>();
    const [userCapabilities, setUserCapabilities] = useState<any>({});
    const [hasReport, setHasReport] = useState<boolean>(false);
    const [loadingReport, setLoadingReport] = useState<boolean>(true);
    
    const handleResize=(scroll?:boolean) => {
        if(messageList.current) {
            const nh=window.innerHeight-420;
            messageList.current.style.height=nh + "px";
            if(scroll) {
                messageList.current.scrollTop = messageList.current.scrollHeight;
            }
        }
    }

    const scrollBottom=() => {
        setTimeout(() => {
            handleResize(true);
        },0);
    }

    const loadMessages=() => {
        const [fetch, cancel] = buildGetFetch<Array<any>>(`/user/exam/${examCtx.exam.id}/chat`,{ toastError: false }, 
                    (r) => { 
                        r.forEach((i: any) => {
                            if(i.was_not_read) {
                                UnreadMessagesService.decrementUnreadMessages();
                            }
                        });
                        if(cachedMessages && cachedMessages.length==r.length) {
                            console.log("cached messages",r.length);
                            //setMessages(r); 
                            setLoading(false);    
                        } else {            
                            console.log("NOT cached messages",r.length);
                            cachedMessages=r;                
                            setMessages(r); 
                            setLoading(false);    
                            scrollBottom();
                        }
                    });
        fetch();
        return () => {
            cancel();
        };
    }

    useEffect(() => {
        cachedMessages=[]
        handleResize();
        window.addEventListener('resize', () => {handleResize()})    
        loadMessages();    
    }, [messages.length]);    

    useEffect(() => {
        tout=setTimeout(() => {
            setupRefresh();
        },5000);
        return function cleanup() {
            if(tout) {
                console.log("cancelling chat timeout");
                clearTimeout(tout);
            }
        };
    },[]);

    const setupRefresh=() => {
        tout=setTimeout(() => {
            loadMessages();
            setupRefresh();
        },5000);
    }

    const loadInvitations=() => {
        const [fetch, cancel] = buildGetFetch<Array<any>>(`/user/exam/${examCtx.exam.id}/invitation`,{ toastError: false }, 
                    (r) => { 
                        let invs:any=[];
                        r.forEach((i: any) => {
                            if(i.accepted) invs.push(i);
                        });
                        setInvitations(invs);     
                    },() => {});
        fetch();
        return () => {
            cancel();
        };
    }

    useEffect(() => {
        loadInvitations();
    }, []);

    const loaduserCapabilities=() => {
        const [fetch, cancel] = buildGetFetch<Array<any>>(`/exam/${examCtx.exam.id}/capability/`, {},
            (patientCaps) => {
                let caps: any = {};
                if (patientCaps !== null) {
                    patientCaps.forEach((cap: any) => {
                        caps[cap.capability.code] = true;
                    });
                }
                setUserCapabilities(caps);
            });
        fetch();
        return () => {
            cancel();
        };
    }

    useEffect(() => {
        loaduserCapabilities();
    }, []);

    const checkReport=() => {
        const [fetch, cancel] = buildGetFetch<any>(`/user/exam/${examCtx.exam.id}/report`, { silent: true }, (data: any) => {
                setHasReport(data !== null);
                setLoadingReport(false);
                scrollBottom();
            },
            setLoadingReport,
            () => {
                setLoadingReport(false); // il servizio da errore se non c'è il parere medico
                scrollBottom();
            });        
        fetch();
    }

    useEffect(() => {
        checkReport();
    }, []);

    const decoratePatient=(p:any) => {
        if(examCtx.isOwner || Auth.isAdmin) {
            return p.surname + " " + p.name + " (Paziente)";
        } else {
            return "Paziente";
        }
    }

    const decorateUser=(p:any) => {
        return "Dott. " + p.surname + " " + p.name + " (Medico)";
    }

    let listItems=[];
    if(messages) {
        listItems=messages.map((m:any) => {
            let isForMe=false;
            let isFromMe=false;

            let recipientDesc="";
            if(m.target) {
                if(m.recipient_entity.toLowerCase()==="patient") {
                    recipientDesc=decoratePatient(m.target);
                } else if(m.recipient_entity.toLowerCase()==='user') {
                    if(m.recipient_id==Auth.user()?.id) {
                        isForMe=true;
                        recipientDesc="Te";
                    } else {
                        recipientDesc=decorateUser(m.target);
                    }
                }
            }

            let css="forMe";
            let senderDesc="";
            if(m.user_id) {
                if(m.user_id==Auth.user()?.id) {
                    isFromMe=true;
                    css="fromMe";
                    senderDesc="Tu";
                } else {
                    senderDesc=decorateUser(m.user);
                }
            } else if(m.patient_id) {
                senderDesc=decoratePatient(m.patient);
            }

            if(!isFromMe && !isForMe) {
                css="others";
            }

            return (
                <Fragment key={m.id}>
                    <Card className={"mt-1 callout " + css}>                        
                        <Card.Body>
                            <Card.Text>
                                <span className="who">
                                    <b>{senderDesc} <i className="chat-arrow">&rarr;</i> {recipientDesc}</b>
                                </span>
                                {m.text}
                                <span className="date">
                                    {formatTS(m.insert_ts)}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Fragment>
            );
        });
    }

    const saveMessage = useCallback(() => {
        if(recipientSelect.current) {
            if(recipientSelect.current.selectedIndex===0) {
                toast.error("Seleziona un destinatario per il messaggio");
                return;
            }            
        }

        setSubmitting(true);
        const msg = messageInput.current?.value;
        let r_id = examCtx.exam.owner_id;
        let r_type = "user";
        if(recipientSelect.current) {
            let ss = recipientSelect.current.value.split(",");
            r_id = ss[1];
            r_type = ss[0];
        }
        if (msg && msg.trim() !== "") {
            let pars={ 
                text: msg,
                recipient_id: r_id,
                recipient_type: r_type
            }
            const [post] = buildPostFetch<any>(`/user/exam/${examCtx.exam.id}/chat`, pars, { toastError: true }, () => { }, setSubmitting);
            post().then((m) => {
                if (m !== null) {
                    loadMessages();
                    toast.success("Comunicazione inviata");
                    //@ts-ignore
                    messageInput.current.value = '';
                }
            })
        } else {
            setSubmitting(false);
            toast.warning("Impossibile inviare un messaggio vuoto");
        }
    }, [loadMessages,examCtx.exam.id,examCtx.exam.owner_id]);

    // bugid#6724 - inseriamo nei destinatari il paziente solo se è stato prodotto il parere medico
    // in più (aggiungo) non lo inseriamo anche se non ha i diritti per leggere i messaggi
    // ma bugid#8180 - diamo la possibilità al paziente di comunicare anche senza parere medico

    let userInRecipients=true;
    if(userCapabilities && !userCapabilities['300']) userInRecipients=false;
    //if(!hasReport) userInRecipients=false;

    let optRecipients=[]
    if(examCtx.isOwner) {
        if(userInRecipients && !Auth.isTemporary() && (Auth.isSpecialist() || Auth.isAdmin())) {
            optRecipients.push(<option key="patient" value={"patient," + examCtx.patientData.patient.id} label={examCtx.patientData.patient.surname + ' ' + examCtx.patientData.patient.name + ' (Paziente)'}/>);
        }
        if(invitations) {
            invitations.forEach((i:any) => {
                optRecipients.push(<option value={"user," + i.user.id} key={i.user.id}>{'Dott. ' + i.user.surname + ' ' + i.user.name}</option>)
            });
        }
    } else {
        if(userInRecipients && !Auth.isTemporary() && (examCtx.hasPermission(EXAM_PERMISSION_ENUM.REPORT_WRITE) || Auth.isAdmin())) {
            optRecipients.push(<option key="patient" value={"patient," + examCtx.patientData.patient.id} label={'Paziente'}/>);
        }
        optRecipients.push(<option value={"user," + examCtx.exam.owner.id} key={examCtx.exam.owner.id}>{'Dott. ' + examCtx.exam.owner.surname + ' ' + examCtx.exam.owner.name}</option>);
    }

    return (
        <div>            
            {(loading || loadingReport) &&
                <Loading show={true} />
            }

            {!loading && !loadingReport &&            
                <Fragment>
                    <Card className="chat-insert" border="info" >
                        <Card.Body>
                            <Form>
                                <table style={{width:'100%'}}>
                                    <tbody>                                        
                                    <tr style={{verticalAlign:'top'}}>
                                        <td>
                                            { (examCtx.isOwner || examCtx.hasPermission(EXAM_PERMISSION_ENUM.REPORT_WRITE)) &&
                                                <Form.Group>
                                                    <select className="form-control" name="reminder-span" ref={recipientSelect}>
                                                        <option value={0} label="Seleziona destinatario"/>
                                                        { optRecipients }
                                                    </select>
                                                </Form.Group>
                                            }
                                            <Form.Group>
                                                <Form.Control rows={4} as="textarea" name="message_text" ref={messageInput} disabled={isSubmitting} />
                                            </Form.Group>
                                        </td>
                                        <td style={{width:'1px',paddingLeft:'1em',verticalAlign:'top'}}>
                                            <LoadingButton className="mt-4" block variant="info" loading={isSubmitting} disabled={isSubmitting} onClick={saveMessage}>
                                                <MdSend/>
                                            </LoadingButton>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Form>
                        </Card.Body>
                    </Card>
                    <br/>

                    <div className="chat-messages" ref={messageList}>
                        { /* bugid#8180 !Auth.isTemporary() && !hasReport &&
                            <Alert variant="warning">
                                Attenzione: non è stato ancora definito il parere medico, non è ancora possibile comunicare con il paziente
                            </Alert>
                        */ }

                        { !Auth.isTemporary() && hasReport && userCapabilities && !userCapabilities['300'] &&
                            <Alert variant="warning">
                                Attenzione: per il paziente non &egrave; attiva la visualizzazione dei messaggi.
                                Verifica i diritti impostati sulla tab <b>Richiamo</b>
                            </Alert>
                        }

                        { messages!=null && messages.length==0 &&
                            <Alert variant="success">Nessun messaggio</Alert>
                        }

                        { messages!=null && messages.length>0 && listItems }
                    </div>

                </Fragment>
            }
        </div>
    );
}

export { Chat };
