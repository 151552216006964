
import React, {FC} from 'react';
import { Badge } from 'react-bootstrap';

export const Genre: FC<{genre: "M" | "F"}> = ({genre}) => {
    if(genre === 'M'){
        return <Badge pill className="badge-male">Maschio</Badge>;
    }
    if(genre === 'F'){
        return <Badge pill className="badge-female">Femmina</Badge>;
    }
    return <Badge/>;
}