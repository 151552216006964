
import React, {FC, useState, useEffect, useMemo, useCallback, Fragment} from 'react';
import { ButtonGroup,Button, Card, Alert } from 'react-bootstrap';
import { Table } from '../common/table';
import { Auth } from '../../services/auth';
import { buildGetFetch } from '../../services/base';
import { Exam } from '../../types/exam';
import { formatTS } from '../../utils/dateutils';
import { StateBadge } from '../common/examstatebadge';
import { Id } from '../common/id';
import { FaEye } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Loading } from '../common/loading';

const ExamsWithUnreadMessages: FC<{}> = () => {

    const [exams, setExams] = useState<Array<Exam>>([]);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        setIsSearching(true);
        const [search] = buildGetFetch(`/user/exams_with_not_read_messages`, {}, setExams, setIsSearching);
        search();
    },[]);

    const history = useHistory();

    const goToExam = useCallback((props: any) => {
        history.push(`/app/exams/${props.row.original.id}#messages`);
    }, [history]);


    const isAdmin:boolean=Auth.isAdmin();
    const isTemporary:boolean=Auth.isTemporary();

    const columns = useMemo(() => {
        let cols= [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (props: any) => <Id id={props.value}/>
            },{
                Header: 'Stato',
                accessor: 'state',
                Cell: (props: any) => <StateBadge state={props.value}/>
            },{
                Header: 'Paziente',
                accessor: (d: any)=>{return d.patient.surname + " " + d.patient.name}
            },{
                Header: 'Visita',                
                accessor: (d: any)=> (
                    <div>
                        {d.title} {isAdmin && d.owner && <div><i>({d.owner.surname} {d.owner.name})</i></div>}
                    </div>)
            },{
                Header: 'Inizio',
                accessor: (d: any)=>{return formatTS(d.start_ts)}
            },{
                Header: 'Fine',
                accessor: (d: any)=>{return formatTS(d.end_ts)}
            },{
                Header: '',
                id: 'actions',     
                Cell: (props: any) => 
                    <div className="text-center">
                        <ButtonGroup aria-label="Azioni visita">
                            <Button title="Accedi alla visita" size="sm" variant="dark" onClick={() => {goToExam(props);}}><FaEye size={'1.5em'}/></Button>
                        </ButtonGroup>
                    </div>
            }
        ];
        if(isTemporary) {
            cols.splice(2,1);
        }
        return cols;
    }, [goToExam,isAdmin]);


    return (
        <div>
            <Loading show={isSearching}/>
            {!isSearching &&
                <Fragment>
                    <Card border="info" className="mb-4">
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                Visite con messaggi non letti
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {!isTemporary && exams.length>0 &&
                                <div className="mt-4 exam-table-container open-exams">
                                    <Table data={exams} columns={columns} />
                                </div>
                            }
                            {isTemporary && exams.length>0 &&
                                <div className="mt-4 exam-table-container temporary-user">
                                    <Table data={exams} columns={columns} />
                                </div>
                            }
                
                            { exams && exams.length===0 &&
                                <div className="mt-4">
                                    <Alert variant="success">Nessuna visita</Alert>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Fragment>
            }            
        </div>
    )
}

export {ExamsWithUnreadMessages};