
import React from 'react';
import { Exam } from '../../types/exam';
import { PatientData } from '../../types/patient';
import { EXAM_PERMISSION_ENUM } from '../../types/enums';

export interface Options {
    examPath?: string,
    examValue?: any,
    patientDataPath?: string,
    patientValue?: any
}

type ExamContextType = {
    onDataUpdate: (options?: Options) => void;
    exam: Exam;
    patientData: PatientData;
    changeTab: (key: string) => void;
    permissions: Array<number>;
    hasPermission: (permission: EXAM_PERMISSION_ENUM) => boolean;
    isOwner: boolean;
    isClosed: boolean;
    updating: boolean;
}

//@ts-ignore
const ExamContext = React.createContext<ExamContextType>();

export { ExamContext }