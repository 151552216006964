
import React, { FC, useState, useRef, useEffect, useCallback } from "react";
import { Media } from "../../types/media";
import { motion, useMotionValue } from "framer-motion";
import move from "array-move";
import { findIndex, Position } from "./find-index";
import { Button, Modal } from "react-bootstrap";
import { BASE, API_ROUTE } from "../../services/base";
import { withZoom, WithZoomProps } from "../common/zoom";

const MediaComparator: FC<{ close: () => void, media: Array<Media> }> = ({ close, media }) => {

    const [list, setList] = useState<Array<Media>>([]);
    const [zoom, setZoom] = useState<boolean>(false);

    useEffect(() => {
        setList(media);
    }, [media]);

    const positions = useRef<Position[]>([]).current;
    const setPosition = (i: number, offset: Position) => {
        positions[i] = offset;
    }

    const moveItem = (i: number, dragOffset: number) => {
        const targetIndex = findIndex(i, dragOffset, positions);
        if (targetIndex !== i) setList(move(list, i, targetIndex));
    };

    return (
        <Modal show={true} onHide={close} dialogClassName="modal-90w comparator">
            <Modal.Header closeButton>
                <div className="d-flex align-items-center">
                    <Button variant={zoom ? "light" : "info"} onClick={() => {setZoom(!zoom)}}>{zoom ? "Disattiva Zoom" : "Attiva Zoom"}</Button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="comparator-images">
                    {list.map((m: Media, i: number) => (
                        <DragImageWithZoom zoomLevel={1.5} zoomSize={300} zoomEnabled={zoom} key={m.id} index={i} path={`${BASE}${API_ROUTE}${m.path}${m.name}`} setPosition={setPosition} moveItem={moveItem} />
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    )
};

const onTop = { zIndex: 1 };
const flat = {
    zIndex: 0,
    transition: { delay: 0.3 }
};

interface DragImageProps {
    index: number;
    path: string;
    moveItem: (i: number, dragOffset: number) => void;
    setPosition: (i: number, offset: Position) => void;
}

const DragImage = React.forwardRef<HTMLImageElement, DragImageProps>(({ path, moveItem, index, setPosition }, forwardImageRef) => {
    const [isDragging, setDragging] = useState(false);
    const containerRef = useRef(null);

    const dragOriginX = useMotionValue(0);
    useEffect(() => {
        setPosition(index, {
            //@ts-ignore
            height: containerRef.current.offsetHeight,
            //@ts-ignore
            top: containerRef.current.offsetTop,
            //@ts-ignore
            width: containerRef.current.offsetWidth,
            //@ts-ignore
            left: containerRef.current.offsetLeft,
        });
    });

    const addRotation = (el:any,aa:any) => {
        let st = window.getComputedStyle(el, null);
        let tr = st.getPropertyValue("transform");
        let values = tr.split('(')[1].split(')')[0].split(',');
        let a = parseFloat(values[0]); 
        let b = parseFloat(values[1]);
//        let c = parseFloat(values[2]);
//        let d = parseFloat(values[3]);        
        let scale = Math.sqrt(a*a + b*b);
        let angle = Math.round(Math.atan2(b, a) * (180/Math.PI));
        angle=angle + aa;
        el.style.transform = 'rotate('+angle+'deg)'; 
    }

    const rotateRight = useCallback((e) => {
        if(!forwardImageRef) return;
        let rf:any=forwardImageRef;
        addRotation(rf.current,5);
    }, [forwardImageRef]);

    const rotateLeft = useCallback((e) => {
        if(!forwardImageRef) return;
        let rf:any=forwardImageRef;
        addRotation(rf.current,-5);
    }, [forwardImageRef]);

    return (
        <div className="img-drag-wrapper">
            
            <motion.div className="img-drag"
                ref={containerRef}
                initial={false}
                animate={isDragging ? onTop : flat}
                drag="x"
                dragOriginX={dragOriginX}
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragStart={() => setDragging(true)}
                onDragEnd={() => setDragging(false)}
                onDrag={(e, { point }) => moveItem(index, point.x)}
                positionTransition={({ delta }) => {
                    if (isDragging) {
                        // If we're dragging, we want to "undo" the items movement within the list
                        // by manipulating its dragOriginX. This will keep the item under the cursor,
                        // even though it's jumping around the DOM.
                        dragOriginX.set(dragOriginX.get() + delta.x);
                    }

                    return !isDragging;
                }}
            >
                <div>
                    <div className="img-tools-bar">
                        <div className="img-tools-bar-inner">
                            <Button className="img-tools" onClick={rotateLeft}>&#8634;</Button>
                            <Button className="img-tools" onClick={rotateRight}>&#8635;</Button>
                        </div>
                    </div>
                    <img ref={forwardImageRef} alt="Immagine" src={path}/>
                </div>
            </motion.div>
        </div>
    );
})

const DragImageWithZoom: FC<DragImageProps & WithZoomProps> = withZoom(DragImage);

export { MediaComparator }