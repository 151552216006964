
import React, { FC, useCallback } from "react"
import {  Table, Form } from "react-bootstrap"
import styled from 'styled-components';
import clsx from "clsx";
import { useFormikContext, useField } from "formik";
import { FotoType } from "../../types/medicaldata";

type FotoTypeFormProps = {
    name: string;
    foto_type: FotoType;
    editing: boolean;
}

const ColoredSquare: any = styled.div`
    height: 100px;
    width: 100px;
    border-radius: 5px;
    background-color: ${(props: any) => props.color};
    background-image: ${(props: any) => props.color2 ? `linear-gradient(to right, ${props.color}, ${props.color2})` : props.color};
    margin: auto;
`;

/**
 * Questo componenente va usato all'interno di un context formik!
 */
export const FotoTypeForm: FC<FotoTypeFormProps> = ({name, foto_type, editing}) => {

    const {setFieldValue} = useFormikContext();
    const [field] = useField(name);
    
    const onClick = useCallback((e: React.MouseEvent, selection: FotoType) => {
        if(editing){
            e.stopPropagation();
            setFieldValue("medicalData.nv_present",true);
            setFieldValue(field.name, selection);
        }
    }, [editing,field,setFieldValue]);

    return (
        <div>
            <Table bordered hover size="sm" className="app-table">
                <thead>
                    <tr>
                        <th className="text-center" colSpan={5}>FOTOTIPO</th>
                    </tr>
                    <tr>
                        <th style={{width:'80px'}}/>
                        <th>Fototipo</th>
                        <th className="text-center"><b>DESCRIZIONE</b><br/> <i>pelle, capelli, occhi</i></th>
                        <th className="text-center"><b>REAZIONE AL SOLE</b><br/> <i>abbronzatura e scottature</i></th>
                        <th className="text-center">ESEMPIO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={clsx(editing ? field.value === 1 && "selected" : foto_type === 1 && "selected")} onClick={(e) => {onClick(e, 1)}}>
                        <td className="text-center">
                            <Form.Check readOnly={true} name="foto-radio" type="radio" checked={editing ? field.value === 1 : foto_type === 1}></Form.Check>
                        </td>
                        <td>FOTOTIPO 1</td>
                        <td>Carnagione bianca, occhi e capelli biondi chiari o rossi, lentiggini</td>
                        <td>Non si abbronzano e si scottano sempre</td>
                        <td className="text-center">
                            <ColoredSquare color="#f6d0b1"/>
                        </td>
                    </tr>
                    <tr className={clsx(editing ? field.value === 2 && "selected" : foto_type === 2 && "selected")} onClick={(e) => {onClick(e, 2)}}>
                        <td className="text-center">
                            <Form.Check readOnly={true} name="foto-radio" type="radio" checked={editing ? field.value === 2 : foto_type === 2}></Form.Check></td>
                        <td>FOTOTIPO 2</td>
                        <td>Carnagione chiara, occhi chiari, capelli biondi o castani</td>
                        <td>Si abbronzano poco e con difficoltà e si scottano</td>
                        <td className="text-center">
                            <ColoredSquare color="#e8b68f"/>
                        </td>
                    </tr>
                    <tr className={clsx(editing ? field.value === 3 && "selected" : foto_type === 3 && "selected")} onClick={(e) => {onClick(e, 3)}}>
                        <td className="text-center">
                            <Form.Check readOnly={true} name="foto-radio" type="radio" checked={editing ? field.value === 3 : foto_type === 3}></Form.Check></td>
                        <td>FOTOTIPO 3</td>
                        <td>Pelle abbastanza scura, occhi scuri e capelli castani</td>
                        <td>Abbronzatura e possibili ustioni solari</td>
                        <td className="text-center">
                            <ColoredSquare color="#d29f7c"/>
                        </td>
                    </tr>
                    <tr className={clsx(editing ? field.value === 4 && "selected" : foto_type === 4 && "selected")} onClick={(e) => {onClick(e, 4)}}>
                        <td className="text-center">
                            <Form.Check readOnly={true} name="foto-radio" type="radio" checked={editing ? field.value === 4 : foto_type === 4}></Form.Check></td>
                        <td>FOTOTIPO 4</td>
                        <td>Carnagione particolarmente scura, occhi e capelli scuri</td>
                        <td>Abbronzatura rapida con rare scottature</td>
                        <td className="text-center">
                            <ColoredSquare color="#bc7950"/>
                        </td>
                    </tr>
                    <tr className={clsx(editing ? field.value === 5 && "selected" : foto_type === 5 && "selected")} onClick={(e) => {onClick(e, 5)}}>
                        <td className="text-center">
                            <Form.Check readOnly={true} name="foto-radio" type="radio" checked={editing ? field.value === 5 : foto_type === 5}></Form.Check></td>
                        <td>FOTOTIPO 5/6</td>
                        <td>Carnagione molto scura (etnie quali africani e indiani)</td>
                        <td>Pelle scura con rare o nessuna scottatura</td>
                        <td className="text-center">
                                <ColoredSquare color="#a65e2b" color2="#3b1f1b"/>
                        </td>
                    </tr>
                </tbody>
            </Table>    
        </div>
    )
}