
import React, {FC, useState, useMemo, useCallback, Fragment, useEffect} from 'react';
import { Button, Row, Col,Alert, InputGroup, ButtonGroup, Card, FormControl } from 'react-bootstrap';
import { useGetApi } from '../../utils/hooks';
import { Exam } from '../../types/exam';
import { buildGetFetch } from '../../services/base';
import { AcPatient } from '../common/ac_patient';
import { useHistory } from 'react-router-dom';
import { Loading } from '../common/loading';
import { formatTS } from '../../utils/dateutils';
import { Patient } from '../../types/patient';
import { toast } from 'react-toastify';
import { Id } from '../common/id';
import { FaEye, FaSearch } from 'react-icons/fa';
import { StateBadge } from '../common/examstatebadge';
import { EXAM_STATE } from '../../types/enums';
import { Auth } from '../../services/auth';
import { MdAdd } from 'react-icons/md';
import ExamSearch from './examsearch';
import { Table } from "../common/table";

const OldExams: FC<{}> = () => {
    const history = useHistory();
    const [exams, setExams] = useState<Array<Exam>>([]);
    const [isSearching, setIsSearching] = useState(false);

    const isAdmin:boolean=Auth.isAdmin();
    const isTemporary:boolean=Auth.isTemporary();

    const goToExam = useCallback((props: any) => {
        history.push(`/app/exams/${props.row.original.id}`);
    }, [history]);

    const userInSession:any=Auth.user();

    const columns = useMemo(() => {
        let cols= [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (props: any) => <Id id={props.value}/>
            },{
                Header: 'Stato',
                accessor: 'state',
                Cell: (props: any) => <StateBadge state={props.value}/>
            },{
                Header: 'Paziente',
                accessor: (d: any)=>{return d.patient.name + " " + d.patient.surname}
            },{
                Header: 'Visita',                
                accessor: (d: any)=> {
                    let invited=null;
                    if(d.confirmed_invitations && d.confirmed_invitations[0] && d.confirmed_invitations[0].user)
                        invited=d.confirmed_invitations[0].user;
                    let invitedName=null;
                    if(invited) {
                        if(invited.name || invited.surname) invitedName=invited.name + " " + invited.surname;
                        else invitedName="[" + invited.email + "]";
                    }
                    return (                    
                        <div>
                            {d.title} &nbsp;
                            { d.owner.id!==userInSession.id && 
                                <span className="badge badge-warning" style={{fontWeight:"normal",fontSize:"80%"}}>
                                    {!isAdmin && <Fragment>Consulenza / parere medico su invito di </Fragment>} {isAdmin && <Fragment>di </Fragment>} 
                                    {d.owner.surname} {d.owner.name}
                                </span>
                            }
                            { d.owner.id===userInSession.id && invitedName &&
                                <span className="badge badge-info" style={{fontWeight:"normal",fontSize:"80%"}}>
                                    Consulenza / parere medico richiesti al dott. {invitedName}
                                </span>
                            }
                        </div>
                    );
                }
            },{
                Header: 'Inizio',
                accessor: (d: any)=>{return formatTS(d.start_ts)}
            },{
                Header: 'Fine',
                accessor: (d: any)=>{return formatTS(d.end_ts)}
            },{
                Header: '',
                id: 'actions',     
                Cell: (props: any) => 
                    <div className="text-center">
                        <ButtonGroup aria-label="Azioni visita">
                            <Button title="Accedi alla visita" size="sm" variant="dark" onClick={() => {goToExam(props);}}><FaEye size={'1.5em'}/></Button>
                        </ButtonGroup>
                    </div>
            }
        ];
        if(isTemporary) {
            cols.splice(2,1);
        }
        return cols;
    }, [goToExam,isAdmin]);

    const doSearch = () => {
        setIsSearching(true);
        const [search] = buildGetFetch(`/user/exam/status/${EXAM_STATE.CLOSED}`, {}, setExams, setIsSearching);
        search();
    }

    useEffect(() => {
        doSearch();
    },[]);

    return (
        <div>
            <Loading show={isSearching}/>
            {!isSearching &&
                <Fragment>
                    <Card border="info" className="mb-4">
                        <Card.Header>Ricerca visite in base al paziente</Card.Header>
                        <Card.Body>
                            <div className="mt-4">
                                <ExamSearch filterState={75}></ExamSearch>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card border="info" className="mb-4">
                        <Card.Header>Visite chiuse</Card.Header>
                        <Card.Body>
                            <Table data={exams} columns={columns} />
                        </Card.Body>
                    </Card>
                </Fragment>
            }            
        </div>
    )
};

export default OldExams;
