
import React, { FC, useState, useEffect, useCallback } from "react";
import { Badge } from "react-bootstrap";
import { RemindersService, CHANGE_EVENT } from "../services/reminderservice";

export const RemindersBadge: FC<{}> = () => {

    const [reminders, setReminders] = useState<number>(RemindersService.getReminders());

    const onRemindersChange = useCallback(() => {        
        setReminders(RemindersService.getReminders());
    }, []);

    useEffect(() => {
        RemindersService.emitter().on(CHANGE_EVENT, onRemindersChange);
        return () => {
            RemindersService.emitter().off(CHANGE_EVENT, onRemindersChange);
        }
    }, [onRemindersChange]);

    if(reminders === 0){
        return (<span/>)
    }

    return (<Badge variant="warning">{reminders}</Badge>)

}