import React, { FC } from "react";
import { BASE, API_ROUTE } from "../../services/base";
import { AiOutlineFilePdf } from "react-icons/ai";
import { Card } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";

export const ExamPrivacy: FC<{}> = () => {

    return (
        <div>
            <Card border="info" >
                <Card.Header>
                    <div>Informativa privacy</div>
                </Card.Header>
                <Card.Body>
                    <div className="mt-4">                        
                        <a className="btn btn-info" target="_blank" href={`${BASE}${API_ROUTE}/docs/privacy`}>
                            <AiOutlineFilePdf size={"1.5rem"}/> Anteprima
                        </a>
                        &nbsp;&nbsp;&nbsp;
                        <a className="btn btn-info" download={`privacy.pdf`} href={`${BASE}${API_ROUTE}/docs/privacy-download`}>
                            <FaDownload size={"1.5rem"}/> Scarica
                        </a>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}
