import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Card, Modal } from "react-bootstrap"
import { Panel } from "../common/panel"
import { BASE, API_ROUTE, buildGetFetch } from '../../services/base';
import { Media } from "../../types/media";
import { Loading } from "../common/loading";
import { Report } from "../../types/report";

interface FastExamCardProps {
    exam: any,
    onClose: any
}

const FastExamCard: FC<FastExamCardProps> = ({exam,onClose}) => {

    const [images, setImages] = useState<Array<Media>>([]);
    const [loadingImages, setLoadingImages] = useState<boolean>(true);
    const [report, setReport] = useState<Report>();
    const [loadingReport, setLoadingReport] = useState<boolean>(true);

    const closePopup=useCallback(() => {
        if(onClose) onClose();
    },[onClose]);

    const refreshMedia=() => {
        const [fetch, cancel] = buildGetFetch<Array<Media>>(`/user/exam/${exam.id}/media`, {}, setImages, setLoadingImages);
        fetch();
        return () => {cancel();}
    };

    const loadReport=() => {
        const [fetch, cancel] = buildGetFetch(`/user/exam/${exam.id}/report`, { silent:true }, setReport, setLoadingReport);
        fetch();
        return () => {
            cancel();
        };
    };

    useEffect(() => {
        refreshMedia();
        loadReport();
    },[]);

    const imagesList = useMemo(() => {
        return images.filter((m) => m.user_id !== null).map((m: Media) => {
            return (
                <img key={m.id} src={`${BASE}${API_ROUTE}${m.path}${m.name}`} />
            );
        });
    }, [images]);

    return(
        <Modal size="xl" show={true} backdrop="static" keyboard={false}>
            <Modal.Header>
                <h5>Informazioni esame</h5>
            </Modal.Header>
            <Modal.Body>
                <Card border="info" className="mb-2 fastexamcard">
                    <Card.Body>
                        <Panel spacing="big" header="SCHEDA VISITA" defaultState="open">
                            <div>
                                <h5>Motivo</h5>
                                <div>{exam.title}</div>
                                <h5>Annotazioni</h5>
                                <div>{exam.note}</div>
                            </div>
                        </Panel>
                        <Panel spacing="big" header="IMMAGINI" defaultState="open">
                            <div>
                                {loadingImages ?
                                    <Loading message="Caricamento immagini..." show={true}/>
                                    :
                                    imagesList
                                }
                            </div>
                        </Panel>
                        <Panel spacing="big" header="PARERE MEDICO" defaultState="open">
                            <div>
                                {loadingReport ?
                                    <Loading message="Caricamento parere medico..." show={true}/>
                                    :    
                                    <Fragment>
                                        { report ?
                                            <div>
                                                <h5>Parere medico</h5>
                                                <div>{report.report}</div>
                                                <h5>Raccomandazioni</h5>
                                                <div>{report.recommendation}</div>
                                                <h5>Annotazioni</h5>
                                                <div>{report.reminder}</div>
                                            </div>
                                            :
                                            <div>
                                                Parere medico non ancora disponibile
                                            </div>
                                        }    
                                    </Fragment>                                
                                }
                            </div>
                        </Panel>
                    </Card.Body>
                </Card>        
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => closePopup()}>Chiudi</Button>
            </Modal.Footer>
        </Modal>
    )
}

export { FastExamCard }