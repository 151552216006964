
import React, {FC, useCallback, useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Loading } from '../common/loading';
import { PatientCard } from './patientcard';
import { Alert, Button } from 'react-bootstrap';
import { FcLeft } from 'react-icons/fc';
import { PatientData } from '../../types/patient';
import { buildGetFetch, buildPutFetch } from '../../services/base';
import { FaArrowLeft } from 'react-icons/fa';
import { Confirm } from '../common/confirm';
import { toast } from 'react-toastify';
import { MdLockOpen } from 'react-icons/md';

interface PatientDetailProps {
}

const PatientDetail: FC<PatientDetailProps> = () => {

    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [patientData, setPatientData] = useState<PatientData>();
    const [resetting, setResetting] = useState<boolean>(false);

    const { patientId } = useParams<{patientId: string}>();

    const history = useHistory();

    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<PatientData>(`/patient/${patientId}`, {}, setPatientData, setLoading, setError);
        fetch();
        return () => {cancel();};
    }, [patientId]);

    const reloadPatient = useCallback((newPatientData: PatientData) => {
        setPatientData(newPatientData);
    }, []);

    const doResetPassword = () => {
        const [doReset] = buildPutFetch<any>(`/patient/${patientId}/rebuildPassword`,{});
        doReset().then((data) => {
            toast.success('Reset effettuato. Credenziali inviate al paziente');
            setResetting(false);
        })
    };

    return (
        <div>
            <div className="action-menu">
                <div>
                    <Button title="Torna alla pagina pazienti" variant="primary" onClick={goBack}><FaArrowLeft/></Button>
                    <Button className="float-right" variant="warning" title="Reset password" onClick={() => { setResetting(true) }}><MdLockOpen /> Reset credenziali</Button>                            
                </div>
            </div>

            <Loading message="Caricamento paziente..." show={loading}></Loading>
            
            {resetting && <Confirm headerMessage="Reset password" 
                                message="Il sistema creerà una nuova password e la invierà al paziente sulla mail configurata. Attenzione: le credenziali preesistenti non saranno più valide. Continuare?"
                                onConfirm={doResetPassword} 
                                ok="Si" 
                                cancel="No" 
                                onCancel={() => {setResetting(false)}}/>}
            
            {error && 
                <Alert variant="danger">
                    <Alert.Heading>Paziente non trovato</Alert.Heading>
                    <hr/>
                    <p>Torna indietro <br/>
                        <Button type="button" variant="light" onClick={goBack}><FcLeft size={'2rem'}/></Button>
                    </p>
                </Alert>}

            {patientData && <PatientCard patientData={patientData} onUpdate={reloadPatient}></PatientCard>}
        </div>
    )
};

export {PatientDetail}