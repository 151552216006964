import React, { FC, useState, useContext, useCallback } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { buildPutFetch } from '../../services/base';
import { Exam } from '../../types/exam';
import { Confirm } from '../common/confirm';
import { ExamContext } from './examcontext';

interface ExamReopenProps {
    onStateChange: () =>void
}

const ExamReopen: FC<ExamReopenProps> = ({onStateChange}) => {

    const examCtx:any = useContext(ExamContext);
    const [askClose, setAskReopen] = useState<boolean>(false);

    const doReopen=useCallback(() => {
        const [closeFetch] = buildPutFetch<Exam>(`/user/exam/${examCtx.exam.id}/reopen`, {});
        closeFetch().then(() => {
            toast.success('Visita riaperta con successo');
            setAskReopen(false);
            onStateChange();
        })
    },[examCtx,onStateChange]);

    return (
        <div>
            <Alert variant="warning" title="Avvertimento">
                La visita dovrebbe essere riaperta solo per correggere eventuali errori.
            </Alert>
            <Button variant="warning" onClick={() => { setAskReopen(true) }}>Riapri visita</Button>
            { askClose && 
                <Confirm 
                    headerMessage="Conferma operazione" 
                    message="Riaprire la visita?"
                    additionalMessage="La visita dovrebbe essere riaperta solo per correggere eventuali errori"
                    onConfirm={() => { doReopen() }} 
                    ok="Esegui" 
                    cancel="Annulla" 
                    onCancel={() => { setAskReopen(false) }}/>
            }
        </div>
    );
}


export { ExamReopen };