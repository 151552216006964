
import React, {FC} from "react";
import { Modal, Button } from "react-bootstrap";
import { Callout } from "./callout";

type ConfirmProps = {
    headerMessage?: string;
    onConfirm: () => void;
    onCancel?: () => void;
    message: string;
    ok: string;
    cancel: string;
    additionalMessage?: string;
}

const Confirm: FC<ConfirmProps> = ({onConfirm, onCancel, headerMessage, ok, cancel, message, additionalMessage}) => {
    return <Modal size="lg" show={true} backdrop="static" keyboard={false}>
        <Modal.Header>
            <h5>{headerMessage || 'Richiesta conferma'}</h5>
        </Modal.Header>
        <Modal.Body>
            <Callout className="p-3">
                <div className="d-flex justify-content-center flex-column">
                    <div className="mb-3">{message}</div>
                    { additionalMessage &&
                        <div className="mb-3">
                            <i>{additionalMessage}</i>
                        </div>
                    }
                    <div className="d-flex justify-content-center" style={{width: '100%'}}>
                        <Button variant="success" onClick={onConfirm} className="mr-3">{ok}</Button> 
                        <Button variant="danger" onClick={onCancel}>{cancel}</Button> 
                    </div>
                </div>
            </Callout>
        </Modal.Body>
    </Modal>
}

export {Confirm};