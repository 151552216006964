
import React, { FC } from "react";
import { FaVenus, FaMars } from "react-icons/fa"
import { useFormikContext, useField } from "formik"
import Switch from 'react-switch'
import {get} from "lodash";

export const YesNo: FC<any> = (props) => {

    return (
        <Switch handleDiameter={25} 
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={26} width={55}
            uncheckedIcon={<div className="text-center yesno-label no">NO</div>} 
            checkedIcon={<div className="text-center yesno-label yes">SI</div>} 
            onChange={(v: any) => {
                props.onChange(v);
            }} checked={props.checked} disabled={props.disabled} className="react-switch yesno"/>
    )
}