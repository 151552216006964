
import React, { FC, Fragment, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { ExamContext } from "./examcontext";
import { PatientCard } from "../patient/patientcard";
import { PatientData } from "../../types/patient";

const PatientHome: FC = () => {
    const { patientData, isOwner, isClosed, onDataUpdate } = useContext(ExamContext);

    const onPatientUpdate = (pd: PatientData) => {
        onDataUpdate({
            patientValue: pd,
            patientDataPath: "$ALL$"
        })
    }

    return (<Fragment>
        
        <Row>
            <Col lg={12}>
                <PatientCard canEdit={!isClosed && isOwner} patientData={patientData} onUpdate={onPatientUpdate}></PatientCard>
            </Col>
        </Row>
    </Fragment>)
}


export { PatientHome };