
import Echo from "laravel-echo";
import Axios from "axios";
import { EventEmitter } from "events";
import { API_ROUTE } from "./base";

declare global {
    interface Window {
        Pusher:any;
    }
}
//dont touch this
window.Pusher = require('pusher-js');

const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
const PUSHER_HOST = process.env.REACT_APP_PUSHER_HOST;
const PUSHER_PATH = process.env.REACT_APP_PUSHER_PATH;
const PUSHER_TLS = process.env.REACT_APP_PUSHER_TLS === 'true';
const PUSHER_ENCRYPT = process.env.REACT_APP_PUSHER_ENCRYPT === 'true';
const PUSHER_PORT = process.env.REACT_APP_PUSHER_PORT ? parseInt(process.env.REACT_APP_PUSHER_PORT || '6001') : null;

interface Channel {
    type: "private" | "public" | "presence",
    name: string;
    events: {
        [key: string]: string;
    }
}

const ADMIN_CHANNEL: Channel = {
    type: "public",
    name: "notification",
    events: {
        Message: "AdminMessage"
    }
}

const USER_CHANNEL: Channel = {
    type: "private",
    name: "user",
    events: {
        ExamCreation: "ExamCreation",
        ExamClosure: "ExamClosure",
        Message: "UserMessage",
        ImageAdd: "ImageAdd",
        ImageRemove: "ImageRemove",
        MediaComment: "MediaComment",
        UserInvitation: "UserInvitation",
        UnreadMessage: "UnreadMessage"
    }
}

 class NotificationService extends EventEmitter {

    private echo: any;
    private ready: boolean = false;
    private userId: number = 0;
    private subscribeToAdmin: boolean = true;
    private subscribeToUser: boolean = true;

    constructor(){
        super();
        this.ready = process.env.REACT_APP_NOTIFICATIONS === 'true';
    }

    private isReady(): Promise<void> {
         if(this.ready){
             return Promise.resolve();
         }
         return Promise.reject();
    }

    public disconnect(): void {
        if(this.ready){
            this.echo.disconnect();
        }
    }

    public connect(userId: number): void{
        this.userId = userId;
        this.isReady().then(() => {
            let options: any = {
                broadcaster: 'pusher',
                key: PUSHER_KEY,
                wsHost: PUSHER_HOST,
                disableStats: true,
                forceTLS: PUSHER_TLS,
                encrypted: PUSHER_ENCRYPT,
                authorizer: (channel: any, options: any) => {
                    return {
                        authorize: (socketId: any, callback: any) => {
                            Axios.post(`${API_ROUTE}/broadcasting/auth`, {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                            .then((response: any) => {
                                callback(false, response.data);
                            })
                            .catch((error: any) => {
                                callback(true, error);
                            });
                        }
                    };
                },
            };
            if(PUSHER_PATH && PUSHER_PATH !== ''){
                options.wsPath = PUSHER_PATH;
            }
            if(PUSHER_PORT){
                options.wsPort = PUSHER_PORT;
            }
            this.echo = new Echo(options);
            if(this.subscribeToAdmin){
                this.subscribe(ADMIN_CHANNEL);
            }
            if(this.subscribeToUser){
                this.subscribe(USER_CHANNEL);
            }
           /* this.echo.private(`${CHANNELS.USER.toString()}.${this.userId}`).listen(EVENTS.EXAM_CREATION.toString(), (e: any) => {
                this.emit(EVENTS.EXAM_CREATION, e);
            });
            this.echo.channel(CHANNELS.ADMIN.toString()).listen(EVENTS.EXAM_CREATION.toString(), (e: any) => {
                this.emit(EVENTS.EXAM_CREATION, e);
            });*/
        })
    }

    private subscribe(channel: Channel){
        switch(channel.type){
            case "presence": {
                Object.keys(channel.events).forEach((eventKey: string) => {
                    this.echo.presence(channel.name).listen(channel.events[eventKey], (e: any) => {
                        this.emit(channel.events[eventKey], e);
                    });
                });
                break;
            }
            case "private": {
                Object.keys(channel.events).forEach((eventKey: string) => {
                    this.echo.private(`${channel.name}.${this.userId}`).listen(channel.events[eventKey], (e: any) => {
                        console.log(`Receiving on the private channel ${channel.name} event ${eventKey}`);
                        console.log(e);
                        this.emit(channel.events[eventKey], e);
                    });
                });
                break;

            }
            case "public": {
                Object.keys(channel.events).forEach((eventKey: string) => {
                    this.echo.channel(channel.name).listen(channel.events[eventKey], (e: any) => {
                        this.emit(channel.events[eventKey], e);
                    });

                });
                break;

            }
        }
    }
}

const INSTANCE = new NotificationService();

export {INSTANCE as NotificationService, ADMIN_CHANNEL, USER_CHANNEL};