
import { ResponsiveCalendar } from "@nivo/calendar";
import { format, sub } from "date-fns";
import React, { FC, useMemo } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { useGetApi } from "../../utils/hooks";
import { Loading } from "../common/loading";

type CalendarResult = {
    data: Array<any>;
    all_exams: number;
    last_week_exams: number;
    last_month_exams: number;
    today_exams: number;
}

export const Dashboard: FC<{}> = () => {

    const from = useMemo(() => {
        return format(sub(new Date(), {months: 6}), "yyyy-MM-dd");
    }, []);

    const to = useMemo(() => {
        return format(new Date(), "yyyy-MM-dd");
    }, []);

    const [error, loading, result] = useGetApi<CalendarResult>('/user/calendar');

    return (<div>

        <div className="alert alert-light">
            <h5 className="text-center">Calendario visite <small>(ultimi 6 mesi)</small></h5>
            <Loading backdrop={false} show={loading} message="Caricamento..."/>
            <div style={{height:'400px'}}>
            {result &&     
                <div style={{width:'100%',height:'100%',maxWidth:'1000px',margin:'auto'}}>    
                    <ResponsiveCalendar minValue={1} maxValue={10}
                        data={result.data} from={from} to={to}
                        emptyColor="#eeeeee"
                        colors={['#d4f0cc', '#8fd487','#41ab5d', '#006d2c', '#003816' ]}
                        margin={{ top: 10, right: 40, bottom: 40, left: 40 }}
                        yearSpacing={40} monthBorderColor="#ffffff" dayBorderWidth={2} dayBorderColor="#ffffff"
                        legends={[
                            {
                                anchor: 'bottom-right',
                                direction: 'row',
                                translateY: 36,
                                itemCount: 4,
                                itemWidth: 42,
                                itemHeight: 36,
                                itemsSpacing: 14,
                                itemDirection: 'right-to-left'
                            }
                        ]}
                        // @ts-ignore
                        tooltip={function(e){ return `Visite in data ${e.day}: ${e.value}`}}
                        monthLegend={(year: number, month: number, date: Date) => {
                            switch (month) {
                                case 0: {
                                    return "Gen";
                                }
                                case 1: {
                                    return "Feb";
                                }
                                case 2: {
                                    return "Mar";
                                }
                                case 3: {
                                    return "Apr";
                                }
                                case 4: {
                                    return "Mag";
                                }
                                case 5: {
                                    return "Giu";
                                }
                                case 6: {
                                    return "Lug";
                                }
                                case 7: {
                                    return "Ago";
                                }
                                case 8: {
                                    return "Set";
                                }
                                case 9: {
                                    return "Ott";
                                }
                                case 10: {
                                    return "Nov";
                                }
                                case 11: {
                                    return "Dic";
                                }
                                default: {
                                    return "";
                                }
                            }
                        }} />
                    </div>
                }
            </div>
        </div>
        <div className="mt-4">
            <Row>
                <Col md={3} sm={6} xs={12}>
                    <div className="alert alert-light shadow-sm text-center p-2">
                        <label>Visite totali</label><br/>
                        <h3><Badge variant="primary">{result?.all_exams}</Badge></h3>
                    </div>
                </Col>
                <Col md={3} sm={6} xs={12}>
                    <div className="alert alert-light shadow-sm text-center p-2">
                        <label>Visite ultimo mese</label><br/>
                        <h3><Badge variant="primary">{result?.last_month_exams}</Badge></h3>
                    </div>
                    
                </Col>
                <Col md={3} sm={6} xs={12}>
                    <div className="alert alert-light shadow-sm text-center p-2">
                        <label>Visite ultima settimana</label><br/>
                        <h3><Badge variant="primary">{result?.last_week_exams}</Badge></h3>
                    </div>
                </Col>
                <Col md={3} sm={6} xs={12}>
                    <div className="alert alert-light shadow-sm text-center p-2">
                        <label>Visite oggi</label><br/>
                        <h3><Badge variant="primary">{result?.today_exams}</Badge></h3>
                    </div>
                </Col>
            </Row>
        </div>
    </div>)    
}