import React, { FC, useState, useCallback, Fragment, useContext } from 'react';
import { Card, Alert, Form, Button, ButtonGroup, Col } from 'react-bootstrap';
import { formatTS } from '../../utils/dateutils';
import { Formik, FormikHelpers } from 'formik';
import { MdEdit, MdPets, MdSave } from 'react-icons/md';
import clsx from 'clsx';
import { ExamContext } from '../exam/examcontext';
import { Logs } from '../common/logs';
import { Exam } from '../../types/exam';
import { cloneDeep } from 'lodash';
import { StickyBar } from '../common/stickybar';
import { Loading } from '../common/loading';
import { Confirm } from '../common/confirm';
import { buildPutFetch } from '../../services/base';
import { toast } from 'react-toastify';

let reset: any = null;

export const ExamCard: FC<any> = () => {

    const examCtx:any = useContext(ExamContext);
    const [showingLogs, setShowingLogs] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);
    const [cancelConfirm, setCancelConfirm] = useState<boolean>(false);    

    const onSubmit = useCallback((values: Exam, helpers: FormikHelpers<Exam>) => {
        setUpdating(true);
        var pars:any={
            title: values.title,
            note: values.note
        }
        const [fetch] = buildPutFetch<Exam>(`/exam/${examCtx.exam.id}/`, pars,{},(x) => {
            values=x;
            examCtx.exam.title=x.title;
            examCtx.exam.note=x.note;
            setUpdating(false);
            setEditing(false);
            toast.success("Scheda aggiornata con successo");
        });
        fetch();
    }, [examCtx]);

    const cancelForm = () => {
        reset();
        setEditing(false); 
        setCancelConfirm(false);
    };
    
    return (
        <Fragment>
            <Card border="info" className="mb-2">
                <Card.Header className={clsx(!editing && "sticky")}>
                    <div className="d-flex justify-content-between align-items-center">
                        Visita
                        {examCtx.isOwner && 
                            <Button className="ml-2" disabled={editing} variant="info" title="Attiva modifica" onClick={() => { setEditing(true) }}><MdEdit /> Inserisci</Button>
                        }
                    </div>
                </Card.Header>
                <Card.Body>
                    <Loading show={updating} backdrop={true} message="Salvataggio in corso..."/>

                    {cancelConfirm && <Confirm headerMessage="Hai effettuato delle modifiche alla scheda" 
                                    message="Sei sicuro di voler annullare le modifiche senza salvare?"
                                    onConfirm={cancelForm} 
                                    ok="Si" 
                                    cancel="No" 
                                    onCancel={() => {setCancelConfirm(false)}}/>}                                

                    <Formik 
                        onSubmit={onSubmit}
                        initialValues={cloneDeep(examCtx.exam)}>
                        {({ resetForm, dirty, values, errors, touched, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                            <Form noValidate onSubmit={handleSubmit}>                                
                                <Form.Row>
                                    <Form.Group as={Col} controlId="titleField">
                                        <Form.Label className="app-form-label">MOTIVO DELLA VISITA:</Form.Label>
                                        { editing && 
                                            <Fragment>
                                                <Form.Control autoComplete="off" type="text" placeholder="Inserisci la motivazione" name="title" value={values.title}
                                                    onChange={handleChange} isValid={touched.title && !errors.title} isInvalid={touched.title && !!errors.title} />
                                                <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                                            </Fragment>
                                        }
                                        { !editing &&
                                            <div className="form-value">{values.title}</div>
                                        }
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group controlId="notes">
                                    <Form.Label className="app-form-label">ANNOTAZIONI:</Form.Label>
                                    { editing && 
                                        <Form.Control rows={5} as="textarea" type="text" placeholder="Inserisci eventuali annotazioni" name="note" value={values.note}
                                            onChange={handleChange} />
                                    }
                                    { !editing &&
                                            <div className="form-value">{values.note}</div>
                                    }
                                </Form.Group>
                                <StickyBar top={49}>
                                    {editing && <Alert variant="warning">
                                        <div className="text-center">
                                            <p>Modifica attiva</p>
                                            <ButtonGroup>
                                                <Button type="button" onClick={() => {
                                                    reset = resetForm;
                                                    if(dirty){
                                                        setCancelConfirm(true);
                                                    } else {
                                                        cancelForm();
                                                    }
                                                }} variant="warning"><MdSave /> Annulla modifiche</Button>
                                                <Button disabled={!dirty} variant="success" type="submit"><MdSave /> Aggiorna scheda visita</Button>
                                            </ButtonGroup>
                                        </div>
                                    </Alert>}
                                </StickyBar>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            Data inserimento: {formatTS(examCtx.exam.insert_ts)}
                        </div>
                        <div>
                            <span>
                                <a className="btn btn-xs btn-logs" onClick={()=> setShowingLogs(true)}>
                                    <MdPets />
                                </a>
                            </span>
                        </div>
                    </div>            
                </Card.Footer>
            </Card>

            { showingLogs &&
                <Logs exam={examCtx.exam} onClose={()=>setShowingLogs(false)} title="Log modifiche visita" />
            }
        </Fragment>        
    )
}

