
import { Group } from "three";
import { BodyModel, SKIN_TYPES } from "./models/bodymodel";
import hrtime from 'browser-process-hrtime';
import { Male } from "./models/male";
import { Female } from "./models/female";
import { OBJLoader } from "../../libs/loader";

export const CacheLoader = (() => {

    const loader = new OBJLoader();
    const _modelCache = new Map<string, any>();

    const load = (file: string) => {
        const time = hrtime();
        return new Promise((resolve, reject) => {
            const c = _modelCache.get(file);
            if(c){
                //const end = hrtime(time);
                //const timeInMs = (end[0]* 1000000000 + end[1]) / 1000000;
                console.log(`Loaded from cache ${file}`);
                resolve(c);
                return;
            }
            //@ts-ignore
            loader.load(
                // resource URL
                `${process.env.PUBLIC_URL}/${file}`,
                (object: Group) => {
                    const end = hrtime(time);
                    const timeInMs = (end[0]* 1000000000 + end[1]) / 1000000;
                    console.log(`Cached ${file} in ${timeInMs} ms`);
                    resolve(object);
                    _modelCache.set(file, object);
                    return;
                },
                (xhr:any) => { },
                (error:any) => {
                    reject(error);
                 }
            );
        });
    }

    return {
        preCache: () => {
            const m = new Male(SKIN_TYPES.F1);
            const f = new Female(SKIN_TYPES.F1);
            load(m.file);
            load(f.file)
        },
        getModel: (model: BodyModel): Promise<any> => {
            return load(model.file);
        }
    }
})();