

import React, {FC, Fragment} from 'react';
import { Badge } from 'react-bootstrap';
import { EnumEntity } from '../../services/enums';

export const EnumRow: FC<{entity: EnumEntity,showCode?:boolean}> = ({entity,showCode}) => {
    return <Fragment>
        { showCode && <Badge variant="dark">{entity.code}</Badge> }
        <span className="ml-3">{entity.desc}</span>
    </Fragment>
}