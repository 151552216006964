
import React, {FC, useCallback, useState, useEffect} from "react";
import { Loading } from "../common/loading";
import { Invitation } from "../../types/invitation";
import { Alert, Badge, Button } from "react-bootstrap";
import { isPastTS, formatTS } from "../../utils/dateutils";
import { UserBadge } from "../common/userbadge";
import { useHistory } from "react-router-dom";
import { buildPostFetch, buildGetFetch } from "../../services/base";
import { LoadingButton } from "../common/loadingbutton";
import { toast } from "react-toastify";
import { InviteBadge } from "../common/invitebadge";
import { InviteService } from "../../services/inviteservice";
import { EMOJI } from "../../utils/emoji";
import Switch from 'react-switch'
import { fakeId } from "../../utils/faker";
import { EXAM_STATE } from "../../types/enums";

type InvitationsProps = {};

const Invitations: FC<InvitationsProps> = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [invitations, setInvitations] = useState<Array<Invitation>>([]);
    const [showAll, setShowAll] = useState(false);

    const loadInvitations = (showAll?:boolean) => {
        let query:string="";
        if(showAll) {
            query="?filter=all";
        }
        const [fetch] = buildGetFetch<Array<Invitation>>('/user/invitation' + query, {}, setInvitations, setLoading, setError);
        fetch();
    };

    useEffect(() => {
        loadInvitations();
    }, []);

    const onReload = useCallback(() => {
        loadInvitations();
    },[]);

    const afterUpdate = useCallback((updated: Invitation) => {
        setInvitations((prev) => {
            const index = prev.findIndex((i: Invitation)=> i.id === updated.id);
            if(index >= 0){
                prev[index] = updated;
            }
            return [...prev];
        });
    }, []);

    const handleFilterChange = useCallback((v:any) => {
        setShowAll(v); 
        setLoading(true);
        setTimeout(() => {
            loadInvitations(v);
        },100);
    },[]);

    if(error){
        return (<Alert variant="danger">
            <Alert.Heading>
                {EMOJI.SAD} Attenzione
            </Alert.Heading>
            <hr/>
            <p>
                Impossibile ottenere la lista degli inviti, contatta il supporto
            </p>
        </Alert>)
    }
    if(loading){     
     
        return <Loading show={true} message="Caricamento inviti..."/>
    }
    if(invitations){
        if(invitations.length === 0){
            return <div>
                <h4>Inviti</h4>
                <Alert variant="info">
                    <Alert.Heading>Nessun invito presente</Alert.Heading> 
                    <hr/>
                    <p>Ogni volta che verrai invitato a partecipare ad una visita l'invito comparirà qui e potrai decidere se accettarlo o rifiutarlo</p>
                </Alert>
                </div>
        }
        return (<div>
            <h4>Inviti</h4>
            <Alert variant="info">
                <p>Qui trovi tutti gli inviti che hai ricevuto</p>
                <span style={{position:"relative",top:"-10px"}}>Mostra tutti (anche quelli relativi a visite già chiuse):</span> <Switch onChange={(v: any) => {handleFilterChange(v)}} checked={showAll} className="react-switch"/> 
            </Alert>
            {invitations.map((i: Invitation) => {
                return <div key={i.id}>
                    <InvitationRow invitation={i} onReload={() => {onReload()}} onRefuse={(updated: Invitation) => {afterUpdate(updated)}} onAccept={(updated: Invitation) => {afterUpdate(updated)}}/>
                </div>
            })}
        </div>)
    }
    return (<div></div>)
}

type InvitationRowProps = {
    invitation: Invitation;
    onAccept: (i: Invitation) => void;
    onRefuse: (i: Invitation) => void;
    onReload: () => void;
}

const InvitationRow: FC<InvitationRowProps> = ({invitation, onAccept, onRefuse, onReload}) => {
    const history = useHistory();
    const [sendingAccept, setSendingAccept] = useState(false);
    const [sendingRefuse, setSendingRefuse] = useState(false);
    
    const goToExam = useCallback(() => {
        history.push(`/app/exams/${invitation.exam_id}`);
    }, [history,invitation.exam_id]);

    const onAcceptInvitation = useCallback(() => {
        setSendingAccept(true);
        const postOptions={
            toastError: false,
            silent:true,
            errorCallback: (ex:any) => {
                if(ex?.response?.status==400) {
                    toast.error(ex?.response?.data); 
                }                
                onReload();                   
            }
        }
        const [sendAccept] = buildPostFetch<Invitation>(`/user/invitation/${invitation.id}/accept`, {}, postOptions);
        sendAccept().then((res) => {
            setSendingAccept(false);
            if(res !== null){
                onAccept(res);
                toast.info("Invito accettato");
                InviteService.decrementInvites();
            } 
        });
    }, [invitation.id,onAccept]);

    const onRefuseInvitation = useCallback(() => {
        setSendingRefuse(true);
        const [sendRefuse] = buildPostFetch<Invitation>(`/user/invitation/${invitation.id}/refuse`, {}, {toastError: true});
        sendRefuse().then((res) => {
            setSendingRefuse(false);
            if(res !== null){
                onRefuse(res);
                toast.info("Invito rifiutato");
                InviteService.decrementInvites();
            } 
        });
    }, [invitation.id,onRefuse]);

    const {accepted, refused, accepted_by_other} = invitation;
    
    useEffect(() => {
    }, [accepted, refused, accepted_by_other]);

    if(invitation.accepted_by_other){
        return (
            <Alert variant="light" className="shadow-sm">
                <div className="d-flex justify-content-between">
                    <div>
                        Invito accettato da altro medico : <InviteBadge invitation={invitation}/> 
                    </div>
                    <div>
                        {formatTS(invitation.insert_ts)}
                    </div>
                </div>
            </Alert>
        )
    }
    if(invitation.accepted){
        return (
            <Alert variant="light" className="shadow-sm">
                <div className="d-flex justify-content-between">
                    <div>
                        Invito da parte di <UserBadge user={invitation.exam.owner}/> : <InviteBadge invitation={invitation}/>
                        {(invitation.exam.state==EXAM_STATE.CLOSED ||  invitation.exam.state==EXAM_STATE.ARCHIVED) && 
                            <Badge variant="warning">Visita chiusa</Badge>
                        }
                    </div>
                    <div>
                        <Button className="mr-2" variant="success" size="sm" onClick={goToExam}>Vai alla visita {fakeId(invitation.exam.id)}</Button>
                        {formatTS(invitation.insert_ts)}
                    </div>
                </div>
            </Alert>
        )
    }
    if(invitation.refused){
        return (
            <Alert variant="light" className="shadow-sm">
                <div className="d-flex justify-content-between">
                    <div>
                        Invito da parte di <UserBadge user={invitation.exam.owner}/> : <InviteBadge invitation={invitation}/>  
                    </div>
                    <div>
                        {formatTS(invitation.insert_ts)}
                    </div>
                </div>
            </Alert>
        )
    }
    if(isPastTS(invitation.expiration_ts)){
        return (
            <Alert variant="light" className="shadow-sm">
                <div className="d-flex justify-content-between">
                    <div>
                        Invito da parte di <UserBadge user={invitation.exam.owner}/> : <InviteBadge invitation={invitation}/>  
                    </div>
                    <div>
                        {formatTS(invitation.insert_ts)}
                    </div>
                </div>
            </Alert>
        )
    }
    return (
        <Alert variant="light" className="shadow-sm">
            <div className="d-flex justify-content-between">
                <div>
                    Invito  da parte di <UserBadge user={invitation.exam.owner}/> : <InviteBadge invitation={invitation}/> 
                </div>
                <div>
                    <LoadingButton loading={sendingAccept} variant="success" size="sm" className="mr-2" onClick={onAcceptInvitation}>Accetta</LoadingButton>
                    <LoadingButton loading={sendingRefuse} variant="danger" size="sm" className="mr-2" onClick={onRefuseInvitation}>Rifiuta</LoadingButton>
                    {formatTS(invitation.insert_ts)}
                </div>
            </div>
        </Alert>)
} 

export {Invitations}