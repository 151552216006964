
import React, {FC, useState, useMemo, useCallback, Fragment, useEffect} from 'react';
import { Button, Alert, InputGroup, ButtonGroup, Card, FormControl } from 'react-bootstrap';
import { useGetApi } from '../../utils/hooks';
import { Exam } from '../../types/exam';
import { buildGetFetch } from '../../services/base';
import { Table } from '../common/table';
import { useHistory } from 'react-router-dom';
import { Loading } from '../common/loading';
import { formatTS } from '../../utils/dateutils';
import { Id } from '../common/id';
import { FaEye, FaSearch } from 'react-icons/fa';
import { StateBadge } from '../common/examstatebadge';
import { EXAM_STATE } from '../../types/enums';
import { Auth } from '../../services/auth';
import { MdAdd } from 'react-icons/md';
import ExamSearch from './examsearch';

const Exams: FC<{}> = () => {

    const [exams, setExams] = useState<Array<Exam>>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [filter, setFilter] = useState<string>("");

    useEffect(() => {
        setIsSearching(true);
        const [search] = buildGetFetch(`/user/exam/status/${EXAM_STATE.IN_EXECUTION}`, {}, setExams, setIsSearching);
        search();
    },[]);

    const history = useHistory();

    const goToNewExam = useCallback(() => {
        history.push('/app/exams/create');
    }, [history]);

    const goToExam = useCallback((props: any) => {
        history.push(`/app/exams/${props.row.original.id}`);
    }, [history]);

    const performSearch = useCallback((event) => {
        setFilter(event.target.value.toUpperCase());
    },[]);

    const isAdmin:boolean=Auth.isAdmin();
    const hasPayments:boolean=Auth.isPaymentActive();
    const isTemporary:boolean=Auth.isTemporary();
    const userInSession:any=Auth.user();

    const columns = useMemo(() => {
        let cols= [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (props: any) => <Id id={props.value}/>
            },{
                Header: 'Stato',
                accessor: 'state',
                Cell: (props: any) => <StateBadge state={props.value}/>
            },{
                Header: 'Paziente',
                accessor: (d: any)=>{return d.patient.name + " " + d.patient.surname}
            },{
                Header: 'Visita',                
                accessor: (d: any)=> {
                    let invited=[];
                    if(d.confirmed_invitations && d.confirmed_invitations.length>0) {
                        invited=d.confirmed_invitations.map((i:any) => {
                            let useri=i.user;
                            if(!useri) return null;
                            let invitedName="";
                            if(useri.name || useri.surname) invitedName=useri.name + " " + useri.surname;
                            else invitedName="[" + useri.email + "]";
                            return invitedName;
                        });
                    }
                    return (                    
                        <div>
                            {d.title} &nbsp;
                            { d.owner.id!==userInSession.id && 
                                <span className="badge badge-warning" style={{fontWeight:"normal",fontSize:"80%"}}>
                                    {!isAdmin && <Fragment>Consulenza / parere su invito di </Fragment>} {isAdmin && <Fragment>di </Fragment>} 
                                    {d.owner.surname} {d.owner.name}
                                </span>
                            }
                            { d.owner.id===userInSession.id && invited.length==1 &&
                                <span className="badge badge-info" style={{fontWeight:"normal",fontSize:"80%"}}>
                                    Consulenza / parere richiesti al dott. {invited[0]}
                                </span>
                            }
                            { d.owner.id===userInSession.id && invited.length>1 &&
                                <span className="badge badge-info" style={{fontWeight:"normal",fontSize:"80%"}}>
                                    Consulenza / parere richiesti ai dott. 
                                        {invited.slice(0,1).map((i:any) => { if(i) return (<span style={{fontWeight:"normal",fontSize:"100%"}}>{i}</span>); else return null; }) }
                                        {invited.slice(1,invited.length).map((i:any) => { if(i)  return (<span style={{fontWeight:"normal",fontSize:"100%"}}>, {i}</span>); else return null; }) }
                                </span>
                            }
                        </div>
                    );
                }
            },{
                Header: 'Inizio',
                accessor: (d: any)=>{return formatTS(d.start_ts)}
            },{
                Header: 'Fine',
                accessor: (d: any)=>{return formatTS(d.end_ts)}
            },{
                Header: '',
                id: 'actions',     
                Cell: (props: any) => 
                    <div className="text-center">
                        <ButtonGroup aria-label="Azioni visita">
                            <Button title="Accedi alla visita" size="sm" variant="dark" onClick={() => {goToExam(props);}}><FaEye size={'1.5em'}/></Button>
                        </ButtonGroup>
                    </div>
            }
        ];
        if(isTemporary) {
            cols.splice(2,1);
        }
        return cols;
    }, [goToExam,isAdmin]);

    const filteredExams=exams.filter((d) => {
        if(!isTemporary) {
            let s=(d.patient.surname + " " + d.patient.name + " " + d.title).toUpperCase();
            return s.indexOf(filter)>=0;
        } else {
            let s=(d.title).toUpperCase();
            return s.indexOf(filter)>=0;
        }
    });

    const hasEnoughBudget:boolean=(Auth.availableBudget() >= Auth.exam_cost());

    return (
        <div>
            <Loading show={isSearching}/>
            {!isSearching &&
                <Fragment>
                    { (hasPayments || hasEnoughBudget) &&
                        <Button variant="warning" onClick={goToNewExam} className="mb-4" style={{border:"2px solid black",fontWeight:"bold"}}>
                            <MdAdd/>Crea visita
                        </Button>
                    }
                    <Card border="info" className="mb-4">
                        <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                Visite aperte
                                <InputGroup className="main-search-filter">
                                    <InputGroup.Append>
                                        <InputGroup.Text><FaSearch /></InputGroup.Text>
                                    </InputGroup.Append>
                                    <FormControl tabIndex={0} autoFocus={true} onChange={performSearch}/>
                                </InputGroup>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {!isTemporary && filteredExams && filteredExams.length>0 &&
                                <div className="mt-4 exam-table-container open-exams">
                                    <Table data={filteredExams} columns={columns} />
                                </div>
                            }
                            {isTemporary && filteredExams && filteredExams.length>0 &&
                                <div className="mt-4 exam-table-container temporary-user">
                                    <Table data={filteredExams} columns={columns} />
                                </div>
                            }
                
                            { exams && exams.length===0 &&
                                <div className="mt-4">
                                    <Alert variant="success">Nessuna visita</Alert>
                                </div>
                            }
                        </Card.Body>
                    </Card>

                    { !isTemporary &&
                        <Card border="info" className="mb-4">
                            <Card.Header>Ricerca visite in base al paziente</Card.Header>
                            <Card.Body>
                                <div className="mt-4">
                                <ExamSearch></ExamSearch>
                                </div>
                            </Card.Body>
                        </Card>          
                    }                  
                                        
                    <Card border="info">
                        <Card.Header>Visite aperte su cui stai collaborando con altro specialista</Card.Header>
                        <Card.Body>
                            <div className="mt-4">
                            <InvitedExams/>
                            </div>
                        </Card.Body>
                    </Card>                            
                </Fragment>
            }            
        </div>
    )
};


const InvitedExams: FC<{}> = ()=> {
    const history = useHistory();
    const [error, loading, exams] = useGetApi<Array<Exam>>('/user/exam/permission');
    
    const isAdmin:boolean=Auth.isAdmin();
    const isTemporary:boolean=Auth.isTemporary();

    const goToExam = useCallback((props: any) => {
        history.push(`/app/exams/${props.row.original.id}`);
    }, [history]);

    const userInSession:any=Auth.user();

    const columns: any = useMemo(() => {
        let cols= [{
            Header: 'Id',
            accessor: 'id',
            Cell: (props: any) => <Id id={props.value}/>
        },{
            Header: 'Stato',
            accessor: 'state',
            Cell: (props: any) => <StateBadge state={props.value}/>
        },{
            Header: 'Paziente',
            accessor: (d: any)=>{return d.patient.name + " " + d.patient.surname}
        },{
            Header: 'Visita',
            accessor: (d: any)=> (
                <div>
                    {d.title} &nbsp;
                    { d.owner.id!==userInSession.id && 
                        <span className="badge badge-warning" style={{fontWeight:"normal",fontSize:"80%"}}>
                            {!isAdmin && <Fragment>Su invito di</Fragment>} {isAdmin && <Fragment>di</Fragment>} 
                            {d.owner.surname} {d.owner.name}
                        </span>
                    }
                </div>)
        },{
            Header: 'Inizio',
            accessor: (d: any)=>{return formatTS(d.start_ts)}
        },{
            id:"fake"
        },{
            Header: '',
            id: 'actions',
            Cell: (props: any) => <div className="text-center">
                <ButtonGroup aria-label="Azioni visita">
                    <Button title="Accedi alla visita" size="sm" variant="dark" onClick={() => {goToExam(props);}}><FaEye size={'1.5em'}/></Button>
                </ButtonGroup>
          </div>
        }];
        if(isTemporary) {
            cols.splice(2,1);
        }
        return cols;
    }, [goToExam]);

    if(exams){
            return <Fragment>
                <Loading message="Ricerca in corso..." show={loading}></Loading>
                { exams.length === 0 ? 
                    <Alert variant="success">Nessuna visita</Alert> : 
                    <Fragment>
                        { isTemporary &&
                            <div className="exam-table-container invited-exams temporary-user">
                                <Table columns={columns} data={exams.reverse()}></Table>
                            </div>
                        }
                        { !isTemporary &&
                            <div className="exam-table-container invited-exams">
                                <Table columns={columns} data={exams.reverse()}></Table>
                            </div>
                        }
                    </Fragment>
                }
            </Fragment>
    } else {
        return <Loading message="Ricerca in corso..." show={true}></Loading>
    }
    
    return <div/>
}

export {Exams};