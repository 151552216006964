
import React, { FC, useState, useEffect, useCallback } from "react";
import { Badge } from "react-bootstrap";
import { InviteService, CHANGE_EVENT } from "../services/inviteservice";

export const Invites: FC<{}> = () => {

    const [invites, setInvites] = useState<number>(InviteService.getPendingInvites());

    const onPendingInvitesChange = useCallback(() => {
        setInvites(InviteService.getPendingInvites());
    }, []);

    useEffect(() => {
        InviteService.emitter().on(CHANGE_EVENT, onPendingInvitesChange);
        return () => {
            InviteService.emitter().off(CHANGE_EVENT, onPendingInvitesChange);
        }
    }, [onPendingInvitesChange]);

    if(invites === 0){
        return (<span/>)
    }

    return (<Badge variant="warning">{invites}</Badge>)

}