
import React, { FC, useCallback, useMemo } from "react";
import { Alert, Badge, Button, ButtonGroup, Modal } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Exam } from "../../types/exam";
import { Patient } from "../../types/patient";
import { formatTS } from "../../utils/dateutils";
import { EMOJI } from "../../utils/emoji";
import { fakeId } from "../../utils/faker";
import { useGetApi } from "../../utils/hooks";
import { StateBadge } from "../common/examstatebadge";
import { Id } from "../common/id";
import { Loading } from "../common/loading";
import { Table } from "../common/table";

interface PatientExamsPopupProps {
    patient: Patient,
    onClose: () => void
}

const PatientExamsPopup: FC<PatientExamsPopupProps> = ({patient,onClose}) => {

    const [error, loading, result] = useGetApi<Array<Exam>>(`/patient/${patient.id}/exams`);

    const history = useHistory();
    
    const goToExam = useCallback((props: any) => {
        onClose();
        history.push(`/app/exams/${props.row.original.id}`);
    }, [onClose,history]);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (props: any) => <Id id={props.value}/>
            },{
                Header: 'Stato',
                accessor: 'state',
                Cell: (props: any) => <StateBadge state={props.value}/>
            },{
                Header: 'Paziente',
                accessor: (d: any)=>{return ""}
            },{
                Header: 'Visita',
                accessor: 'title'
            },{
                Header: 'Inizio',
                accessor: (d: any)=>{return formatTS(d.start_ts)}
            },{
                Header: 'Fine',
                accessor: (d: any)=>{return formatTS(d.end_ts)}
            },{
                Header: '',
                id: 'actions',     
                Cell: (props: any) => 
                    <div className="text-center">
                        <ButtonGroup aria-label="Azioni visita">
                            <Button title="Accedi alla visita" size="sm" variant="dark" onClick={() => {goToExam(props);}}><FaEye size={'1.5em'}/></Button>
                        </ButtonGroup>
                    </div>
            }
        ]
    }, [goToExam]);

    if(error){
        return (
            <Alert>
                <Alert.Heading>{EMOJI.SAD} Attenzione</Alert.Heading>
                <hr/>
                <p>C'è stato un errore nell'ottenere la lista visite del paziente, contatta il supporto</p>
            </Alert>
        )
    }

    return(
        <Modal size="xl" show={true} backdrop="static" keyboard={false}>
            {patient &&
                <Modal.Header>
                    <h5>
                        <Badge variant="dark">Paziente {fakeId(patient.id)}</Badge>&nbsp;
                        {patient.surname} {patient.name} - Visite
                    </h5>
                </Modal.Header>
            }
            <Modal.Body>
                <Loading show={loading} message="Caricamento visite paziente..."/>
                {result && 
                    <div className="mt-1 exam-table-container for-patient">
                        <Table data={result} columns={columns} />
                    </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => onClose() }>Chiudi</Button>
            </Modal.Footer>
        </Modal>
        )
}

export {PatientExamsPopup}