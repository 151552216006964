
import React, { FC, useState, useEffect, useContext, useCallback } from 'react';
import { Button, Badge, Alert, Table } from 'react-bootstrap';
import { Loading } from '../common/loading';
import { buildGetFetch, buildPostFetch } from '../../services/base';
import { ExamContext } from './examcontext';
import { formatDT } from '../../utils/dateutils';
import { FaExternalLinkAlt, FaEye, FaLink, FaUnlink } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Id } from '../common/id';
import { FastExamCard } from './fastexamcard';
import { Auth } from '../../services/auth';

const ExamBindings: FC<{}> = () => {
    const { patientData, isClosed, exam, changeTab, onDataUpdate } = useContext(ExamContext);
    const [updating, setUpdating] = useState<boolean>(false);
    const [exams, setExams] = useState<Array<any>>([]);
    const history = useHistory();
    const ctx:any = useContext(ExamContext);
    const [shownExam, setShownExam] = useState<any>();

    const isAdmin:boolean = Auth.isAdmin();
    const user=Auth.user()?.id;
    const isInvited=(!isAdmin && exam?.owner_id !== user);
    
    useEffect(() => {
        if(isInvited) {
            const [fetch, cancel] = buildGetFetch(`/exam/${exam.id}/related_exam`, {},(data: any) => {
                data.forEach((e:any) => {
                    e.is_related_exam=true;
                });
                setExams(data);
            });
            fetch();
            return () => cancel();
        } else {
            const [fetch, cancel] = buildGetFetch(`/patient/${patientData.patient.id}/exams`, {},(data: any) => {
                let filteredExams:any=[];
                let selectedExams:any=[];
                data.forEach((e:any) => {
                    let toAdd=true;
                    if(ctx && ctx.exam.id===e.id) {
                        toAdd=false;
                        if(e.related_exams) {
                            e.related_exams.forEach((re:any) => {
                                selectedExams.push(re.id);
                            });
                        }
                    }
                    if(toAdd) filteredExams.push(e);
                });

                // Marchiamo gli esami selezionati
                filteredExams.forEach((e:any) => {
                    if(selectedExams.includes(e.id)) e.is_related_exam=true;
                });
                setExams(filteredExams);
            });        
            fetch();
            return () => cancel();
        }
    }, [ctx,patientData.patient]);

    const onExamBind = useCallback((selectedExam:any) => {
        setUpdating(true);
        const [fetch] = buildPostFetch(`/user/exam/${exam.id}/bind`, {
            exam: selectedExam.id
        });
        fetch().then(() => {
            onDataUpdate({
                examPath: "$ALL$",
                examValue: exam
            });
            selectedExam.is_related_exam=true;
            setUpdating(false);
        })
    },[exam]);

    const onExamUnbind = useCallback((selectedExam:any) => {
        setUpdating(true);
        const [fetch] = buildPostFetch(`/user/exam/${exam.id}/unbind`, {
            exam: selectedExam.id
        });
        fetch().then(() => {
            onDataUpdate({
                examPath: "$ALL$",
                examValue: exam
            });
            selectedExam.is_related_exam=false;
            setUpdating(false);
        });
    },[exam]);

    const goToExam = (e:any) => {
        changeTab("home");
        history.push(`/app/exams/${e.id}`);
    }

    const showExam = (e:any) => {
        setShownExam(e);
    }

    const onHideExam = () => {
        setShownExam(null);
    }

    return (<div>
            <Loading show={updating} backdrop={true}/>
            
            { shownExam &&
                <FastExamCard exam={shownExam} onClose={onHideExam}/>
            }

            <div className="action-menu">
                {!isInvited && 
                    <p>Collega la visita corrente ad una visita precedente in modo da poter visionare il parere medico passato e poter aver accesso in maniera rapida alle immagini.</p>
                }
                {isInvited && 
                    <p>Elenco visite collegate, per poter visionare il parere medico passato e poter aver accesso in maniera rapida alle immagini.</p>
                }
                { isClosed ? 
                    <Alert>
                        <p>
                            Nessuna visita collegata
                        </p>
                    </Alert>
                    : 
                    <div>
                        <div className="form-group">
                            <Table striped bordered hover size="sm" className="app-table">
                                <thead>
                                    <tr>
                                        {!isInvited &&
                                            <th style={{width:'1px'}}/>
                                        }
                                        <th>Identificativo</th>
                                        <th>Visita</th>
                                        <th className="text-center">Apertura</th>
                                        <th className="text-center">Chiusura</th>
                                        <th className="text-center">Richiamo</th>
                                        <th style={{width:'1px'}}/>
                                    </tr>
                                </thead>
                                <tbody>
                                    {exams.length === 0 && <tr><td colSpan={6}>Nessuna visita disponibile</td></tr>}
                                    {exams.map((e) => {
                                        return (
                                            <tr key={`exam_id_${e.id}`}>
                                                {!isInvited &&
                                                    <td className="text-center" style={{whiteSpace:"nowrap"}}>
                                                        { e.is_related_exam ?
                                                            <Button variant='success' onClick={() => onExamUnbind(e)}>
                                                                <FaUnlink /> Scollega
                                                            </Button> 
                                                            :
                                                            <Button variant='secondary' onClick={() => onExamBind(e)}>
                                                                <FaLink /> Collega
                                                            </Button> 
                                                        }
                                                    </td>
                                                }
                                                <td className="clickable" onClick={() => showExam(e)}><Id id={e.id}/></td>
                                                <td className="clickable" onClick={() => showExam(e)}>{e.title}</td>
                                                <td className="clickable" onClick={() => showExam(e)}>{formatDT(e.start_ts)}</td>
                                                <td className="clickable" onClick={() => showExam(e)}>{formatDT(e.end_ts)}</td>
                                                <td><Badge variant="info">{e.reminder}</Badge> mes{e.reminder === 1 ? 'e' : 'i'}</td>
                                                {!isInvited && 
                                                    <td>
                                                        <Button variant='primary' onClick={() => goToExam(e)}>
                                                            <FaEye />
                                                        </Button> 
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>   
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export { ExamBindings };
