
import './app.scss';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Collapse, Nav, Alert } from 'react-bootstrap';
import { useRouteMatch, Switch, Route, NavLink,useHistory } from 'react-router-dom';
import { useNotification } from '../utils/hooks';
import { toast } from 'react-toastify';
import { USER_CHANNEL, ADMIN_CHANNEL } from '../services/notificationservice';
import { Nomatch } from './common/nomatch';
import { Invitations } from './invitation/invitations';
import { GiExitDoor } from 'react-icons/gi';
import { FaClinicMedical, FaFileMedicalAlt, FaHistory } from 'react-icons/fa';
import { MdPerson, MdAlarmOn, MdNewReleases, MdSettingsApplications, MdChat, MdContactMail } from 'react-icons/md';
import { RiMoneyEuroCircleLine } from 'react-icons/ri';
import { Auth,LoginData } from '../services/auth';
import { ExamCenter } from './exam/examcenter';
import { PatientCenter } from './patient/patientcenter';
import { OverflowWrapper } from './common/overflowwrapper';
import clsx from 'clsx';
import { Message } from '../types/message';
import { Invites } from './invites';
import { Reminders } from './reminders/reminders';
import { MainNav } from './mainnav';
import { Dashboard } from './dash/dashboard';
import { Payments } from './payments/payments';
import { PaymentsBadge } from './payments/paymentsbadge';
import { Confirm } from './common/confirm';
import { Settings } from './settings/Settings';
import { UnreadMessagesBadge } from './unreadMessagesBadge';
import { ExamsWithUnreadMessages } from './exam/examsWithUnreadMessages';
import { RemindersBadge } from './remindersbadge';
import { buildGetFetch } from '../services/base';
import OldExams from './exam/oldexams';

const App: FC = () => {
  let { path, url } = useRouteMatch();
  let history = useHistory();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [invitations] = useState<boolean>(Auth.canBeInvited());
  const [askLogout, setAskLogout] = useState<boolean>(false);
  const [temporaryUserWarningCollapsed, setTemporaryUserWarningCollapsed] = useState<boolean>(false);
  const [version, setVersion] = useState<string>();
  const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false);

  useNotification(ADMIN_CHANNEL.events.Message, (e: Message) => {
    toast.info(`Una nuova notifica: ${e.message}`);
  });
  useNotification(USER_CHANNEL.events.Message, (e: Message) => {
    toast.info(`Una nuova notifica utente: ${e.message}`);
  });

  useEffect(() => {
    let savedAppInfoAsString=localStorage.getItem("app-info");
    let savedAppInfo=null;
    if(savedAppInfoAsString) {
        try {
            savedAppInfo=JSON.parse(savedAppInfoAsString);
        } catch(e) {
            savedAppInfo=null;
        }
    }
    if(savedAppInfo && savedAppInfo.version) {
      console.log("savedAppInfo",savedAppInfo)
      setVersion(savedAppInfo.version);
      checkNewVersionAvailable(savedAppInfo.version);
    } else {
      setVersion("[no version]");
    }
  },[]);

  const checkNewVersionAvailable = (vv:string) => {
    if(!vv) {
      console.log("No saved version, considering no update available");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');
    var myInit = {
      method: 'GET',
      headers: myHeaders,
    };
    fetch('/app-info.json',myInit)
      .then((response) => response.json())
      .then((serverInfo) => {
          console.log("Saved version present, comparing versions",vv,serverInfo.version);
          if(vv!==serverInfo.version) {
              console.log("Version mismatch, an update is available....");
              setNewVersionAvailable(true);
              toast.warning(`E' disponibile un aggiornamento software. Utilizza lo strumento vicino al numero di versione per aggiornare`);
          }
    });
  }

  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  const doAskLogout = (e: any) => {
    e.preventDefault();
    setAskLogout(true);
  }

  const doLogout = () => {
    localStorage.removeItem("credentials");
    Auth.logout();
    history.replace("/");
  }

  const updateApp = useCallback(() => {
      window.location.href="/";
  },[]);

  const collapseTemporaryUserWarning = useCallback(() => {
    setTemporaryUserWarningCollapsed(true);
  },[]);

  const expandTemporaryUserWarning = useCallback(() => {
    setTemporaryUserWarningCollapsed(false);
  },[]);

  const hasPayments=(Auth.isPaymentActive() || Auth.availableBudget()>0);
  const isTemporaryUser=(Auth.user()?.temporary);
  const hasPaymentWarnings=(Auth.hasPaymentWarnings());
  let tout:any=null;

  const checkSession = () => {
    console.log("checking session...");
    const [search] = buildGetFetch<LoginData>(`/user`, {}, 
          (sessionData: LoginData) => { // set result 
            Auth.refreshSession(sessionData);
            installCheckSession();
          }, 
          () => {}, // is loading 
          () => { // is error
            console.warn("session disconnected, restarting...")
            window.location.href="/";
          });
    search();    
  }

  const installCheckSession = () => {
    tout=setTimeout(() => {
      checkSession();      
    },1000*5);
  }

  useEffect(() => {
    installCheckSession();
    return function cleanup() {
        if(tout) {
            console.log("cancelling session refresh timeout");
            clearTimeout(tout);
        }
    };
  },[]);
  
  return (
    <div className="App">
      <span className="app-version">
          Versione: {version}
          { newVersionAvailable &&
            <span title="Aggiornamento disponibile. Premi per aggiornare" onClick={updateApp}>
              <MdNewReleases />
            </span>
          }
      </span>
      <header >
      </header>
      <MainNav/>
      <Container fluid>
        <Row className="flex-xl-nowrap">
          <Col xl={2} md={3} className="sidebar">
            <button title={collapsed ? 'Chiudi menu' : 'Apri menu'} onClick={handleCollapse} className={clsx(collapsed ? 'hide-menu' : 'show-menu' )}>
              <div className="content"></div>
            </button>
            <Collapse in={collapsed}>
              <OverflowWrapper>
                <Nav className={`flex-column`}>
                  <NavLink exact to={`${url}`} className="nav-link" activeClassName="active">
                    <FaClinicMedical size={'2.5rem'} className="mr-4 ml-4" />Home
                  </NavLink>
                  <NavLink exact to={`${url}/exams`} isActive={(match, location) => {
                      if (match) {
                        return true;
                      }
                      return location.pathname.startsWith(`${url}/exams`);
                    }}
                    className="nav-link" activeClassName="active">
                    <FaFileMedicalAlt size={'2.5rem'} className="mr-4 ml-4" />Visite
                  </NavLink>
                   <NavLink exact to={`${url}/oldExams`} isActive={(match, location) => {
                      if (match) {
                        return true;
                      }
                      return location.pathname.startsWith(`${url}/oldExams`);
                    }}
                    className="nav-link" activeClassName="active">
                    <FaHistory size={'2.5rem'} className="mr-4 ml-4" />Visite chiuse
                  </NavLink>
                  {!isTemporaryUser &&
                  <NavLink exact to={`${url}/patients`} isActive={(match, location) => {
                      if (match) {
                        return true;
                      }
                      return location.pathname.startsWith(`${url}/patients`);
                    }} className="nav-link" activeClassName="active">
                    <MdPerson size={'2.5rem'} className="mr-4 ml-4" />Pazienti
                  </NavLink>
                  }
                  { invitations && 
                    <NavLink exact to={`${url}/invitations`} className="nav-link" activeClassName="active">
                      <MdContactMail size={'2.5rem'} className="mr-4 ml-4" />Inviti <Invites/>
                    </NavLink>
                  }
                  <NavLink exact to={`${url}/unreadMessages`} onClick={() => { }} className="nav-link" activeClassName="active">
                    <MdChat size={'2.5rem'} className="mr-4 ml-4" />Messaggi <UnreadMessagesBadge/>
                  </NavLink>   
                  {!isTemporaryUser &&
                    <NavLink exact to={`${url}/reminders`} onClick={() => { }} className="nav-link" activeClassName="active">
                      <MdAlarmOn size={'2.5rem'} className="mr-4 ml-4" />Richiami <RemindersBadge />
                    </NavLink>   
                  }
                  {!isTemporaryUser &&
                    <NavLink exact to={`${url}/payments`} onClick={() => { }} className="nav-link" activeClassName="active">
                      <RiMoneyEuroCircleLine size={'2.5rem'} className="mr-4 ml-4" />Pagamenti <PaymentsBadge/>
                    </NavLink>   
                  }
                  {!isTemporaryUser &&
                    <NavLink exact to={`${url}/settings`} onClick={() => { }} className="nav-link" activeClassName="active">
                      <MdSettingsApplications size={'2.5rem'} className="mr-4 ml-4" />Impostazioni
                    </NavLink>   
                  }
                  <NavLink exact to={`${url}/logout`} onClick={doAskLogout} className="nav-link" activeClassName="active">
                    <GiExitDoor size={'2.5rem'} className="mr-4 ml-4" />Logout
                  </NavLink>
                </Nav>
              </OverflowWrapper>
            </Collapse>
          </Col>
          <main className="col-xl-10 col-md-9 col-12">
            { isTemporaryUser &&
                  <Alert variant="warning">
                      {temporaryUserWarningCollapsed &&
                        <div>
                            Accesso come esperto invitato a fornire una consulenza.
                            <a onClick={expandTemporaryUserWarning} className="as-link">Visualizza informazioni dettagliate</a>
                        </div>
                      }
                      {!temporaryUserWarningCollapsed &&
                        <div>
                          <a onClick={collapseTemporaryUserWarning} className="as-link pull-right">Nascondi</a>
                          Accesso come esperto invitato a fornire una consulenza. L'accesso &egrave; limitato ai dati strettamente necessari 
                          e non riservati e non consente la refertazione ma solo l'uso della chat con il dottore che ha chiesto la Sua consulenza.<br/>
                          <u>Per visualizzare i dati</u> &rarr; selezionare l'invito e accettare l'invito<br/>
                          <u>Per visualizzare le immagini</u> &rarr; nella videata della visita selezionare la voce esame e, per ingrandirle, flaggare 
                                e cliccare la voce confronta immagini (esiste anche zoom digitale).<br/>
                          <u>Per utilizzare la chat</u> &rarr; nella videata della visita, selezionare la voce messaggi e utilizzare il campo apposito per la chat<br/>
                          NB: L'utilizzo completo del sistema Dermaco necessita di iscrizione al circuito stesso. Per info scrivere a:
                          <a href="mailto:info@dermaco.it">info@dermaco.it</a>
                        </div>
                      }
                  </Alert>
            }
            { !hasPayments && !isTemporaryUser &&
                  <Alert variant="danger">
                      Nessun servizio di pagamento attivo. Non sarà possibile creare nuove visite.<br/>
                      Contatta l'amministratore
                  </Alert>
            }
            { hasPaymentWarnings && !isTemporaryUser &&
                  <Alert variant="warning">
                      Attenzione, stai esaurendo il tuo credite. Credito residuo: &nbsp;
                      <b>{Auth.availableBudget()}&euro;</b>
                      <br/>
                      Contatta l'amministratore per attivare una nuova quota
                  </Alert>
            }
            <Switch>
              <Route exact path={path}>
                <Dashboard/>
              </Route>
              <Route path={`${path}/exams`}>
                <ExamCenter/>
              </Route>
              <Route path={`${path}/oldExams`}>
                <OldExams/>
              </Route>
              <Route path={`${path}/patients`}>
                <PatientCenter/>
              </Route>
              {invitations && <Route exact path={`${path}/invitations`}>
                <Invitations/>
              </Route>}
              <Route path={`${path}/unreadMessages`}>
                <ExamsWithUnreadMessages />
              </Route>
              <Route exact path={`${path}/reminders`}>
                <Reminders/>
              </Route>
              <Route exact path={`${path}/payments`}>
                <Payments/>
              </Route>
              <Route exact path={`${path}/settings`}>
                <Settings/>
              </Route>
              <Route path="*">
                <Nomatch />
              </Route>
            </Switch>
          </main>
        </Row>
      </Container>

      {askLogout && 
          <Confirm 
              headerMessage="Conferma logout" 
              message="Questa operazione scollega la sessione utente. Sarà necessario immettere nuovamente le credenziali per accedere al sistema. Continuare?"
              additionalMessage="Nota: chiudendo invece il browser la sessione utente rimarrà attiva e alla prossima attivazione del sistema l'accesso avverrà automaticamente"
              onConfirm={() => {doLogout()}} 
              ok="Esegui logout" 
              cancel="Annulla" 
              onCancel={() => {setAskLogout(false)}}/>
      }

      <footer>
      </footer>
    </div>
  );
}


export { App };
