import React, { FC, useState, useContext, useCallback, useEffect, Fragment } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { buildGetFetch, buildPutFetch } from '../../services/base';
import { Exam } from '../../types/exam';
import { Confirm } from '../common/confirm';
import { Loading } from '../common/loading';
import { ExamContext } from './examcontext';

interface ExamCloseProps {
    onStateChange: () =>void
}

const ExamClose: FC<ExamCloseProps> = ({onStateChange}) => {

    const examCtx:any = useContext(ExamContext);
    const [askClose, setAskClose] = useState<boolean>(false);
    const [report, setReport] = useState<Array<boolean>>();
    const [loading, setLoading] = useState<boolean>(true);
    const [notPresent, setNotPresent] = useState<boolean>(false);

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch(`/user/exam/${examCtx.exam.id}/reportExists`,{ toastError: false },setReport,setLoading,setNotPresent);
        fetch();
        return () => {
            cancel();
        };
    }, [examCtx]);

    const doClose=useCallback(() => {
        const [closeFetch] = buildPutFetch<Exam>(`/user/exam/${examCtx.exam.id}/close`, {});
        closeFetch().then(() => {
            toast.success('Visita chiusa con successo');
            setAskClose(false);
            onStateChange();
        })
    },[examCtx,onStateChange]);

    return (
        <div>            
            {loading &&
                <Loading show={true} />
            }
            {!loading && 
                <Fragment>
                    { notPresent &&
                        <Alert variant="warning" title="Avvertimento">
                            La visita pu&ograve; essere chiusa solo al termine della raccolta di tutti i dati e dopo
                            aver redatto il parere medico.
                        </Alert>
                    }
                    { !notPresent &&
                        <Fragment>
                            <Alert variant="success" title="Nota">
                                Il parere medico &egrave; stato redatto. La visita pu&ograve; essere chiusa.
                            </Alert>
                            <Button variant="warning" onClick={() => { setAskClose(true) }}>Chiudi visita</Button>
                        </Fragment>
                    }                      
                    { askClose &&
                        <Confirm 
                            headerMessage="Conferma operazione" 
                            message="Chiudere definitivamente la visita?"
                            onConfirm={() => { doClose() }} 
                            ok="Esegui" 
                            cancel="Annulla" 
                            onCancel={() => { setAskClose(false) }}/>
                    }
                </Fragment>
            }   
        </div>
    );
}


export { ExamClose };