
import React, {FC, useCallback, useState} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Patient } from '../../types/patient';
import { useHistory } from 'react-router-dom';
import { NewPatient } from './newpatient';
import { MdAdd } from 'react-icons/md';
import { AcPatient } from '../common/ac_patient';
import { Auth } from '../../services/auth';

const Patients: FC<{}> = () => {

    const [show, setShow] = useState(false);
    const history = useHistory();

    const onPatientCreate = useCallback((p: Patient) => {
        history.push(`/app/patients/${p.id}`);
    }, [history]);

    const hasPayments=(Auth.paymentService() !== null);

    return (
        <div>
            <h2>Pazienti</h2>
            { hasPayments &&
                <div className="action-menu">
                    <Button variant="info" onClick={() => {setShow(true)}}><MdAdd/> Nuovo paziente</Button>
                </div>
            }
            <PatientSearch/>
            <Modal size="lg" show={show} onHide={() => setShow(false)} aria-labelledby="new patient dialog">
                <Modal.Header closeButton>
                <Modal.Title>
                    Inserimento nuovo paziente
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewPatient onCreate={onPatientCreate}/>
                </Modal.Body>
        </Modal>
        </div>
    )
};

const PatientSearch = () => {

    const history = useHistory();
    
    const goToPatient = useCallback((patients) => {
        history.push(`/app/patients/${patients[0].id}`);
    }, [history]);

    return (<AcPatient onSelect={(p) => {goToPatient(p)}}/>)
}

export {Patients}