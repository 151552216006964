

import React, {FC} from 'react';
import { Badge } from 'react-bootstrap';
import { User } from '../../types/user';

export const UserBadge: FC<{user: User}> = ({user}) => {
    if(user.temporary) {
        return <Badge variant="warning">[{user.email}]</Badge>;
    } else {
        return <Badge variant="dark">Dr. {user.name} {user.surname}</Badge>;
    }
}