
import './patientcard.scss';
import React, { FC, useState, useCallback, Fragment, useContext } from 'react';
import { Card, Alert, Badge, Form, Button, ButtonGroup } from 'react-bootstrap';
import { PatientData, PatientAddress, Patient } from '../../types/patient';
import { formatTS } from '../../utils/dateutils';
import { fakeId } from '../../utils/faker';
import { Panel } from '../common/panel';
import { FotoTypeForm } from './fototypeForm';
import { Formik, FormikHelpers } from 'formik';
import { MdEdit, MdPets, MdSave } from 'react-icons/md';
import { NeviNoteForm } from './neviNoteForm';
import { PatientForm } from './patientForm';
import { Confirm } from '../common/confirm';
import { isEqual, reduce, cloneDeep } from "lodash";
import { buildPutFetch } from '../../services/base';
import { toast } from 'react-toastify';
import { MedicalData } from '../../types/medicaldata';
import { SkinLesion } from '../../types/skinlesion';
import { Loading } from '../common/loading';
import { StickyBar } from '../common/stickybar';
import clsx from 'clsx';
import { ExamContext } from '../exam/examcontext';
import { OtherPatologiesForm } from './otherpathologies';
import { Logs } from '../common/logs';
import { FaFileMedicalAlt } from 'react-icons/fa';
import { PatientExamsPopup } from './patientexamspopup';

type PatientCardProps = {
    /**
     * The source PatientData. This will be cloned into formik in order to have 2 different versions of PatientData: the source PatientData 
     * and the editing PatientData. When an update is performed you should use the onUpdate prop to reflect the changes of the cloned PatientData 
     * on the source PatientData
     */
    patientData: PatientData;
    /**
     * Show / hide the patient basic information
     */
    showBasicInfo?: boolean;
    /**
     * Show / hide the patient medical information
     */
    showMedicalData?: boolean;
    /**
     * Show / hide the patient exam list
     */
    showExamList?: boolean;
    /**
     * Enable/disable the editing capability of the component
     */
    canEdit?: boolean;
    /**
     * Optionall callback to be execute when any change is performed in PatientData
     */
    onUpdate?: (patientData: PatientData) => void;
}

let reset: any = null;

export const PatientCard: FC<PatientCardProps> = ({ patientData, showBasicInfo=true, showMedicalData = false, showExamList = false, canEdit = true, onUpdate }) => {

    const examCtx:any = useContext(ExamContext);
    const [editing, setEditing] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);
    const [cancelConfirm, setCancelConfirm] = useState<boolean>(false);    
    const [showingLogs, setShowingLogs] = useState<boolean>(false);
    const [showingExams, setShowingExams] = useState<boolean>(false);

    const onSubmit = useCallback((values: PatientData, helpers: FormikHelpers<PatientData>) => {
        setUpdating(true);
        
        const eq = (a: any, b: any) => {
            return reduce(a, (result, value, key) => {
                return isEqual(value, b[key]) ? result : result.concat(key);
            }, [] as Array<string>);
        }
        
        const patDiff = eq(patientData.patient, values.patient);
        const addDiff = eq(patientData.address, values.address);
        const medicalInfoDiff = eq(patientData.medicalData, values.medicalData);
        const lesionDiff = eq(patientData.lesions, values.lesions);
        const updates: Array<() => Promise<any>> = [];

        if(patDiff.length > 0){
            //update patient infos
            const [fetch] = buildPutFetch<Patient>(`/patient/${patientData.patient.id}/`, values.patient,{},(x) => {
                values.patient=x;
            });
            updates.push(fetch);
        }
        if(addDiff.length > 0){
            //update patient address
            const [fetch] = buildPutFetch<PatientAddress>(`/patient/${patientData.patient.id}/address`, values.address,{},(x) => {
                values.address=x;
                values.patient.updated_ts=x.updated_ts;
            });
            updates.push(fetch);
        }
        if(examCtx!=null && medicalInfoDiff.length > 0){
            //update patient medical data - riportato sull'esame secondo bugid#6005
            const [fetch] = buildPutFetch<MedicalData>(`/exam/${examCtx.exam.id}/medical`, values.medicalData,{},(x) => {
                values.medicalData=x;
                values.patient.updated_ts=x.updated_ts;
            });
            updates.push(fetch);
        }
        if(patientData.lesions.length !== values.lesions.length || lesionDiff.length > 0){
            //update skin lesions
            const [fetch] = buildPutFetch<Array<SkinLesion>>(`/patient/${patientData.patient.id}/lesion`, values.lesions,{},(x) => {
                values.lesions=x;
            });
            updates.push(fetch);
        }

        Promise.all(updates.map( f => f() )).then((results: Array<any>) => {
            helpers.setSubmitting(false);
            setEditing(false);
            setUpdating(false);
            if(onUpdate){
                onUpdate(values);
            }
            toast.success("Paziente aggiornato con successo");
        }).catch((e)=>{
            helpers.setSubmitting(false);
            setUpdating(false);
        })

    }, [patientData,onUpdate,examCtx]);

    const cancelForm = () => {
        reset();
        setEditing(false); 
        setCancelConfirm(false);
    };

    const showExams = useCallback(() => {
        setShowingExams(true);
    }, []);

    return (
        <Fragment>
            <Card border="info" className="mb-2">
                <Card.Header className={clsx(!editing && "sticky")}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            { patientData?.patient &&
                                <Fragment>
                                    <Badge variant="dark">Paziente {fakeId(patientData?.patient?.id)}</Badge>&nbsp;
                                    {patientData.patient.surname} {patientData.patient.name}
                                </Fragment>
                            }
                            { (!patientData || !patientData.patient) &&
                                <Fragment>
                                    <Badge variant="dark">Paziente {fakeId(patientData?.patient?.id)}</Badge> &nbsp;
                                </Fragment>
                            }
                        </div>
                        <div>
                            {canEdit && 
                                <Button disabled={editing} variant="info" title="Attiva modifica" onClick={() => { setEditing(true) }}><MdEdit /> Inserisci</Button>
                            }
                            { showExamList &&
                                <Button className="ml-2" disabled={editing} title="Mostra visite paziente" variant="info" onClick={showExams}><FaFileMedicalAlt/> Elenco visite</Button>
                            }
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Loading show={updating} backdrop={true} message="Salvataggio in corso..."/>

                    {cancelConfirm && <Confirm headerMessage="Hai effettuato delle modifiche al paziente" 
                                    message="Sei sicuro di voler annullare le modifiche senza salvare?"
                                    onConfirm={cancelForm} 
                                    ok="Si" 
                                    cancel="No" 
                                    onCancel={() => {setCancelConfirm(false)}}/>}                                

                    <Formik onSubmit={onSubmit} initialValues={cloneDeep(patientData)} >
                        {({ resetForm, handleSubmit, dirty }) => (
                            <Form noValidate onSubmit={handleSubmit}  autoComplete="off">
                                
                                { showBasicInfo &&
                                    <Panel spacing="normal" header="ANAGRAFICA" defaultState="open">
                                        <div>
                                            <PatientForm editing={editing} entityData={patientData}/>
                                        </div>
                                    </Panel>
                                }
                                { showMedicalData && 
                                    <Fragment>
                                        <Panel spacing="big" header="NEVI" defaultState={patientData.medicalData.nv_present?"open":"closed"}>
                                            <div>
                                                <FotoTypeForm editing={editing} name="medicalData.foto_type" foto_type={patientData.medicalData.foto_type}/>
                                                <NeviNoteForm editing={editing} base="medicalData" neiNote={patientData.medicalData} />
                                            </div>
                                        </Panel>
                                        <Panel spacing="big" header="ALTRE PATOLOGIE CUTANEE" defaultState={patientData.medicalData.ph_present?"open":"closed"}>
                                            <div>
                                                <OtherPatologiesForm editing={editing} base="medicalData" medicalData={patientData.medicalData}/>
                                            </div>
                                        </Panel>
                                    </Fragment>
                                }
                                    
                                <StickyBar top={49}>
                                    {editing && <Alert variant="warning">
                                        <div className="text-center">
                                            <p>Modifica attiva</p>
                                            <ButtonGroup>
                                                <Button type="button" onClick={() => {
                                                    reset = resetForm;
                                                    if(dirty){
                                                        setCancelConfirm(true);
                                                    } else {
                                                        cancelForm();
                                                    }
                                                }} variant="warning"><MdSave /> Annulla modifiche</Button>
                                                <Button disabled={!dirty} variant="success" type="submit"><MdSave /> Aggiorna dati paziente</Button>
                                            </ButtonGroup>
                                        </div>
                                    </Alert>}
                                </StickyBar>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            Data inserimento: {formatTS(patientData.patient.created_ts)}
                        </div>
                        <div>
                            { patientData.patient.updated_ts &&
                                <span>
                                    Ultimo aggiornamento: {formatTS(patientData.patient.updated_ts)}   
                                    <a className="btn btn-xs btn-logs" onClick={()=> setShowingLogs(true)}>
                                        <MdPets />
                                    </a>
                                </span>
                            }
                        </div>
                    </div>            
                </Card.Footer>
            </Card>

            { showingLogs &&
                <Logs patient={patientData.patient} onClose={()=>setShowingLogs(false)} title="Log modifiche paziente" />
            }

            { showingExams &&
                <PatientExamsPopup patient={patientData.patient} onClose={()=>setShowingExams(false)}  />
            }
        </Fragment>        
    )
}

