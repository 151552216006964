import React, { FC } from "react";
import { Auth } from "../../services/auth";
import { EMOJI } from "../../utils/emoji";

export const PaymentsBadge: FC<{}> = () => {

    const hasPayments=(Auth.isPaymentActive());
    const budget=Auth.availableBudget();
    const hasPaymentWarnings=(Auth.hasPaymentWarnings());

    if(!hasPayments && budget<=0){
        return (
            <span className="badge badge-danger" role="emoji" aria-label="Attenzione">
                {EMOJI.ATTENTION}
            </span>
            );
    } else if(hasPaymentWarnings) {
        return (
            <span className="badge badge-warning" role="emoji" aria-label="Attenzione">
                {EMOJI.ATTENTION}
            </span>
        );
    } else {
        return <span/>
    }
}