
import React, { FC, useCallback, useState, useEffect } from 'react';
import { Form, Col, Button, Modal, InputGroup, ButtonGroup, Card } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { AcPatient } from '../common/ac_patient';
import { validationSchema } from '../../utils/validation';
import { PatientData } from '../../types/patient';
import { Exam } from '../../types/exam';
import { buildPostFetch, buildGetFetch } from '../../services/base';
import { toast } from 'react-toastify';
import { FcPlus } from 'react-icons/fc';
import { NewPatient } from '../patient/newpatient';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '../common/loadingbutton';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const schema: yup.ObjectSchema = validationSchema({
    patients: yup.array().required(),
    title: yup.string().required(),
    note: yup.string()
});

const NewExam: FC<{}> = () => {

    const history = useHistory();
    const [show, setShow] = useState(false);
    const [canopen, setCanOpen] = useState(false);
    const [exam, setExam] = useState<Exam | null>(null);

    useEffect(() => {
        const [fetch] = buildGetFetch("/user/exam/canopen", {});
        fetch().then((data) => {
            if(!data){
                toast.error("Impossibile aprire una nuova visita");
                history.replace("/app/payments");
            } else {
                setCanOpen(true);
            }
        });
    }, [history]);

    /**
     * Creazione
     */
    const submit = useCallback((values: any, helpers: FormikHelpers<any>) => {
        helpers.setSubmitting(true);
        const patient = values.patients[0];
        const data = {
            patient_id: patient.id,
            title: values.title,
            note: values.note
        }
        const [create] = buildPostFetch<Exam>("/user/exam", data, { toastError: true, toastMessage: 'Errore apertura visita, controlla non ci sia già una visita in corso oppure contatta il supporto' });
        create().then(async (e) => {
            if (e !== null) {
                const [fetch, cancel] = buildGetFetch<PatientData>(`/patient/${patient.id}`, {});
                const data = await fetch();
                helpers.setSubmitting(false);
                setTimeout(() => {
                    if (data !== null) {
                        setExam(e);
                        toast.success("Visita aperta");
                        history.replace('/app/exams/' + e.id);
                        return;
                    }
                }, 200);
            }
        });
    }, [history]);

    const goBack = () => {
        history.replace(`/app/exams`);
    };

    if(!canopen){
        return (<div/>);
    }

    return (<div style={{ height: '100%', overflow: 'hidden' }}>

        <Card>
            <Card.Header>
                <div>Accettazione nuova visita</div>
            </Card.Header>
            <Card.Body>
                <Formik validationSchema={schema}
                    onSubmit={submit}
                    initialValues={{
                        patients: undefined,
                        title: '',
                        note: ''
                    }}>
                    {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {
                                //@ts-ignore
                            values.patient?.id
                            }
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>Seleziona paziente</Form.Label>
                                    <InputGroup>
                                        <AcPatient selected={values.patients} disabled={!!exam} name="patients" onSelect={(s) => {
                                            setFieldValue("patients", [...s], true);
                                        }} valid={touched.patients && !errors.patients} invalid={touched.patients && !!errors.patients} />
                                        <InputGroup.Append>
                                            <Button title=" paziente" variant="outline-primary" onClick={() => { setShow(true)}}>
                                                <FcPlus size={'1em'} />
                                            </Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">{errors.patients}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="titleField">
                                    <Form.Label>Motivo della visita</Form.Label>
                                    <Form.Control autoComplete="off" disabled={!!exam} type="text" placeholder="Inserisci la motivazione" name="title" value={values.title}
                                        onChange={handleChange} isValid={touched.title && !errors.title} isInvalid={touched.title && !!errors.title} />
                                    <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Group controlId="notes">
                                <Form.Label>Annotazioni</Form.Label>
                                <Form.Control disabled={!!exam} rows={5} as="textarea" type="text" placeholder="Inserisci eventuali annotazioni" name="note" value={values.note}
                                    onChange={handleChange} />
                            </Form.Group>
                            <ButtonGroup style={{ width: '100%' }}>
                                <Button onClick={goBack} type="button" size="lg" variant="warning">
                                    <FaArrowLeft size={"1.5rem"} /> Torna alle visite
                                </Button>
                                <LoadingButton loading={isSubmitting} disabled={isSubmitting} type="submit" size="lg" variant="success">
                                    Apri visita <FaArrowRight size={"1.5rem"} />
                                </LoadingButton>
                            </ButtonGroup>
                            <Modal size="lg" show={show} onHide={() => setShow(false)} aria-labelledby="new patient dialog">
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        Inserimento nuovo paziente
                                </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <NewPatient onCreate={(p) => {
                                        setShow(false);
                                        setFieldValue("patients", [p], true);
                                    }} />
                                </Modal.Body>
                            </Modal>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
        
    </div>)
}

export { NewExam }