
import React, {FC} from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import { ExamDetail } from "./examdetail";
import { NewExam } from "./newexam";
import { Exams } from "./examslist";

const ExamCenter: FC<{}> = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Exams></Exams>
              </Route>
              <Route exact path={`${path}/create`}>
                <NewExam></NewExam>
              </Route>
              <Route path={`${path}/:examId`}>
                <ExamDetail></ExamDetail>
              </Route>
        </Switch>
    )
}

export {ExamCenter};