
import React, { FC, useRef, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from '../services/auth';
import { Container, Jumbotron, Form, Row, Col, Spinner, Tabs, Tab, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { buildPostFetch } from '../services/base';
import { reversed } from '@popmotion/popcorn';
import { MdVisibility } from 'react-icons/md';

const Login: FC<any> = () => {
    const [bootstrapping, setBootstrapping] = useState(true);
    const [logging, setLogging] = useState(true);
    const [passwordSent, setPasswordSent] = useState(false);
    let emailInput = useRef<HTMLInputElement>(null);
    let passwordInput = useRef<HTMLInputElement>(null);
    let emailResetInput = useRef<HTMLInputElement>(null);
    const [lastCheckLoginDate,setLastCheckLoginDate] = useState<Date>();
    let history = useHistory();

    const performAuthentication = (email:string,password:string) => {
        setLogging(true);
        return Auth.login(email, password.trim()).then(() => {
            localStorage.setItem("credentials",JSON.stringify({email: email,password: password.trim()}));
            history.push('/app');
        });
    }

    const zapCacheAndReload = () => {
        try {
        if (caches) {
            caches.keys().then(function(names) {
              for (let name of names) caches.delete(name);
            });
          }
        } catch(e) {}
        window.location.reload(true);
    }

    const checkSavedCredentials = () =>  {
        // Evitiamo loop - es. in caso di errore di rete
        const now:Date=new Date();
        if(lastCheckLoginDate) {
            const elapsed:number=now.getTime()-lastCheckLoginDate.getTime();
            console.log("Elapsed :" + elapsed);
            if(elapsed<5*1000) {
                console.log("Breaking check saved credential loop");
                setLogging(false);
                setBootstrapping(false);                    
                return;
            }
        }

        // Verifichiamo eventuali credenziali salvate
        let savedCredentials=null;
        let savedCredentialsAsString=localStorage.getItem("credentials");
        if(savedCredentialsAsString) {
            savedCredentials=JSON.parse(savedCredentialsAsString); 
            console.log("Trying saved credentials...",savedCredentials);   
            setLastCheckLoginDate(now);
            performAuthentication(savedCredentials.email,savedCredentials.password).catch((e) => {
                console.log("Error in saved authentication",e);
                setLogging(false);
                setBootstrapping(false);                    
            });
        } else {
            console.log("No saved credentials, bootstrapping");
            if(bootstrapping) setLogging(false);
            setBootstrapping(false);                    
        }
    };
    
    useEffect(() => {
        console.log("bootstrapping=" + bootstrapping);

        // verifichiamo la versione applicativa
        var myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-cache');
        var myInit = {
            method: 'GET',
            headers: myHeaders,
        };
        fetch('/app-info.json',myInit)
            .then((response) => response.json())
            .then((serverInfo) => {
                let reloading=false;
                console.log("server app-info",serverInfo);
                let savedAppInfoAsString=localStorage.getItem("app-info");
                let savedAppInfo=null;
                if(savedAppInfoAsString) {
                    try {
                        savedAppInfo=JSON.parse(savedAppInfoAsString);
                    } catch(e) {
                        savedAppInfo=null;
                    }
                }
                if(!savedAppInfo || !savedAppInfo.version) {
                    console.log("No saved version, storing the server app-info");
                    localStorage.setItem("app-info",JSON.stringify(serverInfo));
                } else {
                    console.log("Saved version present, comparing versions",savedAppInfo.version,serverInfo.version);
                    if(savedAppInfo.version!==serverInfo.version) {
                        console.log("Version mismatch, reloading....");
                        localStorage.removeItem("app-info");
                        zapCacheAndReload();
                        reloading=true;
                    }
                }
                if(!reloading) {
                    checkSavedCredentials();
                }
            });
        });


    const login = (e: any) => {
        e.preventDefault();
        const email = emailInput.current?.value;
        const password = passwordInput.current?.value;
        if (email && email !== '' && password && password.trim() !== '') {
            performAuthentication(email, password.trim()).catch(() => {
                setLogging(false);
                toast.error("Login fallito");
                console.log("Error in authentication",e);
            });
        }
    }

    const reset = (e: any) => {
        e.preventDefault();
        setLogging(true);
        const email = emailResetInput.current?.value;
        if (email && email !== '') {
            let pars={
                email: email
            }
            const [call] = buildPostFetch<any>("/resetPassword",pars);
            call().then((v) => {
                toast.success("Credenziali inviate. Controlla la tua email");
                setLogging(false);
                setPasswordSent(true);
            }).catch((e)=>{
                toast.error("Errore nella operazione");
                setLogging(false);
            });
        } else {
            toast.error("Specifica indirizzo email");
        }
    }

    const togglePassword=useCallback(() => {
        let pt = passwordInput.current;
        if(!pt) return;
        if(pt.type=='text') {
            pt.type="password";
        } else {
            pt.type='text';
        }
    },[]);

    const gotoLogin=useCallback(() => {
        document.location.reload();
    },[]);

    return (
        <Container>
            {logging && 
                <Row className="justify-content-md-center login-loading-outer">
                    <Spinner animation="border" role="status" className="login-loading"></Spinner>
                </Row>
            }
            <Row className="justify-content-md-center">
                <Col xs={6}>
                    <h1  className="mt-5 mb-5" style={{textAlign: 'center', color: 'var(--theme-color1)'}}>DermaCo</h1>
                    <Jumbotron className="shadow login-tabs">
                        <Tabs id="tabs">
                            <Tab eventKey="login" title="Login">
                                <div className="tab-pane">
                                    <Form onSubmit={login}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control ref={emailInput} type="email" placeholder="Enter email" required/>
                                            <Form.Text className="text-muted">
                                                Inserisci il tuo indirizzo mail
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>

                                            <div onMouseDown={togglePassword} className="reveal-password">
                                                <div>
                                                    <MdVisibility size={24}/>
                                                </div>
                                            </div>
                                            <Form.Control type="password" placeholder="Password" ref={passwordInput} required/>
                                        </Form.Group>     
                                        <Button disabled={logging} variant="info" type="submit" className="mt-3">
                                            Login
                                        </Button>                                    
                                    </Form>
                                </div>
                            </Tab>
                            <Tab eventKey="reset" title="Recupero password">
                                <div className="tab-pane">
                                    <Form onSubmit={reset}>
                                        <Form.Group controlId="formResetEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control ref={emailResetInput} type="email" placeholder="Enter email" required/>
                                            <Form.Text className="text-muted">
                                                Inserisci il tuo indirizzo mail. Il sistema ti invier&agrave; nuove credenziali di accesso
                                            </Form.Text>
                                        </Form.Group>
                                        { !passwordSent &&
                                            <Button disabled={logging} variant="info" type="submit" className="mt-3">
                                                Recupero password
                                            </Button>                                    
                                        }
                                        { passwordSent &&
                                            <div style={{marginTop:'20px',fontWeight:'bold'}}>
                                                Ti sono state inviate nuove credenziali di accesso. Controlla la tua email.
                                                <br/>
                                                <Button variant="success" onClick={gotoLogin} className="mt-3">
                                                    Torna alla login
                                                </Button>    
                                            </div>
                                        }
                                    </Form>
                                </div>
                            </Tab>
                        </Tabs>
                    </Jumbotron>
                </Col>
            </Row>
        </Container>
    )
};

export { Login };