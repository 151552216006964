
import styled from 'styled-components';
import React, { FC } from 'react';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    outline: 0;
`;

const Backdrop: FC<any> = (props) => {
    return (
        <Wrapper>{props.children}</Wrapper>
    )
}

export {Backdrop};