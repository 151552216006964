
import React, { FC, useState, useCallback, Fragment, useRef, useEffect, useContext } from "react";
import { Media, MediaComment } from "../../types/media";
import { Card, Row, Col, ButtonGroup, Button, Modal, Form, Alert, InputGroup, Spinner, Badge } from "react-bootstrap";
import { FaAccessibleIcon, FaAddressCard, FaEye, FaTrash } from "react-icons/fa";
import { Check } from "../common/check";
import { BASE, buildPostFetch, buildGetFetch, buildDeleteFetch, API_ROUTE } from "../../services/base";
import { Loading } from "../common/loading";
import { formatDT, formatTS } from "../../utils/dateutils";
import { toast } from "react-toastify";
import { Auth } from "../../services/auth";
import { withZoom, WithZoomProps } from "../common/zoom";
import clsx from "clsx";
import { motion } from "framer-motion";
import { MdSend } from "react-icons/md";
import { ExamContext } from "./examcontext";

interface ImageCardProps {
    media: Media;
    notified?: boolean;
    showImage?:boolean;
    toggleCompare?: (state: boolean) => void;
    onDelete?: () => void;
    onShow?: () => void;
}

interface ImageProps {
    path: string;
}

const ImageCard: FC<ImageCardProps> = ({ media, notified = false, showImage=true, toggleCompare=null, onDelete }) => {
    const {exam,patientData} = useContext(ExamContext);
    const [checked, setChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);

    const updateChecked = useCallback((state: boolean) => {
        setChecked(state);
        if(toggleCompare) toggleCompare(state);
    }, [toggleCompare]);

    const deleteMedia = useCallback(() => {
        setShowRemoveModal(true);
    }, []);

    const actuallyDeleteMedia = useCallback(() => {
        setShowRemoveModal(false);
        if (onDelete) {
            onDelete();
        }
    }, [onDelete]);

    const decoratePatient=(p:any) => {
        return p.surname + " " + p.name + " (Paziente)";
    }

    const decorateUser=(p:any) => {
        return "Dott. " + p.surname + " " + p.name + " (Medico)";
    }

    return (
        <Fragment>
            <Card className="media-card">
                { toggleCompare &&
                    <div className="check-container">                    
                        <Check check={checked} onChange={updateChecked}></Check>
                    </div>
                }
                <div className="image-card-container">
                    <motion.img className={clsx(notified && 'notified', '')} 
                        src={`${BASE}${API_ROUTE}${media.path}${media.name}`}  style={{height: '100%', position: 'relative', marginBottom: '1rem'}}/>
                </div>
                <Card.Body>
                    <Card.Title>{media.visual_model_point?.category}</Card.Title>
                    <p className="mb-1"><label className="mb-1">Data:</label>&nbsp;{formatDT(media.insert_ts)}</p>
                    <p className="mb-1"><label className="mb-1">Nota:</label>&nbsp;{media.visual_model_point?.note}</p>

                    <Row>
                        <Col>
                            <ButtonGroup>
                                { showImage && <Button title="Mostra" variant="info" onClick={() => { setShowModal(true); }}><FaEye></FaEye></Button>}
                                { onDelete && <Button title="Elimina" variant="danger" onClick={deleteMedia}><FaTrash></FaTrash></Button>}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <div className="image-source">
                        {media.patient_id && <span><FaAccessibleIcon></FaAccessibleIcon>&nbsp;{decoratePatient(patientData.patient)}</span>}
                        {media.user_id && <span><FaAddressCard></FaAddressCard>&nbsp;{decorateUser(exam.owner)}</span>}
                    </div>
                    {media.media_comments &&
                        <Fragment>
                            <hr/>
                            {media.media_comments.map((c) => {
                                return (
                                    <div key={c.id}>
                                        {c.message}
                                    </div>
                                );
                            })}
                        </Fragment>
                    }
                    
                </Card.Body>
            </Card>
            {showModal && <ImageModal image={media} show={showModal} onHide={() => { setShowModal(false); }} ></ImageModal>}
            {showRemoveModal &&
                <Modal show={true}>
                    <Modal.Header>Avvertimento</Modal.Header>
                    <Modal.Body>
                        Il sistema rimuoverà l'immagine selezionata. Continuare?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button title="Conferma" variant="warning" onClick={actuallyDeleteMedia}>Conferma</Button>
                        <Button title="Annulla" variant="danger" onClick={() => { setShowRemoveModal(false)}}>Annulla</Button>
                    </Modal.Footer>
                </Modal>
            }
        </Fragment>)
}

interface ImageModalProps {
    image: Media;
    show: boolean;
    onHide: () => void;
}

const Image = React.forwardRef<HTMLImageElement, ImageProps>(
    ({ path }, ref) => {
        return (
            <img alt="Immagine" ref={ref} src={path} style={{ position: 'relative', border: '1px solid #ccc' }} />
        )
    });

const ImageWithZoom: FC<ImageProps & WithZoomProps> = withZoom(Image);

const ImageModal: FC<ImageModalProps> = ({ image, show, onHide }) => {

    const commentInput = useRef<HTMLTextAreaElement>(null);
    const [comments, setComments] = useState<Array<MediaComment>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [sending, setSending] = useState<boolean>(false);

    const [zoom, setZoom] = useState<boolean>(false);
    const [zoomLevel, setZoomLevel] = useState<number>(1.5);

    const loadComments = useCallback(() => {
        const [fetch] = buildGetFetch<Array<MediaComment>>(`/media/${image.id}/comment`, {}, setComments, setLoading);
        fetch();
    },[image.id]);

    useEffect(loadComments, []);
    
    const saveComment = useCallback(() => {
        setSending(true);
        const comment = commentInput.current?.value;
        if (comment && comment.trim() !== "") {
            const [post] = buildPostFetch<MediaComment>(`/media/${image.id}/comment`, { message: comment }, { toastError: true }, () => { }, setSending);
            post().then((comment) => {
                if (comment !== null) {
                    loadComments();
                    toast.success("Annotazione salvata");
                    //@ts-ignore
                    commentInput.current.value = '';
                }
            })
        } else {
            setSending(false);
            toast.warning("Impossibile aggiungere un commento vuoto");
        }
    }, [image.id,loadComments]);

    const deleteComment = useCallback((id: number) => {
        const [deleteApi] = buildDeleteFetch<MediaComment>(`/media/${image.id}/comment/${id}`, { toastError: true });
        deleteApi().then((comment) => {
            loadComments();
            toast.success("Annotazione rimossa");
        })
    }, [image.id,loadComments])

    return (
        <Modal show={show} onHide={onHide} dialogClassName="modal-90w">
            <Modal.Header closeButton>
                <div className="d-flex align-items-center">
                    <Button variant={zoom ? "light" : "info"} onClick={() => {setZoom(!zoom)}}>{zoom ? "Disattiva Zoom" : "Attiva Zoom"}</Button>
                    {zoom && <div>
                        <input style={{marginLeft: '1rem', marginRight: '1rem'}} type="range" min={1} max={2} step={0.25} value={zoomLevel} onChange={(e)=>{setZoomLevel(+e.target.value)}}/>
                        ZOOM: <Badge variant="dark">{zoomLevel} X</Badge>
                    </div>}
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-row">
                    <div className="shadow-sm img-drag-wrapper">
                        <div>
                            <ImageWithZoom zoomEnabled={zoom} path={`${BASE}${API_ROUTE}${image.path}${image.name}`} zoomLevel={zoomLevel} zoomSize={300} />
                        </div>
                    </div>
                    <div className="p-2 flex-grow-1">
                        <div className="d-flex flex-column">
                            <Loading show={loading} message="Caricamento annotazioni..." />
                            {!loading && comments &&
                                <Fragment>
                                    <div className="media-comment">
                                        <InputGroup className="mb-3">
                                            <Form.Control disabled={sending} ref={commentInput} as="textarea" rows={3} placeholder="Inserisci nuova annotazione" />
                                            <InputGroup.Append>
                                                <Button disabled={sending} onClick={saveComment} title="Invia annotazione" variant="info">
                                                    {sending ? <Fragment>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        <span className="sr-only">Invio...</span>
                                                    </Fragment> : <MdSend/>}
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                    <h5>Annotazioni</h5>
                                    {comments.length === 0 && <Alert variant="info">Nessuna annotazione presente</Alert>}
                                    <div className="comments-container">
                                        {comments.map((c: MediaComment) => {
                                            return (<div className="rounded-2 mb-2 p-2 media-comment bg-light" key={c.id}>
                                                <div>
                                                    {c.message}
                                                </div>
                                                <div className="d-flex flex-row justify-content-end">
                                                    <div className="text-right mr-2">
                                                        <p className="author">{c.author.name + ' ' + c.author.surname}</p>
                                                        <p className="time text-muted">{formatTS(c.insert_ts)}</p>
                                                    </div>
                                                    {Auth.user()?.id === c.author.id && <div>
                                                        <Button onClick={() => { deleteComment(c.id) }} variant="light" title="Elimina annotazione"><FaTrash></FaTrash></Button>
                                                    </div>}
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export { ImageCard };

