
import React, {FC} from "react";
import styled from "styled-components";
import clsx from "clsx";

const Container = styled.div`
    border-left: 2px solid var(--theme-color1);

`;

export const Callout: FC<{className: any}> = ({children, className}) => {
    return <Container className={clsx('shadow-sm', 'bg-light', className)}>{children}</Container>
}