
import React, { FC, useCallback, useContext, useState, useEffect, Fragment } from "react";
import { Formik } from 'formik';
import { Form, Col, Modal, InputGroup, Tabs, Tab, Alert, Badge } from 'react-bootstrap';
import { buildGetFetch, buildPostFetch } from "../../services/base";
import { toast } from "react-toastify";
import { ExamContext } from "./examcontext";
import { User } from "../../types/user";
import { Invitation } from "../../types/invitation";
import { EnumEntity, getAllExamPermissions } from "../../services/enums";
import Switch from "react-switch";
import { Loading } from "../common/loading";
import { EnumRow } from "../common/enumrow";
import { LoadingButton } from "../common/loadingbutton";
import { MdSend } from "react-icons/md";
import { Auth } from "../../services/auth";

type NewPermissionProps = {
    onCancel: () => void;
    onInvite: (invitation: Invitation) => void;
    onError: () => void;
}

const NewPermission: FC<NewPermissionProps> = ({ onCancel, onError, onInvite }) => {
    const { exam } = useContext(ExamContext);
    const [ready, setReady] = useState<boolean>(false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [selectedTab,setSelectedTab] = useState<string>("cst");

    const validateEmail=(email:string) => {
        const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return res.test(String(email).toLowerCase());
      }

    const submit = useCallback((values: any) => {
        let data:any = {
            permissions: values.permissions.filter((p: any) => p.checked).map((p: any)=> p.code)
        }

        // bugid#6885
        // se specialista esterno togliamo d'ufficio alcune abilitazioni
        console.log("selectedTab",selectedTab);
        if(selectedTab=="external") {
            console.log("permissions-before",data.permissions);
            let filtered=data.permissions.filter((x:any) => x==100 /*|| x==200*/ || x==300 || x==320 || x==600);
            console.log("permissions-after",filtered);
            data.permissions=filtered;
        }

        if(values.user) {
            data.user_id=values.user.id;
        } else if(values.email) {
            data.email=values.email;
            if(!validateEmail(data.email)) {
                toast.warn("Indirizzo email non valido");
                return;
            }
        } else {
            toast.warn("Dati mancanti o non validi");
            return;
        }
        const [create] = buildPostFetch<Invitation>(`/user/exam/${exam.id}/invitation`, data, { toastError: true});
        setSubmitting(true);
        create().then((invitation) => {
            setSubmitting(false);
            if(invitation !== null){
                if(invitation._message)
                    toast.success(invitation._message);
                else
                    toast.success("Invito predisposto");
                onInvite(invitation);
            } else {
                onError();
            }
        }).catch(() =>{
            setSubmitting(false);
        });
    }, [exam,onError,onInvite,selectedTab]);

    const [enums, setEnums] = useState<Array<EnumEntity>>([]);
    const [isInternalUsersLoading, setInternalUsersLoading] = useState<boolean>(false);
    const [internalUsers,setInternalUsers] = useState<Array<User>>([]);
    const [isNonInternalUsersLoading, setNonInternalUsersLoading] = useState<boolean>(false);
    const [nonInternalUsers,setNonInternalUsers] = useState<Array<User>>([]);
    const [internalFilter,setInternalFilter] = useState<string>("");
    const [nonInternalFilter,setNonInternalFilter] = useState<string>("");

    const loadInternalUsers = () => {
        const qp:any={
            value: '',
            type:'spec-internal'
        }
        const [search] = buildGetFetch<Array<User>>("/user/search", {queryParams: qp}, setInternalUsers, setInternalUsersLoading);
        search();
    }

    const loadNonInternalUsers = () => {
        const qp:any={
            value: '',
            type:'spec-not-internal'
        }
        const [search] = buildGetFetch<Array<User>>("/user/search", {queryParams: qp}, setNonInternalUsers, setNonInternalUsersLoading);
        search();
    }

    useEffect(() => {
        getAllExamPermissions().then((_c) => {
            setEnums(_c);
            setReady(true);
        })
        loadInternalUsers();
        loadNonInternalUsers();
    }, []);

    const filterForUsers = (users:Array<User>,filter:string):Array<User> => {
        if(!filter) return users;
        const f:string=filter.toLowerCase();
        return users.filter(u => {
            if(u.surname && u.surname.toLowerCase().indexOf(f)>=0) return true;
            if(u.name && u.name.toLowerCase().indexOf(f)>=0) return true;
            if(u.email && u.email.toLowerCase().indexOf(f)>=0) return true;
            if(u.user_contact_info && u.user_contact_info.specialization && u.user_contact_info.specialization.toLowerCase().indexOf(f)>=0) return true;
            if(u.user_contact_info && u.user_contact_info.presentation && u.user_contact_info.presentation.toLowerCase().indexOf(f)>=0) return true;
            return false;
        })
    }

    const changedTab = (x:string) => {
        console.log("selecting tab",x);
        setSelectedTab(x);
    }

    // Disabilitiamo il riquadro dei pemessi se non siamo
    // specialisti o amministratori
    let permissionStyle:any={margin:'0.5rem',marginTop:'1rem'}
    let permissionBlockedStyle:any={display:'none',margin:'0.5rem',marginTop:'1rem'}
    if(!Auth.isSpecialist() && !Auth.isAdmin()) {
        permissionStyle.display='none';
        permissionBlockedStyle.display='block';
    }

    return (<div>
        <Modal show={true} onHide={onCancel} size="xl">
            <Modal.Header closeButton>
                Nuovo invito
            </Modal.Header>
            <Modal.Body>
                <Loading show={!ready} message="Attendere..."/>
                {ready &&
                <Formik

                    onSubmit={submit}
                    initialValues={{
                        user: null,
                        email: null,
                        permissions: enums.map((e) => {return {checked: true, id: e.id, code: e.code}})
                    }}>

                    {({ values, errors, touched, handleSubmit, setFieldValue}) => (

                        <Form noValidate onSubmit={handleSubmit}>

                            <div style={{width:'70%',float:'left',paddingRight:'20px',marginBottom:'18px'}}>
                                <Tabs defaultActiveKey="cst" id="consultant-selector" style={{margin:"0",borderBottom:'1px solid #dee2e6'}} onSelect={(x:string) => { setFieldValue("user", undefined, true ); changedTab(x) }}>
                                    <Tab eventKey="cst" title="Specialista Dermaco">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>Seleziona specialista Dermaco</Form.Label>
                                            <label style={{fontWeight:'normal',fontStyle:'italic'}}>Ricerca tra gli specialisti disponibili utilizzando nome/cognome dello specialista, specialità o patologia dermatologica di interesse</label>
                                            <InputGroup>
                                                <Form.Control type="text" onChange={(e) => setInternalFilter(e.target.value)} value={internalFilter} />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">{errors.user}</Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="invitation_userlist_container">
                                            {internalUsers && filterForUsers(internalUsers,internalFilter).map((option, index) => {
                                                return <div key={'u_' + option.id} onClick={(e) => { if(option===values.user) setFieldValue("user", undefined, true ); else setFieldValue("user", option, true ); }  }
                                                                className={(option===values.user)?"selected":""}>
                                                    <div style={{float:'right'}}>
                                                        <Badge variant="secondary">{option.advice_cost}&euro;</Badge>
                                                    </div>
                                                    <div className="highlight">
                                                        Dott. {option.surname} {option.name} &mdash; {option.email}
                                                    </div>
                                                    <div>
                                                    {option.user_contact_info &&
                                                        <small>
                                                        {option.user_contact_info.specialization &&
                                                            <Fragment>
                                                            {option.user_contact_info.specialization.split(',').map((e:string,i:number) => {
                                                                return <Badge variant="secondary" className="specialization-badge" key={i}>{e}</Badge>
                                                            })}
                                                            </Fragment>
                                                        }
                                                        <br/>
                                                        {option.user_contact_info.presentation}
                                                        </small>
                                                    }
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="other" title="Altro Specialista">
                                        <Form.Group as={Col} controlId="formGridEmail2">
                                            <Form.Label>Seleziona altro specialista aderente al circuto Dermaco</Form.Label>
                                            <label style={{fontWeight:'normal',fontStyle:'italic'}}>Ricerca tra gli specialisti disponibili utilizzando nome/cognome dello specialista, specialità o patologia dermatologica di interesse</label>
                                            <InputGroup>
                                                <Form.Control type="text" onChange={(e) => setNonInternalFilter(e.target.value)} value={nonInternalFilter} />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">{errors.user}</Form.Control.Feedback>
                                        </Form.Group>
                                        <div className="invitation_userlist_container">
                                            {nonInternalUsers && filterForUsers(nonInternalUsers,nonInternalFilter).map((option, index) => {
                                                return <div key={'u_' + option.id} onClick={() => { if(option===values.user) setFieldValue("user", undefined, true ); else setFieldValue("user", option, true ); }}
                                                                className={(option===values.user)?"selected":""}>
                                                    <div style={{float:'right'}}>
                                                        <Badge variant="secondary">{option.advice_cost}&euro;</Badge>
                                                    </div>
                                                    <div className="highlight">
                                                        Dott. {option.surname} {option.name} &mdash; {option.email}
                                                    </div>
                                                    <div>
                                                    {option.user_contact_info &&
                                                        <small>
                                                        {option.user_contact_info.specialization &&
                                                            <Fragment>
                                                            {option.user_contact_info.specialization.split(',').map((e:string,i:number) => {
                                                                return <Badge variant="secondary" className="specialization-badge" key={i}>{e}</Badge>
                                                            })}
                                                            </Fragment>
                                                        }
                                                        <br/>
                                                        {option.user_contact_info.presentation}
                                                        </small>
                                                    }
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </Tab>
                                        <Tab eventKey="external" title="Specialista Esterno">
                                            <Form.Group as={Col} controlId="formGridEmail3">
                                                <Form.Control type="email" placeholder="Indirizzo email" onChange={(e) => {
                                                    setFieldValue('email', e.target.value, true);
                                                }}/>
                                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Tab>
                                </Tabs>
                            </div>

                            <div style={{width:'30%',float:'left',marginBottom:'18px',marginTop:'20px'}}>
                                { selectedTab!=="external" ?
                                    <div style={permissionBlockedStyle}>
                                        <Alert variant="info">
                                            <h5>Modalità di ricerca</h5>
                                            <p>
                                                La ricerca di uno specialista può avvenire specificando
                                                nome, cognome, specialità o patologia dermatologica.
                                            </p>
                                        </Alert>
                                    </div>
                                    :
                                    <div style={permissionBlockedStyle}>
                                        <Alert variant="info">
                                            <h5>Modalità di ricerca</h5>
                                            <p>
                                                Invita uno specialista esterno indicando il suo indirizzo email.
                                                Lo specialista riceverà un messaggio con credenziali temporanee per accedere al
                                                sistema Dermaco.
                                            </p>
                                        </Alert>
                                    </div>
                                }
                                <div style={permissionStyle}>
                                    {enums.map((e, index) => {
                                        return <Form.Row key={'permission_' + e.id}>
                                            <Form.Group as={Col} className="p-2 d-flex">
                                                <Switch checked={values.permissions[index].checked} onChange={(c: boolean) => {
                                                        setFieldValue(`permissions[${index}].checked`, c, true);
                                                    }}></Switch>
                                                <div>
                                                    <EnumRow entity={e}/>
                                                </div>
                                                </Form.Group>
                                        </Form.Row>
                                    })}
                                </div>
                                { selectedTab!=="external" &&
                                    <div style={permissionBlockedStyle}>
                                        <Alert variant="warning">
                                            <h5>Impostazioni visibilità</h5>
                                            <p>
                                                Lo specialista invitato potrà accedere ai dati della visita e del paziente
                                                e potrà definire il parere medico.<br/>
                                                Potrà inoltre comunicare con il paziente.
                                            </p>
                                        </Alert>
                                    </div>
                                }
                            </div>

                            <LoadingButton type="submit" size="lg" block variant="info" loading={isSubmitting} disabled={isSubmitting}>
                                <MdSend/> Spedisci invito
                            </LoadingButton>
                        </Form>
                    )}
                </Formik>}

            </Modal.Body>
        </Modal>
    </div>)
}

export { NewPermission }
