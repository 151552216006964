import Axios from "axios"
import { API_ROUTE } from "./base";

export type EnumEntity  = {
    id: number;
    code: number;
    name: string;
    desc: string;
}

export const getAllExamPermissions = () : Promise<Array<EnumEntity>> => {
    return Axios.get(`${API_ROUTE}/enum/permissions`).then((r) => {
        return r.data;
    });
}

export const getAllExamStates = () : Promise<Array<EnumEntity>> => {
    return Axios.get(`${API_ROUTE}/enum/states`).then((r) => {
        return r.data;
    });
}

export const getAllExamCapabilities = () : Promise<Array<EnumEntity>> => {
    return Axios.get(`${API_ROUTE}/enum/capabilities`).then((r) => {
        return r.data;
    });
}