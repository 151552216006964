
import React, {FC, useContext, Fragment} from "react";
import { EXAM_PERMISSION_ENUM } from "../../types/enums";
import { ExamContext } from "./examcontext";

type WithPermissionProps = {
    permission: EXAM_PERMISSION_ENUM;
}

export const WithPermission: FC<WithPermissionProps> = ({permission, children}) => {

    const {hasPermission} = useContext(ExamContext);

    if(hasPermission(permission)){
        return <Fragment>{children}</Fragment>
    }
    return null;
    /*
    return <div>
        <Alert variant="warning">
            <Alert.Heading>
                {EMOJI.SORRY} Spiacente
            </Alert.Heading>
            <hr/>
            <p>La funzionalità non è disponible o non è in possesso dei permessi necessari</p>
        </Alert>
    </div>
    */
}