
//bootstrap customization and import
import './bootstrap.scss';
//autocomplete css
import 'react-bootstrap-typeahead/css/Typeahead.css';
//toast css
import 'react-toastify/dist/ReactToastify.css';
//date picker css
import "react-datepicker/dist/react-datepicker.css";
//main css
import './assets/css/index.css';
//theming stuff
import './assets/css/theme.css';

import React from 'react';
import ReactDOM from 'react-dom';
import Main from './main';
import * as serviceWorker from './serviceWorker';
import {Auth} from './services/auth';
import { ToastContainer } from 'react-toastify';
import { EMOJI } from './utils/emoji';
import { setDefaultLocale, registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';

registerLocale('it', it);

setDefaultLocale('it');

//setupVersioning();

Auth.start().then(() => { 
  ReactDOM.render(
    <React.Fragment>
      <Main/>
      <ToastContainer/>
    </React.Fragment>,
    document.getElementById('root')
  );
  
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}).catch((reason: any) => {
  ReactDOM.render(
    <React.StrictMode>
      <div style={{textAlign: 'center', marginTop: '25vh'}}>
        <h4>{EMOJI.INVALID}</h4>
        <p>Server non raggiungibile</p>
        <p>Contatta il supporto</p>
        <pre>{JSON.stringify(reason.message)}</pre>
      </div>
    </React.StrictMode>,
    document.getElementById('root')
  );
});



