
import { EventEmitter } from 'events';
import { NotificationService, USER_CHANNEL } from './notificationservice';
import { Invitation } from '../types/invitation';
import { toast } from 'react-toastify';

interface IService {
    /**
     * Get current pending invites
     */
    getPendingInvites: () => number;
    /**
     * pending invites change notifier
     */
    emitter: () => EventEmitter;
    /**
     * Decrement invites shortcut
     */
    decrementInvites: () => void;
    /**
     * Increment invites shortcut
     */
    incrementInvites: () => void;
    /**
     * Pending invites setter
     */
    setPendingInvites: (inv: number) => void;
}

export const CHANGE_EVENT = 'PENDING_INVITES_CHANGE';

export const InviteService: IService = (() => {
    
    let _invites: number = 0;

    let _emitter = new EventEmitter();

    const _incrementInvites = () => {
        _invites++;
        _emitter.emit(CHANGE_EVENT);
    };

    const _decrementInvites = () => {
        _invites--;
        if(_invites<0) _invites=0;
        _emitter.emit(CHANGE_EVENT);
    }

    NotificationService.on(USER_CHANNEL.events.UserInvitation, (e: {
        user_id: number,
        invitation: Invitation
    }) => {
        toast.info("Nuovo invito ricevuto");
        _incrementInvites();
    });
    
    return {
        setPendingInvites: (inv: number) => {
            let toEmit=(_invites!=inv);
            _invites = inv;
            if(toEmit) _emitter.emit(CHANGE_EVENT);
        },
        getPendingInvites: () => {
            return _invites;
        },
        emitter: () => {
            return _emitter;
        },
        decrementInvites: _decrementInvites,
        incrementInvites: _incrementInvites
    }

})();
