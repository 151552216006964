import React, {FC, useCallback, useState, useEffect, useContext, useMemo, useRef} from 'react';
import { Alert, Button, Form } from "react-bootstrap";
import { buildDeleteFetch, buildGetFetch, buildPostFetch } from '../../services/base';
import { ExamVisualModel } from '../../types/exam';
import { Point } from '../../types/point';
import { Loading } from '../common/loading';
import { ExamContext } from './examcontext';
import { Scene, Color, Group, PerspectiveCamera, WebGLRenderer, HemisphereLight, Raycaster, SphereGeometry, Mesh, MeshBasicMaterial, Vector3 } from 'three';
import { OrbitControls } from '../../libs/orbit';
import { BodyModel, ModelFactory } from '../../utils/game/models/bodymodel';
import { CacheLoader } from '../../utils/game/CacheLoader';
import { Media } from '../../types/media';
import { ImageCard } from './imagecard';
import { MdChevronLeft, MdSave } from 'react-icons/md';
import { FaFile, FaFileUpload, FaTrash, FaUpload } from 'react-icons/fa';
import { toast } from 'react-toastify';

const BodyCollector: FC = () => {

    //let th:any={}    
    let th_animationFrame:any=null;

    /**
     * Max points on the scene
     */
    const MAX_POINTS: number = 50;

    const {exam} = useContext(ExamContext);
    const [images, setImages] = useState<Media[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [preparingUpload, setPreparingUpload] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [points, setPoints] = useState<Array<Point>>([]);
    const canvas=useRef<HTMLCanvasElement|null>(null);
    const [visualModel, setVisualModel] = useState<ExamVisualModel|null>(null);
    const [bodyModels, setBodyModels] = useState<any>({});
    const [selectedPoint, setSelectedPoint] = useState<Point|null>(null);
    const [fileToUpload, setFileToUpload] = useState<any>();
    const [th,setTh] = useState<any>({});

    const refreshMedia=useCallback(() => {
        const [fetch, cancel] = buildGetFetch<Array<Media>>(`/user/exam/${exam.id}/media`, {}, setImages);
        fetch();
        return () => {cancel();}
    },[exam]);

    useEffect(() => {
        refreshMedia();
    }, [refreshMedia])

    useEffect(() => {
        const [fetchModel] = buildGetFetch<ExamVisualModel>(`/exam/${exam.id}/model`,{silent:true});
        setLoading(true);
        fetchModel()
            .then((m: ExamVisualModel | null) => {
                // Modello esistente, ecuperiamo i punti del modello
                if(m) {
                    setVisualModel(m);
                    const [pointsFetch] = buildGetFetch<Array<Point>>(`/exam/${exam.id}/model/${m.id}/point`,{}, setPoints, setLoading, setError);
                    pointsFetch().then(async (pps: Array<Point>|null) => {
                        if(pps!=null && canvas.current!=null) {
                            setPoints(pps);
                            layoutPoints(canvas.current, ModelFactory.createFromVisualModel(m),pps);
                        }
                    });
                }
            })
            .catch(() => {
                // Modello non esistente, ne creiamo uno di default
                const [createModel] = buildPostFetch<ExamVisualModel>(`/exam/${exam.id}/model/`, {
                    /**
                     * Model ID is calculated from patient genre
                     */
                    //model_id: 1,
                    model_skin: 'Caucasico',
                });
                createModel().then(async (m: ExamVisualModel | null)=> {
                    setVisualModel(m);
                    setPoints([]);
                    if(m!=null && canvas.current!=null) {
                        layoutPoints(canvas.current, ModelFactory.createFromVisualModel(m),[]);
                    }
                });
            });

        return () => {
            resetPoints();
            disposeBody();
        }
    },[setPoints,exam]);

    const pointClickedOnList=useCallback((p:Point) => {
        selectPoint(p.uuid);
        setSelectedPoint(p);
    },[]);

    const disposeBody = () => {
        console.log("dispose body");

        /**
         * Remove model from the scene
         */
        if(th.currentModel){
            th.scene.remove(bodyModels[th.currentModel.name]);
            th.scene.remove(th.pointsGroup);
        }
        /**
         * Dispose all cached models
         */
        Object.keys(bodyModels).forEach((k: string) => {
            const group = bodyModels[k];
            if(group.children){
                group.children.forEach((v: Mesh) => {
                    v.geometry.dispose();
                    // @ts-ignore
                    v.material.dispose();
                })
            }
        });
        if(th.pointsGroup && th.pointsGroup.children){
            th.pointsGroup.children.forEach((v: Mesh) => {
                v.geometry.dispose();
                // @ts-ignore
                v.material.dispose();
            })
        }
        /**
         * Disponse scene
         */
        th.scene.dispose();
        // th={}
        setTh({})
    }

    const resetPoints = () => {
        console.log("reset points");
        for (let p of points) {
            const point: any = th.pointsGroup.getObjectByProperty('uuid', p.uuid);
            point.geometry.dispose();
            point.material.dispose();
            th.pointsGroup.remove(point);
        }
        setPoints([])
    }

    const removePoint = (uuid: string,deleteFromList:boolean) => {
        const point: any = th.pointsGroup.getObjectByProperty('uuid', uuid);
        if (point) {
            point.geometry.dispose();
            point.material.dispose();
            th.pointsGroup.remove(point);
            const stateIndex = th.points.findIndex((p:any) => p.uuid === uuid);
            if(deleteFromList) th.points.splice(stateIndex, 1);
            console.warn(`Point removed ${uuid}`);
        } else {
            console.warn("Point not found");
        }
    }

    const selectPoint = (uuid: string) => {
        const point: any = th.pointsGroup.getObjectByProperty('uuid', uuid);
        if (point) {
            th.selectedUuid=uuid;
            point.material=new MeshBasicMaterial({ color: 0xffb84d });
            console.warn(`Point selected ${uuid}`);
        } else {
            console.warn("Point not found");
        }
    }

    const doUnselectPoint=useCallback(() => {
        console.log("selectedPoint in doUnselectPoint",selectedPoint);
        if(selectedPoint && !selectedPoint.id) {
            removePoint(selectedPoint.uuid,false);
        }
        unselectPoint();
        setSelectedPoint(null);
    },[selectedPoint]);

    const unselectPoint = () => {
        if(!th.selectedUuid) return;
        const point: any = th.pointsGroup.getObjectByProperty('uuid', th.selectedUuid);
        th.selectedUuid=null;        
        if (point) {
            point.material=new MeshBasicMaterial({ color: 0x8a0000 });
            console.warn(`Point unselected`);
        } else {
            console.warn("Point not found");
        }
    }   

    const addPoint = (point: any,th: any) => {
        if (points.length < MAX_POINTS) {
            let highlight:boolean=false;
            const geometry = new SphereGeometry(0.1, 10, 10);
            const material = new MeshBasicMaterial({ color: 0x8a0000 });
            const mesh = new Mesh(geometry, material);
            mesh.scale.set(0.7, 0.7, 0.7);
            mesh.position.x = point.x;
            mesh.position.y = point.y;
            mesh.position.z = point.z;
            console.warn("Adding point",point,highlight);
            if(point.uuid){
                //if I have an uuid I use that
                mesh.uuid = point.uuid;
            } else {
                //I use the generated mesh UUID
                point.uuid = mesh.uuid;            
            }
            th.pointsGroup.add(mesh);
        } else {
            console.warn("Max points reached")
        }
    }

    const showModelOnScene = (model: BodyModel, th: any) => {
        if(th.currentModel){
            //remove from scene previous model
            th.scene.remove(bodyModels[th.currentModel.name]);
        }
        console.log("setting currentModel",model);
        th.currentModel=model;
        const obj = bodyModels[model.name];
        const { scale, position, rotation } = model;
        obj.traverse(function (child:any) {
            if (child instanceof Mesh) {
                child.material.color.setHex(model.skin);
                //we need this to understand when we touch the body mesh
                //child.geometry.computeBoundingBox();
                //child.geometry.boundingBox;
            }
        });
        th.scene.add(obj);
        if (position) {
            if (position.x !== undefined) {
                obj.position.x = position.x;
            }
            if (position.y !== undefined) {
                obj.position.y = position.y;
            }
            if (position.z !== undefined) {
                obj.position.z = position.z;
            }
        }
        if (rotation) {
            if (rotation.x !== undefined) {
                obj.rotateX(rotation.x);
            }
            if (rotation.y !== undefined) {
                obj.rotateX(rotation.y);
            }
            if (rotation.z !== undefined) {
                obj.rotateX(rotation.z);
            }
        }
        obj.scale.set(scale.x, scale.y, scale.z);
        obj.name = 'BODY_MODEL_OBJ';
    }

    const loadModel = async (model: BodyModel, th: any) => {
        if(th.currentModel && model.name === th.currentModel.name){
            return;
        }
        if (bodyModels[model.name]) {
            console.warn("Using cached model");
            showModelOnScene(model,th);
        } else {
            console.warn(`Loading model ${process.env.PUBLIC_URL}/${model.file}`);

            const object = await CacheLoader.getModel(model).catch(() => {
                console.error("Error loading model");
                return null;
            });
            console.log("Loaded");
            if(object !== null){
                bodyModels[model.name] = object;
                showModelOnScene(model,th);
            }
        }
    }

    const layoutPoints = (canvas: HTMLCanvasElement, defaultModel: BodyModel, points: Array<Point>) => {
        console.log("model",defaultModel);
        console.log("points",points);

        const height = 600; //window.innerHeight;
        const width = 350; // window.innerWidth;
        const fov = 75;

        th.scene = new Scene();
        th.scene.background = new Color(0xe3ecff);
        th.camera = new PerspectiveCamera(fov, width / height, 0.1, 100);

        th.pointsGroup = new Group();
        th.pointsGroup.name = "POINTS_SPHERE_GROUP";

        const renderer = new WebGLRenderer({
            canvas: canvas,
            antialias: true
        });
        renderer.setSize(width, height);

        const light = new HemisphereLight(0xfffcf2, 0x3D3D3D, 1.2);
        th.scene.add(light);

        const raycaster = new Raycaster();
        th.raycaster=raycaster;
        th.controls = new OrbitControls(th.camera, canvas);

        th.controls.enableKeys = false;
        th.controls.enablePan = false;
        th.controls.maxDistance = 11;
        th.controls.minDistance = 3;
        th.camera.position.x = 0;
        th.camera.position.z = 7;
        th.camera.position.y = 1;
        th.controls.update();
        th.points=points;

        let init = false;

        const animate = () => {
            if(!th.scene) {
                if(th_animationFrame)
                    cancelAnimationFrame(th_animationFrame);
                return;
            }

            th_animationFrame=requestAnimationFrame(animate);
            renderer.render(th.scene, th.camera);
            if (!init && defaultModel != null) {
                init = true;
                if (points.length > 0) {
                    try {
                        for (let p of points) {
                            //console.log(p);
                            addPoint({
                                x: p.vector.x,
                                y: p.vector.y,
                                z: p.vector.z,
                                uuid: p.uuid
                            },th);
                        }
                    } catch (e) {
                        console.warn("Invalid points found");
                    }
                }
                //console.warn("Adding points group to the scene");
                th.scene.add(th.pointsGroup);
            }
        };

        loadModel(defaultModel,th);
        animate();
    };

    const bodyClicked=useCallback((event) => {
        if(canvas.current==null) return;
        let body = th.currentModel;
        console.log("mousedown",body,th)
        if (body != null) {
            const canvasBounds = canvas.current.getBoundingClientRect();
            const mouseVector = new Vector3();
            mouseVector.x = ((event.clientX - canvasBounds.left) / canvas.current.clientWidth) * 2 - 1;
            mouseVector.y = - ((event.clientY - canvasBounds.top) / canvas.current.clientHeight) * 2 + 1;
            //update raycast on mouse-camera
            th.raycaster.setFromCamera(mouseVector, th.camera);
            //check intersections
            const intersects = th.raycaster.intersectObjects(th.scene.children, true);
            console.log("intersects",intersects);
            if (intersects.length > 0) {
                const firstIntersection = intersects[0];
                const { point, object } = firstIntersection;
                const { uuid } = object.parent;
                if (object.parent.name === "BODY_MODEL_OBJ") {
                    //body intersection
                    console.warn("Touched body",point);
                    addPoint(point,th);
                    let newPoint:Point={
                        id: 0,
                        category: "Nuovo punto",
                        note: "",
                        uuid: point.uuid,
                        vector: {
                            x: point.x,
                            y: point.y,
                            z: point.z
                        }
                    }
                    selectPoint(point.uuid);
                    setSelectedPoint(newPoint);
                } else if (uuid === th.pointsGroup.uuid) {
                    // possible point intersection
                    console.warn(`intersection: ${object.uuid}`,th.points);
                    const pointTouchIndex = th.points.findIndex((pg:any) => pg.uuid === object.uuid);
                    if (pointTouchIndex >= 0) {
                        let selected=th.points[pointTouchIndex];
                        selectPoint(selected.uuid);
                        setSelectedPoint(selected);
                        console.log("TOUCHED",selected);
                    } else {
                        console.error("Should never happen");
                    }
                }
            }
        }
    },[selectedPoint]);

    const doSavePoint = useCallback(() => {
        if(!visualModel) return;
        if(!selectedPoint) return;
        setUpdating(true);
        const [savePoint] = buildPostFetch<Point>(`/exam/${exam.id}/model/${visualModel.id}/point`, {
            vector: {
                x: selectedPoint.vector.x,
                y: selectedPoint.vector.y,
                z: selectedPoint.vector.z
            },
            uuid: selectedPoint.uuid,
            note: selectedPoint.note,
            category: selectedPoint.category,
            id: (selectedPoint.id!=0)?selectedPoint.id:null
        });
        savePoint().then((p: Point|null) => {
            setUpdating(false);
            setSelectedPoint(p);
        });
    }, [exam, visualModel, selectedPoint]);

    const doRemovePoint = useCallback(() => {
        if(!visualModel) return;
        if(!selectedPoint) return;
        setUpdating(true);
        const [deletePoint] = buildDeleteFetch<any>(`/exam/${exam.id}/model/${visualModel.id}/point/${selectedPoint.id}`);
        deletePoint().then((r: any) => {
            removePoint(selectedPoint.uuid,true);
            setUpdating(false);
            setSelectedPoint(null);
        });
    }, [exam, visualModel, selectedPoint]);

    const changeCategory = useCallback((e) => {
        //console.log("changeCategory",selectedPoint);
        if(selectedPoint==null) return;
        selectedPoint.category=e.target.value;
    },[selectedPoint]);

    const changeNote = useCallback((e) => {
        //console.log("changeNote",selectedPoint);
        if(selectedPoint==null) return;
        selectedPoint.note=e.target.value;
    },[selectedPoint]);

    const deleteMedia = useCallback((m: Media) => {
        setDeleting(true)
        const [deleteFetch] = buildDeleteFetch(`/media/${m.id}`);
        deleteFetch().then((success: boolean) => {
            setDeleting(false)
            if(success){
                setImages(images.filter((i: Media)=> i.id !== m.id));
                toast.success("Immagine eliminata");
            } else {
                toast.error("Errore nell'eliminare l'immagine");
            }
        })
    }, []);

    const showUpload = useCallback(() => {
        setPreparingUpload(true);
    }, []);

    const hideUpload = useCallback(() => {
        setPreparingUpload(false);
    }, []);

    const onFileSelected=useCallback((e) => {
        setFileToUpload(e.target.files[0]);
    },[]);

    const doUpload = useCallback(() => {
        if(visualModel==null) return;
        if(selectedPoint==null) return;

        setUploading(true);
        const formData = new FormData();
        formData.append('image', fileToUpload);
        const [createImage] = buildPostFetch<Media>(
          `/exam/${exam.id}/model/${visualModel.id}/point/${selectedPoint.id}/media`,
          formData,
          {
            headers: {
              'content-type': 'multipart/form-data',
            },
          }
        );
        createImage().then((result: Media|null) => {
            if(result==null) {
                setUploading(false);
                toast.error("Errore nell'operazione");
            } else {      
                let newImages=images.concat(result);
                console.log("newImages",newImages);
                setImages(newImages);
                setUploading(false);
                setPreparingUpload(false);
                toast.success("Immagine aggiunta");
            }
        }).catch(() => {
            toast.error("Errore nell'operazione");
        });
    },[fileToUpload,images]);

    if (error) {
        return (
          <Alert variant="danger">
            <p>Errore nell'inizializzare il sistema, contatta il supporto</p>
          </Alert>
        );
    }

    //console.log("refresh",images,selectedPoint);

    return (
        <div>
            { loading && (
                <Loading show={true} message="Inizializzazione modello in corso" />
            )}

            { uploading && (
                <Loading show={true} message="Salvataggio immagine in corso" />
            )}

            { deleting && (
                <Loading show={true} message="Cancellazione immagine in corso" />
            )}

            {!loading &&
                <Alert variant="info">
                    <p>
                        Usare il doppio click per <b>selezionare o aggiungere un punto</b>.
                        Tenere premuto il pulsante e muovere il mouse per <b>ruotare</b> il modello.<br/>
                        Usare la rotella del mouse per <b>variare lo zoom</b>.
                        Per <b>aggiungere un'immagine</b> selezionare un punto esistente o crearne uno nuovo.
                    </p>
                </Alert>
            }
            <div style={{display:'flex'}}>
                <div style={{width:'400px'}}>
                    <canvas ref={canvas} onDoubleClick={bodyClicked}></canvas>
                </div>
                { updating &&
                    <div className="center-ui point-list-popup" style={{flex:'1'}}>
                        <Loading show={true} message="Aggiornamento dati" />
                    </div>
                }
                { !loading && !updating &&  (
                    <div className="center-ui point-list-popup" style={{flex:'1'}}>
                        { !selectedPoint &&
                            <div>
                                <div style={{fontWeight:'bold'}}>
                                    Elenco punti
                                </div>
                                <hr/>
                                { points.map(p => (
                                    <button
                                            key={p.id}
                                            className="btn btn-info"
                                            style={{ display: 'block', width: '90%', marginBottom: '6px' }}
                                            onClick={() => pointClickedOnList(p)}>
                                        <div style={{ fontWeight: 'bold' }}>{p.category}</div>
                                        <div>{p.note}</div>
                                    </button>
                                ))}
                            </div>
                        }
                        { selectedPoint &&
                            <div>
                                <div style={{fontWeight:'bold'}}>
                                    <Form>
                                        <Button style={{marginBottom:'6px'}} onClick={doUnselectPoint}>
                                            <MdChevronLeft/> Torna ad elenco
                                        </Button>
                                        <Form.Control as="input" type="text" defaultValue={selectedPoint.category} onChange={changeCategory}/>
                                        <Form.Control rows={3} as="textarea" type="text" defaultValue={selectedPoint.note} onChange={changeNote} />                                        
                                        <Button style={{marginTop:'6px'}} onClick={doSavePoint}>
                                            <MdSave/> Salva modifiche
                                        </Button>
                                        { selectedPoint.id!=0 &&                                       
                                            <Button variant='info' style={{marginTop:'6px',marginLeft:'6px'}} onClick={showUpload}>
                                                <FaUpload/> Carica immagine
                                            </Button>
                                        }
                                        { selectedPoint.id!=0 &&
                                            <Button variant='danger' style={{marginTop:'6px',float:'right'}} onClick={doRemovePoint}>
                                                <FaTrash/> Elimina
                                            </Button>
                                        }
                                    </Form>
                                </div>  
                                { preparingUpload &&                        
                                    <div className="alert alert-info" style={{marginTop:'1rem'}}>
                                        <div className="close-x" onClick={hideUpload}>&times;</div>
                                        <div style={{fontWeight:600,textDecoration:'underline'}}>Selezione immagine</div>
                                        <form method="post" action="#" id="#">
                                            <div className="form-group files">
                                                <label className="btn btn-info custom-file-upload">
                                                    <input type="file" name="file" className="form-control" onChange={onFileSelected}/>
                                                    <FaFile /> Seleziona immagine
                                                </label>&nbsp;
                                                <button type="button" className="btn btn-success" disabled={!fileToUpload} onClick={doUpload}>
                                                    <FaFileUpload /> Conferma 
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                }        
                                <hr/>   
                                <div style={{display:'flex',marginLeft:'10px',height:'400px',overflowY:'auto'}}>                   
                                    { images.filter(m => m.visual_model_point && m.visual_model_point.id==selectedPoint.id).map(m => (
                                        <div style={{width:'280px'}} key={m.id}>
                                            <ImageCard showImage={true} media={m} notified={false} onDelete={() => {deleteMedia(m)}} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                )}                
            </div>
        </div>
    )
    
}

export {BodyCollector};