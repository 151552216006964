
import React, { FC, useCallback } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { validationSchema } from '../../utils/validation';
import { Patient } from '../../types/patient';
import { buildPostFetch, buildGetFetch } from '../../services/base';
import { toast } from 'react-toastify';
import { DatePickerField } from '../common/date_picker';
import { GenderInput } from '../common/genderInput';
import { MdSave } from 'react-icons/md';
import { LoadingButton } from '../common/loadingbutton';
import { EMOJI } from '../../utils/emoji';
import { Auth } from '../../services/auth';

type NewPatientProps = {
    onCreate: (patient: Patient) => void;
}

const schema: yup.ObjectSchema = validationSchema({
    name: yup.string().required(),
    surname: yup.string().required(),
    date_of_birth: yup.date().required(),
    taxcode: yup.string().required().length(16).test(
        'is-unique-cf',
        'Codice fiscale già in uso o errato',
        async function (value: any) {
            if(!value || value === ''){
                return false;
            }
            const [fetch] = buildGetFetch<boolean>('/patient/validate', {queryParams: {
                taxcode: value
            }})
            return !await fetch();
        }
    ),
    genre: yup.string(),
    address: yup.object().required().shape({
        address_line1: yup.string().required(),
        address_line2: yup.string(),
        zipcode: yup.number().required(),
        cell1: yup.number(),
        cell2: yup.number(),
        city: yup.string().required(),
        country: yup.string().required(),
        email: yup.string().email().test(
            'is-unique-email',
            'Email invalida o già in uso',
            async function (value: any) {
                if(!value || value === ''){
                    return false;
                }
                const [fetch] = buildGetFetch<boolean>('/patient/validate', {queryParams: {
                    email: value
                }})
                return !await fetch();
            }
        )
    })
});

/**
 * Validation is performed only on submit so we dont waste api calls
 * @param param0
 */
const NewPatient: FC<NewPatientProps> = ({onCreate}) => {
    const submit = useCallback((values, helpers: FormikHelpers<any>) => {
        let birth_date = values.date_of_birth;
        let utc = new Date(
            birth_date.getTime() - birth_date.getTimezoneOffset() * 60000
        );
        values.date_of_birth = utc;
        const [create] = buildPostFetch<{patient: Patient}>("/patient", values, {toastError: true, toastMessage: `${EMOJI.SAD} Errore nell'inserimento del paziente, contatta il supporto`});
        create().then((p) => {
            if(p !== null){
                toast.success("Paziente creato");
                onCreate(p.patient);
                helpers.resetForm();
            }
            helpers.setSubmitting(false);
        }).catch((e) => {
            helpers.setSubmitting(false);
        })
    }, [onCreate]);

    return (<div>
        <Formik validateOnBlur={true} validateOnChange={false} validationSchema={schema} onSubmit={submit} initialValues={{
            name: '',
            surname: '',
            taxcode: '',
            date_of_birth: '',
            genre: 'M',
            address: {
                address_line1: "",
                address_line2: "",
                zipcode: "",
                cell1: "",
                cell2: "",
                city: "",
                country: "",
                province: "",
                email: ""
            }
        }}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, isValidating }) => (
                <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                    <Form.Group controlId="sexField">
                        <Form.Label>Sesso</Form.Label>
                        <br/>
                        <GenderInput name="genre"/>
                    </Form.Group>
                    <Form.Row>
                        <Form.Group as={Col} controlId="nameField">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control autoComplete="none" type="text" name="name" value={values.name}
                                onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={touched.name && !!errors.name} />
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="surnameField">
                            <Form.Label>Cognome</Form.Label>
                            <Form.Control autoComplete="none" type="text" name="surname" value={values.surname}
                                onChange={handleChange} isValid={touched.surname && !errors.surname} isInvalid={touched.surname && !!errors.surname} />
                            <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="taxcodeField">
                            <Form.Label>Codice fiscale</Form.Label>
                            <Form.Control autoComplete="none" type="text" name="taxcode" value={values.taxcode}
                                onChange={handleChange} isValid={touched.taxcode && !errors.taxcode} isInvalid={touched.taxcode && !!errors.taxcode} />
                            <Form.Control.Feedback type="invalid">{errors.taxcode}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="birthField">
                            <Form.Label>Data di nascita</Form.Label>
                            <br/>
                            <DatePickerField maxDate={new Date()} name="date_of_birth" placeholder="DD/MM/YYYY"/>
                            <Form.Control.Feedback type="invalid">{errors.date_of_birth}</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <div className="d-flex mb-4">
                        <fieldset>
                            <legend>Indirizzo</legend>
                                <Form.Group controlId="address1">
                                    <Form.Label>Indirizzo 1</Form.Label>
                                    <Form.Control autoComplete="none" name="address.address_line1" value={values.address.address_line1}
                                        onChange={handleChange} isValid={touched.address?.address_line1 && !errors.address?.address_line1} isInvalid={touched.address?.address_line1 && !!errors.address?.address_line1}/>
                                        <Form.Control.Feedback type="invalid">{errors.address?.address_line1}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="address2">
                                    <Form.Label>Indirizzo 2</Form.Label>
                                    <Form.Control autoComplete="none" name="address.address_line2" value={values.address.address_line2}
                                        onChange={handleChange} />
                                </Form.Group>
                                <Form.Group controlId="city">
                                    <Form.Label>Città</Form.Label>
                                    <Form.Control autoComplete="none" name="address.city" value={values.address.city}
                                        onChange={handleChange} isValid={touched.address?.city && !errors.address?.city} isInvalid={touched.address?.city && !!errors.address?.city}/>
                                        <Form.Control.Feedback type="invalid">{errors.address?.city}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group  controlId="zipcode">
                                    <Form.Label>Cap</Form.Label>
                                    <Form.Control autoComplete="none" name="address.zipcode" value={values.address.zipcode}
                                        onChange={handleChange} isValid={touched.address?.zipcode && !errors.address?.zipcode} isInvalid={touched.address?.zipcode && !!errors.address?.zipcode}/>
                                    <Form.Control.Feedback type="invalid">{errors.address?.zipcode}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group  controlId="state">
                                    <Form.Label>Stato</Form.Label>
                                    <Form.Control autoComplete="none" name="address.country" value={values.address.country}
                                        onChange={handleChange} isValid={touched.address?.country && !errors.address?.country} isInvalid={touched.address?.country && !!errors.address?.country}/>
                                    <Form.Control.Feedback type="invalid">{errors.address?.country}</Form.Control.Feedback>
                                </Form.Group>
                        </fieldset>
                        <fieldset>
                            <legend>Contatti</legend>
                                <Form.Group  controlId="emailField">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control autoComplete="none" type="email" name="address.email" value={values.address.email}
                                    onChange={handleChange} isValid={touched.address?.email && !errors.address?.email} isInvalid={touched.address?.email && !!errors.address?.email}/>
                                    <Form.Control.Feedback type="invalid">{errors.address?.email}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group  controlId="cell1">
                                    <Form.Label>Telefono fisso</Form.Label>
                                    <Form.Control autoComplete="none" name="address.cell1" value={values.address.cell1}
                                        onChange={handleChange} />
                                </Form.Group>
                                <Form.Group controlId="cell2">
                                    <Form.Label>Cellulare</Form.Label>
                                    <Form.Control autoComplete="none" name="address.cell2" value={values.address.cell2}
                                        onChange={handleChange} />
                                </Form.Group>
                        </fieldset>
                    </div>
                    <LoadingButton disabled={isSubmitting || isValidating} loading={isSubmitting || isValidating} type="submit" size="lg" block variant="success"><MdSave size={"1.5rem"}/> Aggiungi paziente</LoadingButton>
                </Form>
            )}
        </Formik>
    </div>)
}

export { NewPatient };