
import React, {FC, Fragment, useEffect, useState} from "react";
import { Alert, Badge, Col, Row } from "react-bootstrap";
import { Auth } from "../../services/auth";
import { buildGetFetch } from "../../services/base";
import { PaymentService } from "../../types/paymentservice";
import { formatDT } from "../../utils/dateutils";
import { Loading } from "../common/loading";

const Payments: FC<{}> = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<{
      payments: Array<PaymentService> | null
    }>();

    const budget:number=Auth.availableBudget();
    const paymentService:PaymentService=Auth.paymentService();

    useEffect(() => {
      const [fetchPayments] = buildGetFetch<Array<PaymentService>>("/user/payments", {});
      Promise.all([fetchPayments()]).then(([result]) => {
          setData({
            payments: result
          });
          setLoading(false);
      })
    }, []);

    if(loading){
      return <Loading show={true} message="Caricamento servizi..."/>
    }
    if(data){
        return (
          <Fragment>
              <div>
                { paymentService &&
                  <Fragment>
                    <h4>Servizio di pagamento attivo: <Badge variant="dark">{paymentService.payment.name}</Badge></h4>
                    <p>Data attivazione: {formatDT(paymentService.start_ts)} </p>
                  </Fragment>
                }
                
                { !paymentService &&
                  <Row>
                      <Col md={4} sm={6} xs={12}>
                        <div className="alert alert-light text-center p-2 shadow-sm">
                            <label>Credito residuo</label><br/>
                            <h3><Badge variant="secondary">{Auth.availableBudget()}</Badge></h3>
                        </div>
                      </Col>
                      <Col md={4} sm={6} xs={12}>
                        <div className="alert alert-light text-center p-2 shadow-sm">
                            <label>Credito utilizzato</label><br/>
                            <h3><Badge variant="warning">{Auth.user()?.used_budget}</Badge></h3>
                        </div>
                      </Col>
                      <Col md={4} sm={6} xs={12}>
                        <div className="alert alert-light text-center p-2 shadow-sm">
                            <label>Totale pagamenti</label><br/>
                            <h3><Badge variant="primary">{Auth.user()?.total_pay}</Badge></h3>
                        </div>
                      </Col>
                    </Row>
                  }
              </div>

            {data.payments && data.payments.length > 0 && 
            <div className="mb-4 mt-4">
              <h4>Storico servizi</h4>
              {data.payments.map((i: PaymentService) => {
                  return <div key={i.id}>
                    <PaymentRow payment={i}/>
                  </div>
              })}
            </div>}
          </Fragment>
        )
    }
    return (<div>
    </div>)
}

type PaymentRowProps = {
  payment: PaymentService;
}

const PaymentRow: FC<PaymentRowProps> = ({payment}) => {
  return (
      <Alert variant="light" className="shadow-sm">
          <div className="justify-content-between">
            <h5>{payment.payment.name}</h5>
            <div>
              Attivato il <b>{formatDT(payment.start_ts)}</b><br/>
              {payment.end_ts && <Fragment>Terminato il <b>{formatDT(payment.end_ts)}</b></Fragment>}
            </div>
          </div>
      </Alert>)
}

export {Payments};