
import React, {FC} from 'react';
import { Alert } from 'react-bootstrap';

export const Nomatch: FC<{}> = () => {
    return (
        <div>
            <Alert variant="dark">
                <Alert.Heading><span role="img" aria-label="lost">😵</span> Ti sei perso?</Alert.Heading>
                <p>
                    La pagina che stai cercando non è stata trovata
                </p>
                <hr/>
                <p>
                    Torna alla home
                </p>
            </Alert>
        </div>
    )
};