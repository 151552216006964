
import React, { useContext, FC, Fragment } from "react";
import { ExamContext } from "./examcontext";

export const ExamActionMenu: FC<any> = (props) => {
    const { isClosed } = useContext(ExamContext);
    if(isClosed){
        return (<Fragment/>)
    }
    return (
        <div className="action-menu">
            {props.children}
        </div>
    )
}