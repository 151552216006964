
import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import clsx from 'clsx';
import { parseJSON } from "date-fns";


/**
 * Datepicker-Formik
 * @param props 
 */
export const DatePickerField = (props: any) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);  
  return (
    <DatePicker dropdownMode="select" showMonthDropdown showYearDropdown dateFormat="dd/MM/yyyy" placeholderText={props.placeholder} yearDropdownItemNumber={5} 
        className={clsx('form-control', meta.touched && meta.error && 'is-invalid', meta.touched && !meta.error && 'is-valid')}
        locale="it" {...props} selected={(field.value && parseJSON(field.value)) || null}     
        onChange={val => {
          setFieldValue(field.name, val);
        }}
    />
  );
};