
import styled from 'styled-components';

const OverflowWrapper = styled.div`
    @media (min-width: 768px) {
        overflow: hidden;
        display: block!important;
        height: 100%!important;
    }
`;

export {OverflowWrapper};