
import './exam_report.scss';
import React, { FC, Fragment, useContext, useCallback, useEffect, useState } from "react";
import { ExamContext } from "./examcontext";
import { Button, Alert, Form, ButtonGroup, Row, Col, Card } from "react-bootstrap";
import {  MdSave } from "react-icons/md";
import { AiOutlineFilePdf } from "react-icons/ai";
import { FaDownload, FaNotesMedical } from "react-icons/fa";
import { Formik, FormikHelpers } from "formik";
import { buildGetFetch, buildPostFetch, API_ROUTE, BASE, buildPutFetch } from "../../services/base";
import { motion } from "framer-motion";
import { StickyBar } from "../common/stickybar";
import { Report } from "../../types/report";
import { toast } from 'react-toastify';
import { Panel } from '../common/panel';
import { formatDT } from '../../utils/dateutils';
import { ExamActionMenu } from './examactiomenu';
import { EXAM_PERMISSION_ENUM } from '../../types/enums';
import { Loading } from '../common/loading';
import { ExamInvitations } from './examinvitations';
import { Auth } from '../../services/auth';


const ExamReport: FC = () => {
    const { exam, isClosed, hasPermission } = useContext(ExamContext);
    const [report, setReport] = useState<Report>();
    const [loading, setLoading] = useState<boolean>(true);
    const [create, setCreate] = useState<boolean>(false);

    const isBaseDoctor:boolean = Auth.isBaseDoctor();

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch(`/user/exam/${exam.id}/report`, { silent:true }, setReport, setLoading);
        fetch();
        return () => {
            cancel();
        };
    }, [exam]);

    const onSubmit = useCallback((values: Partial<Report>, helpers: FormikHelpers<Partial<Report>>) => {
        if(report) {
            const [fetch] = buildPutFetch<Report>(`/user/exam/${exam.id}/report`, values, {});
            fetch().then((data) => {
                if(data){
                    toast.success("Parere medico aggiornato con successo");
                    setReport(data);
                    setCreate(false);
                }
            });
        } else {
            const [fetch] = buildPostFetch<Report>(`/user/exam/${exam.id}/report`, values, {});
            fetch().then((data) => {
                if(data){
                    toast.success("Parere medico creato con successo");
                    setReport(data);
                    setCreate(false);
                }
            });
        }
    }, [report,exam]);

    const validate = useCallback((values: Partial<Report>) => {
      const errors: any = {};
      if(!values.report || values.report === ""){
          errors.report = "Compila il parere medico";
      }
      return errors;
    }, []);
    
    if(loading){
        return (<Loading show={true} message="Caricamento informazioni..."/>)
    }

    if (create && !isClosed) {
        return (
            <motion.div className="paper" initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }} transition={{ delay: 0.2, duration: 0.4 }}>
                <Formik onSubmit={onSubmit} initialValues={{ 
                        report: report?report.report:"", 
                        recommendation: report?report.recommendation:"", 
                        reminder: report?report.reminder:"" }} validate={validate}>
                    {({ handleChange, handleBlur, values, errors, dirty, touched, handleSubmit, resetForm }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                            <Form.Group>
                                <Form.Label>Parere medico</Form.Label>
                                <Form.Control as="textarea" rows={10} onChange={handleChange} onBlur={handleBlur} value={values.report} name="report" isValid={touched.report && !errors.report} isInvalid={!!errors.report}/>
                                <Form.Control.Feedback type="invalid">
                                    {errors.report}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Raccomandazioni</Form.Label>
                                <Form.Control rows={5} as="textarea" onChange={handleChange} onBlur={handleBlur} value={values.recommendation} name="recommendation" />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Annotazioni</Form.Label>
                                <Form.Control rows={5} as="textarea" onChange={handleChange} onBlur={handleBlur} value={values.reminder} name="reminder" />
                            </Form.Group>
                            <StickyBar top={49}>
                                <Alert variant="warning">
                                    <div className="text-center">
                                        { !report &&
                                            <p>Stesura nuovo parere medico</p>
                                        }
                                        { report &&
                                            <p>Modifica parere medico</p>
                                        }
                                        <ButtonGroup>
                                            <Button type="button" onClick={() => {
                                                if(dirty){
                                                    resetForm();
                                                } 
                                                setCreate(false);
                                            }} variant="warning"><MdSave /> Annulla</Button>
                                            { !report &&
                                                <Button disabled={!dirty} variant="success" type="submit"><MdSave/> Salva parere medico</Button>
                                            }
                                            { report &&
                                                <Button disabled={!dirty} variant="success" type="submit"><MdSave/> Aggiorna parere medico</Button>
                                            }
                                        </ButtonGroup>
                                    </div>
                                </Alert>
                            </StickyBar>
                        </Form>
                    )}
                </Formik>
            </motion.div>
        )
    }

    const isAdmin:boolean = Auth.isAdmin();
    const user=Auth.user()?.id;
    const isInvited=(!isAdmin && exam?.owner_id !== user);
    const colSizeReport=isInvited?12:6;
    const colSizeInvitation=isInvited?0:6;

    return (<Fragment>
        <Row>
            <Col lg={colSizeReport}>
                <Card >
                    <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                Parere medico
                            </div>
                            { !isBaseDoctor &&
                                <Fragment>
                                    { !report &&
                                        <Button disabled={isClosed || !hasPermission(EXAM_PERMISSION_ENUM.REPORT_WRITE)} variant="success" onClick={() => { setCreate(true) }}><FaNotesMedical size={"1.5rem"} /> Crea nuovo parere medico</Button>
                                    }
                                    { report &&
                                        <Button disabled={isClosed || !hasPermission(EXAM_PERMISSION_ENUM.REPORT_WRITE)} variant="warning" onClick={() => { setCreate(true) }}><FaNotesMedical size={"1.5rem"} /> Modifica parere medico</Button>
                                    }
                                </Fragment>
                            }
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="mt-4">
                            { !report &&
                                <Alert variant="info">
                                    Parere medico non ancora creato
                                </Alert>
                            }
                            { report &&
                                <div>
                                    <ExamActionMenu>
                                        <a className="btn btn-info" target="_blank" href={`${BASE}${API_ROUTE}/user/exam/${exam.id}/report/pdf`}>
                                            <AiOutlineFilePdf size={"1.5rem"}/> Anteprima
                                        </a>
                                        <a className="btn btn-info" download={`parere_${exam.id}.pdf`} href={`${BASE}${API_ROUTE}/user/exam/${exam.id}/report/download`}>
                                            <FaDownload size={"1.5rem"}/> Scarica
                                        </a>
                                    </ExamActionMenu>
                                    <div className="paper">
                                        <p>
                                            <label>Data parere:&nbsp;&nbsp;</label>{formatDT(report.release_ts)}
                                        </p>
                                        <p>
                                            <label>Dottore refertante:&nbsp;&nbsp;</label>{report.owner.name} {report.owner.surname}
                                        </p>
                                        <Panel defaultState="open" header="Parere medico">
                                            <div>
                                                {report.report}
                                            </div>
                                        </Panel>
                                        <Panel defaultState="closed" header="Raccomandazioni">
                                            <div>
                                                {!report.recommendation || report.recommendation === "" ? <div>
                                                    <Alert variant="info">
                                                        Nessuna raccomandazione specificata
                                                    </Alert>
                                                </div> : report.recommendation}
                                            </div>
                                            </Panel>
                                        <Panel defaultState="closed" header="Annotazioni">
                                            <div>       
                                                {!report.reminder || report.reminder === "" ? <div>
                                                    <Alert variant="info">
                                                        Nessuna annotazione specificato
                                                    </Alert>
                                                </div> : report.reminder}
                                            </div>
                                        </Panel>
                                    </div>
                                </div>
                            }
                        </div>
                    </Card.Body>
                </Card>

            </Col>
            {!isInvited &&
                <Col lg={colSizeInvitation}>
                    <ExamInvitations />
                </Col>
            }
        </Row>
    </Fragment>)
}

export { ExamReport };