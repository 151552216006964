
import styled from "styled-components";

interface StickyBarProps {
    readonly top?: number;
  };

const StickyBar = styled.div<StickyBarProps>`
    background-color: white;
    position: sticky;
    top: ${props => props.top || 50}px;
    z-index: 100;
`;

export {StickyBar}
