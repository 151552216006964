
import React, { FC, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { ExamCard } from "./examcard";

const ExamHome: FC = () => {

    return (<Fragment>
        
        <Row>
            <Col lg={12}>
                <ExamCard />
            </Col>
        </Row>
    </Fragment>)
}


export { ExamHome };