
import React, { FC } from "react";
import { Navbar } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { Auth } from "../services/auth";

export const MainNav: FC<{}> = () => {
  let userCss="user";
  if(Auth.isAdmin()) {
    userCss="user admin";
  }
  return (
    <Navbar bg="dark" variant="dark" className="navbar" expand="lg">
      <Navbar.Brand href="/app">
        DermaCo 
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">    
        <Navbar.Text>
          <span className={userCss}>
            <FaUserCircle size={'1rem'}/> {Auth.user()?.name + " " + Auth.user()?.surname}
          </span>
        </Navbar.Text>
      </Navbar.Collapse>
  </Navbar>
  )
}