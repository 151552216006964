export let RecentExams: Array<any> = [];

export const addRecentExam = (record: any) => {
    let alreadyIndex=RecentExams.findIndex(r => { return r.exam.id==record.exam.id; });
    if(alreadyIndex>=0) {
        RecentExams.splice(alreadyIndex, 1)
    }
    if(RecentExams.length>=5) {
        RecentExams=RecentExams.slice(0,4);
    }
    RecentExams.unshift(record);
    console.log("recent exams",RecentExams)
}

export const clearRecentExams = () => {
    RecentExams=[];
}