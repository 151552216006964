
import React, {FC, useState, useEffect, useCallback, useMemo} from "react";
import { Loading } from "../common/loading";
import {  buildGetFetch } from "../../services/base";
import { Alert, Badge, Button, ButtonGroup, Card } from "react-bootstrap";
import { EMOJI } from "../../utils/emoji";
import { Reminder } from "../../types/reminder";
import { Id } from "../common/id";
import { formatDT, isPastDT } from "../../utils/dateutils";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { Table } from '../common/table';

type RemindersProps = {};

const Reminders: FC<RemindersProps> = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [reminders, setReminders] = useState<Array<Reminder>>([]);
    const history = useHistory();

    useEffect(() => {
        const [fetch] = buildGetFetch<Array<Reminder>>('/user/reminders', {}, setReminders, setLoading, setError);
        fetch();
    }, []);

    const goToExam = useCallback((props: any) => {
        history.push(`/app/exams/${props.row.original.source_exam_id}`);
    }, [history]);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Data RIchiamo',
                Cell: (props:any) =>  
                    <span className={clsx(isPastDT(props.row.original.due_ts) && "error")}>{formatDT(props.row.original.due_ts)}</span>
            },{
                Header: 'Paziente',
                accessor: (r: any)=>{return r.exam.patient.surname + " " + r.exam.patient.name}
            },{
                Header: 'Visita',
                accessor: (r: any)=>{return r.exam.title + " " + r.exam.title},
                Cell: (props:any) => 
                    <div>
                        <Id id={props.row.original.source_exam_id}/> {props.value}
                    </div>
            },{
                Header: 'Data Visita',
                accessor: (r: any)=>{return formatDT(r.exam.start_ts)}
            },{
                Header: 'Periodicità',
                Cell: (props:any) => 
                    <div>
                        <Badge variant="info">{props.row.original.exam.reminder}</Badge> mes{props.row.original.exam.reminder === 1 ? 'e' : 'i'}
                    </div>
            },{
                Header: '',
                id: 'actions',     
                Cell: (props: any) => 
                    <div className="text-center">
                        <ButtonGroup aria-label="Azioni visita">
                            <Button title="Accedi alla visita" size="sm" variant="dark" onClick={() => {goToExam(props);}}><FaEye size={'1.5em'}/></Button>
                        </ButtonGroup>
                    </div>
            }
        ]
    }, [goToExam]);

    if(error){
        return (<Alert variant="danger">
            <Alert.Heading>
                {EMOJI.SAD} Attenzione
            </Alert.Heading>
            <hr/>
            <p>
                Impossibile ottenere la lista dei reminders, contatta il supporto
            </p>
        </Alert>)
    }
    if(loading){
        return <Loading show={true} message="Caricamento richiami..."/>
    }
    return (
        <div>
            <h4>Richiami</h4>
            <Alert variant="info">
                <p>
                    Qui vengono mostrate le visite che prevedono un richiamo in scadenza. <br/>
                    <b>Non vengono visualizzati i richiami scaduti da più di un mese o che scadranno tra più di un mese</b>
                </p>
            </Alert>
            <Card border="info" className="mb-4">
                <Card.Header>
                    <div className="d-flex justify-content-between align-items-center">
                        Richiami
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="pt-4">
                        <Table data={reminders} columns={columns} />        
                    </div>
                </Card.Body>
            </Card>
            
            { /* reminders.map((r: Reminder) => {
                return (<ReminderRow key={`reminder_${r.id}`} reminder={r}/>)
            }) */ }
        </div>
    )
}

type ReminderRowProps = {
    reminder: Reminder;
}

export {Reminders}