
import './exam.scss';
import React, { FC, useCallback, useState, useEffect, useMemo, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Exam, ExamPermission, ExamData } from '../../types/exam';
import { Loading } from '../common/loading';
import { Alert, Button, Badge, Tabs, Tab, Modal, ButtonGroup } from 'react-bootstrap';
import { FcLeft } from 'react-icons/fc';
import { ExamContext, Options } from './examcontext';
import { Auth } from '../../services/auth';
import { ExamHome } from './examhome';
import { ExamMedicalData } from './exammedicaldata';
import { fakeId } from '../../utils/faker';
import { ExamReport } from './examreport';
import { ExamDocument } from './examdocument';
import { EXAM_PERMISSION_ENUM, EXAM_STATE } from '../../types/enums';
import { buildGetFetch } from '../../services/base';
import { PatientData } from '../../types/patient';
import { cloneDeep, set } from 'lodash';
import { formatDT, formatTS } from "../../utils/dateutils";
import { ExamPrivacy } from './examprivacy';
import { ExamRecall } from './examrecall';
import { ExamExecution } from './examexecution';
import { ExamClose } from './examclose';
import { ExamReopen } from './examreopen';
import { PatientHome } from './patienthome';
import { Chat } from './chat';
import { ExamInvitations } from './examinvitations';
import { addRecentExam, RecentExams } from '../../services/recents';
import { MdHistory } from 'react-icons/md';
import { Table } from '../common/table';
import { Id } from '../common/id';
import { StateBadge } from '../common/examstatebadge';
import { FaEye } from 'react-icons/fa';

interface ExamDetailProps {
}

const ExamDetail: FC<ExamDetailProps> = () => {

    const [user] = useState(Auth.user()?.id);
    /**
     * First time loading  any other update will now show the loading
     */
    const [firstLoading, setFirstLoading] = useState<boolean>(true);
    /**
     * an update is occurring on main data
     */
    const [updating, setUpdating] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    
    /**
     * Context state data
     */
    const [tabKey, setTabKey] = useState('home');
    const [exam, setExam] = useState<Exam>();
    const [patientData, setPatientData] = useState<PatientData>();
    const [reportOwner, setReportOwner] = useState<any>();
    const [showRecents, setShowRecents] = useState<boolean>(false);

    let { examId } = useParams<{examId: string}>();
    
    let history = useHistory();

    const handleStateChange=()=>{        
        loadExam();
        setTabKey("home");
    }

    const loadExam=useCallback(() => {
        const [fetch, cancel] = buildGetFetch<ExamData>(`/user/exam/${examId}`, {});
        fetch().then((data)=> {
            if(!data){
                setError(true);
                setExam(undefined);
                setPatientData(undefined);
                setReportOwner(undefined);
            } else {
                addRecentExam(data);
                setPatientData(data.patientData);
                setExam(data.exam);
                setReportOwner(data.reportOwner);
            }
            if(firstLoading){
                setFirstLoading(false);

                setTimeout(() => {
                    if(window.location.hash=="#messages") {
                        let el=document.getElementById('tabs-tab-chat');
                        if(el) el.click();
                    }
                },100);

            } else {
                setUpdating(false);
            }
        });
        return () => cancel();
    },[examId,firstLoading]);

    useEffect(() => {
        if(!firstLoading){
            setUpdating(true);
        }
        return loadExam();        
    }, [examId,firstLoading,loadExam]);


    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const doShowRecents = useCallback(() => {
        setShowRecents(true);
    }, [setShowRecents]);

    const doHideRecents = useCallback(() => {
        setShowRecents(false);
    }, [setShowRecents]);

    const isAdmin:boolean = Auth.isAdmin();
    const isSpecialist:boolean = Auth.isSpecialist();
    const isBaseDoctor:boolean = Auth.isBaseDoctor();
    const isTemporary:boolean = Auth.isTemporary();
    const isClosed:boolean = (exam && exam.state===75)?true:false; // chiusa
    const userInSession:any=Auth.user();

    const isReportOwner = () => {
        if(!reportOwner) return false;
        if(reportOwner.id===user) return true;
        else return false;
    }

    const hasPermission = (permission: EXAM_PERMISSION_ENUM) => {
        if(!exam) return false;
        if(isAdmin) return true;
        if(exam.owner_id===user) return true;
        if(!exam.exam_permissions) return false;
        let idx=exam.exam_permissions.findIndex((ep) => ep.user_id===user && ep.permission === permission);
        if(idx>= 0) {
            return true;
        } else {
            return false;
        }
    }

    const isInvited=(!isAdmin && exam?.owner_id !== user);

    let invited=null;
    if(exam && exam.confirmed_invitations && exam.confirmed_invitations[0] && exam.confirmed_invitations[0].user)
        invited=exam.confirmed_invitations[0].user;
    let invitedName=null;
    if(invited) {
        if(invited.name || invited.surname) invitedName=invited.name + " " + invited.surname;
        else invitedName="[" + invited.email + "]";
    }

    const goToExam = useCallback((d: any) => {
        history.push(`/app/exams/${d.exam.id}`);
        setShowRecents(false);
    }, [history,setShowRecents]);

    const columns = useMemo(() => {
        let cols= [
            {
                Header: 'Id',
                accessor: (d: any) => <Id id={d.exam.id}/>
            },{
                Header: 'Stato',
                accessor: (d: any) => <StateBadge state={d.exam.state}/>
            },{
                Header: 'Paziente',
                accessor: (d: any)=>{return d.patientData.patient.name + " " + d.patientData.patient.surname}
            },{
                Header: 'Visita',                
                accessor: (d: any)=> {
                    let invited=null;
                    if(d.exam.confirmed_invitations && d.exam.confirmed_invitations[0] && d.exam.confirmed_invitations[0].user)
                        invited=d.exam.confirmed_invitations[0].user;
                    let invitedName=null;
                    if(invited) {
                        if(invited.name || invited.surname) invitedName=invited.name + " " + invited.surname;
                        else invitedName="[" + invited.email + "]";
                    }
                    return (                    
                        <div>
                            {d.exam.title} &nbsp;
                            { d.exam.owner.id!==userInSession.id && 
                                <span className="badge badge-warning" style={{fontWeight:"normal",fontSize:"80%"}}>
                                    {!isAdmin && <Fragment>Consulenza / parere su invito di </Fragment>} {isAdmin && <Fragment>di </Fragment>} 
                                    {d.exam.owner.surname} {d.exam.owner.name}
                                </span>
                            }
                            { d.exam.owner.id===userInSession.id && invitedName &&
                                <span className="badge badge-info" style={{fontWeight:"normal",fontSize:"80%"}}>
                                    Consulenza / parere richiesti al dott. {invitedName}
                                </span>
                            }
                        </div>
                    );
                }
            },{
                Header: 'Inizio',
                accessor: (d: any)=>{return formatTS(d.exam.start_ts)}
            },{
                Header: 'Fine',
                accessor: (d: any)=>{return formatTS(d.exam.end_ts)}
            },{
                Header: '',
                id: 'actions',     
                accessor: (d: any) => 
                    <div className="text-center">
                        <ButtonGroup aria-label="Azioni visita">
                            <Button title="Accedi alla visita" size="sm" variant="dark" onClick={() => {goToExam(d);}}><FaEye size={'1.5em'}/></Button>
                        </ButtonGroup>
                    </div>
            }
        ];
        if(isTemporary) {
            cols.splice(2,1);
        }
        return cols;
    }, [goToExam,isAdmin]);

    const recents:Array<any>=RecentExams.filter(r => r.exam.id!=examId);

    return (
        <div>
            <Loading show={firstLoading} message="Caricamento visita..." ></Loading>
            <Loading show={updating} backdrop={true}/>

            {error &&
                <Alert variant="danger">
                    <Alert.Heading>Visita non trovata</Alert.Heading>
                    <hr />
                    <p>Torna indietro <br />
                        <Button type="button" variant="light" onClick={goBack}><FcLeft size={'2rem'} /></Button>
                    </p>
                </Alert>}

            {exam && patientData &&
                <div>
                    <ExamContext.Provider value={{ 
                        onDataUpdate: (opts?: Options) => {
                            if(opts){
                                if(opts.examPath){
                                    let newExam;
                                    if(opts.examPath !== "$ALL$"){
                                        newExam = cloneDeep(exam);
                                        set(newExam, opts.examPath, opts.examValue);
                                    } else {
                                        newExam = cloneDeep(opts.examValue);
                                    }
                                    setExam(newExam);
                                } else if (opts.patientDataPath){
                                    let newPatientData;
                                    if(opts.patientDataPath !== "$ALL$"){
                                        newPatientData = cloneDeep(patientData);
                                        set(newPatientData, opts.patientDataPath, opts.patientValue);
                                    } else {
                                        newPatientData = cloneDeep(opts.patientValue);
                                    }
                                    setPatientData(newPatientData);
                                }
                            }
                        },
                        updating,
                        exam, 
                        patientData,
                        changeTab: setTabKey, 
                        permissions: exam.exam_permissions?.map((ep: ExamPermission) => ep.permission) || [], 
                        isOwner: exam.owner_id === user,
                        isClosed: isClosed,                        
                        hasPermission: hasPermission
                        }}>

                        <h3 className="mb-3">
                            <Badge variant="dark">Visita {fakeId(exam.id)}</Badge>&nbsp;
                            {formatDT(exam.start_ts)} {patientData.patient.surname} {patientData.patient.name} 
                            { isClosed && <Badge className="ml-3" variant="warning">Visita chiusa</Badge>}
                            { isInvited && <Badge className="ml-3" variant="warning">Su invito di {exam.owner.surname} {exam.owner.name}</Badge>}
                            { exam.owner_id === user && invitedName &&
                                <Badge className="ml-3" variant="info">
                                    Consulenza / parere medico richiesti al dott. {invitedName}
                                </Badge>
                            }
                            {(isAdmin && exam.owner_id !== user) && <Badge className="ml-3" variant="warning">Creatore: {exam.owner.surname} {exam.owner.name}</Badge>}
                            { !isInvited && recents.length>0 &&
                                <Button variant="warning" className="pull-right" onClick={doShowRecents}>
                                    <MdHistory/> Visite recenti
                                </Button>
                            }
                        </h3>
                        
                        <Tabs activeKey={tabKey} id="tabs" onSelect={(k: string) => {setTabKey(k)}}>
                            <Tab eventKey="home" title="Scheda visita">
                                <div className="tab-contents">
                                    {tabKey === "home" && <ExamHome/>}
                                </div>
                            </Tab>
                            <Tab eventKey="patient" title="Dati paziente">
                                <div className="tab-contents">
                                    {tabKey === "patient" && <PatientHome/>}
                                </div>
                            </Tab>
                            { (exam.owner_id === user || isAdmin) &&
                                <Tab eventKey="privacy" title="Privacy">
                                    <div className="tab-contents">
                                        {tabKey === "privacy" && <ExamPrivacy/>}
                                    </div>
                                </Tab> 
                            }
                            <Tab eventKey="anamnesis" title="Anamnesi">
                                <div className="tab-contents">
                                    {tabKey === "anamnesis" && <ExamMedicalData />}
                                </div>
                            </Tab>                                                       
                            <Tab eventKey="execution" title="Esame">
                                <div className="tab-contents">
                                    {tabKey === "execution" && <ExamExecution />}
                                </div>
                            </Tab>                            
                            { hasPermission(EXAM_PERMISSION_ENUM.REPORT_READ) && (!isSpecialist && !isAdmin) &&
                                <Tab eventKey="invitation" title="Richiedi parere / consulenza">
                                    <div className="tab-contents">
                                        {tabKey === "invitation" && <ExamInvitations/>}
                                    </div>
                                </Tab>
                            }
                            { !isTemporary && hasPermission(EXAM_PERMISSION_ENUM.REPORT_READ) && (isSpecialist || isAdmin || isBaseDoctor) &&
                                <Tab eventKey="report" title="Parere medico">
                                    <div className="tab-contents">
                                        {tabKey === "report" && <ExamReport/>}
                                    </div>
                                </Tab>
                            }
                            { hasPermission(EXAM_PERMISSION_ENUM.CHAT) && 
                                <Tab eventKey="chat" title="Messaggi">
                                    <div className="tab-contents">
                                        {tabKey === "chat" && <Chat/>}
                                    </div>
                                </Tab>                             
                            }
                            { !isTemporary && hasPermission(EXAM_PERMISSION_ENUM.REPORT_WRITE) && (isSpecialist || isAdmin) &&
                                <Tab eventKey="recall" title="Richiamo">
                                    <div className="tab-contents">
                                        {tabKey === "recall" && <ExamRecall />}
                                    </div>
                                </Tab>
                            }
                            { !isTemporary &&
                                <Tab eventKey="docs" title="Documenti">
                                    <div className="tab-contents">
                                        {tabKey === "docs" && <ExamDocument/>}
                                    </div>
                                </Tab>
                            }
                            {   (exam.state!==EXAM_STATE.CLOSED && exam.state!==EXAM_STATE.ARCHIVED) &&
                                !isTemporary && hasPermission(EXAM_PERMISSION_ENUM.REPORT_WRITE) && (isSpecialist || isAdmin) &&
                                <Tab eventKey="close" title="Chiusura">
                                    <div className="tab-contents">
                                        {tabKey === "close" && <ExamClose onStateChange={() => handleStateChange() }/>}
                                    </div>
                                </Tab>                             
                            }
                            { /*hasPermission(EXAM_PERMISSION_ENUM.REPORT_WRITE) && !isBaseDoctor --- modificato per bugid#3730 */ 
                                isReportOwner() && (exam.state===EXAM_STATE.CLOSED || exam.state===EXAM_STATE.ARCHIVED) && 
                                <Tab eventKey="reopen" title="Riapertura">
                                    <div className="tab-contents">
                                        {tabKey === "reopen" && <ExamReopen onStateChange={() => handleStateChange() } />}
                                    </div>
                                </Tab>                             
                            }                            
                        </Tabs>
                    </ExamContext.Provider>

                    { showRecents &&
                        <Modal size="xl" show={true} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <h5>Visite recenti</h5>
                            </Modal.Header>
                            <Modal.Body>
                                {!isTemporary &&
                                    <div className="mt-4 exam-table-container open-exams">
                                        <Table data={recents} columns={columns} />
                                    </div>
                                }
                                {isTemporary &&
                                    <div className="mt-4 exam-table-container temporary-user">
                                        <Table data={recents} columns={columns} />
                                    </div>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => doHideRecents() }>Chiudi</Button>
                            </Modal.Footer>
                        </Modal>
                    }
                </div>
            }
        </div>
    )
};

export { ExamDetail }