

import React, {FC} from 'react';
import { Badge } from 'react-bootstrap';
import { Invitation } from '../../types/invitation';
import { isPastTS } from '../../utils/dateutils';

export const InviteBadge: FC<{invitation: Invitation}> = ({invitation}) => {
    if(invitation.accepted_by_other){
        return <Badge variant="danger">Anullato</Badge>;
    }
    if(invitation.accepted){
        return <Badge variant="success">Accettato</Badge>;
    }
    if(invitation.refused){
        return <Badge variant="danger">Rifiutato</Badge>;
    }
    if(isPastTS(invitation.expiration_ts)){
        return <Badge variant="light">Scaduto</Badge>;
    }
    return <Badge variant="info">In attesa</Badge>;
}