import React, {FC, useState, useMemo, useCallback, Fragment} from 'react';
import { Button, Row, Col, InputGroup, ButtonGroup } from 'react-bootstrap';
import { Exam } from '../../types/exam';
import { buildGetFetch } from '../../services/base';
import { AcPatient } from '../common/ac_patient';
import { Table } from '../common/table';
import { useHistory } from 'react-router-dom';
import { Loading } from '../common/loading';
import { formatTS } from '../../utils/dateutils';
import { Patient } from '../../types/patient';
import { toast } from 'react-toastify';
import { Id } from '../common/id';
import { FaEye, FaSearch } from 'react-icons/fa';
import { StateBadge } from '../common/examstatebadge';
import { Auth } from '../../services/auth';

type ExamSearchProps = {
    filterState?: number;
}

const ExamSearch: FC<ExamSearchProps> = ({filterState}) => {
    const [exams, setExams] = useState<Array<Exam>>([]);
    const [selectedPatient, setSelectedPatient] = useState<Patient|null>(null);
    const [isSearching, setIsSearching] = useState(false);
    const history = useHistory();

    const handleSearchExams = () => {
        if(selectedPatient !== null){
            setIsSearching(true);

            const [search] = buildGetFetch(`/patient/${selectedPatient.id}/exams`, {queryParams: {filterState: filterState}}, setExams, setIsSearching);
            search();
        } else {
            toast.warning("Seleziona un paziente");
        }
    }

    const goToExam = useCallback((props: any) => {
        history.push(`/app/exams/${props.row.original.id}`);
    }, [history]);

    const isAdmin=(Auth.isAdmin());
    const userInSession:any=Auth.user();

    const columns: any = useMemo(() => {
        return [{
            Header: 'Id',
            accessor: 'id',
            Cell: (props: any) => <Id id={props.value}/>
        },{
            Header: 'Stato',
            accessor: 'state',
            Cell: (props: any) => <StateBadge state={props.value}/>
        },{
            Header: 'Paziente',
            accessor: (d: any)=>{return d.patient.name + " " + d.patient.surname}
        },{
            Header: 'Visita',
            accessor: (d: any)=> (
                    <div>
                        {d.title} &nbsp;
                        { d.owner.id!==userInSession.id && 
                            <span className="badge badge-warning" style={{fontWeight:"normal",fontSize:"80%"}}>
                                {!isAdmin && <Fragment>Consulenza / parere medico su invito di </Fragment>} {isAdmin && <Fragment>di</Fragment>} 
                                {d.owner.surname} {d.owner.name}
                            </span>
                        }
                    </div>)
        },{
            id: "fake"
        },{
            Header: 'Inizio',
            accessor: (d: any)=>{return formatTS(d.start_ts)}
        },{
            Header: '',
            id: 'actions',
            Cell: (props: any) => <div className="text-center">
                <ButtonGroup aria-label="Azioni visita">
                    <Button title="Accedi alla visita" size="sm" variant="dark" onClick={() => {goToExam(props);}}><FaEye size={'1.5em'}/></Button>
                </ButtonGroup>
          </div>
        }]
    }, [goToExam,isAdmin]);

    return (
    <div>
        <Row>
            <Col md={12}>
                <Row className="justify-content-md-center">
                    <Col lg={12}>
                    <InputGroup className="mb-3">
                        <AcPatient onSelect={(p) => {setSelectedPatient(p[0])}}/>
                        <InputGroup.Append>
                            <Button variant="info" onClick={handleSearchExams}><FaSearch size={'1em'}/></Button>
                        </InputGroup.Append>
                    </InputGroup>
                    </Col>
                </Row>
                <div className="search-result">
                    <Loading message="Ricerca in corso..." show={isSearching}></Loading>
                    { exams.length > 0 && !isSearching && 
                        <div className="exam-table-container search-exams">
                            <Table columns={columns} data={exams}></Table>
                        </div>
                    }
                </div>
            </Col>
        </Row>
    </div>)
}


export default ExamSearch;