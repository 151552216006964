
import React, { FC, useState } from "react";
import styled, { StyledComponent } from 'styled-components';
import { Collapse } from "react-bootstrap";
import clsx from "clsx";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";

type Spacing = "normal" | "small" | "big";

type PanelProps = {
    spacing?: Spacing;
    header?: string;
    defaultState?: "open" | "closed";
    tooltip?: string;
    hint?:string;
}

const Wrapper: StyledComponent<"div", any, {size: Spacing}, any> = styled.div`
    margin-bottom: ${(props: {size: Spacing}) => props.size === 'normal' ? '1rem' : props.size === 'big' ? '2rem' : '0.5rem'};
    margin-top: ${(props: {size: Spacing}) => props.size === 'normal' ? '1rem' : props.size === 'big' ? '1rem' : '0.5rem'};
`;

export const Panel: FC<PanelProps> = ({spacing = "normal", children, header, defaultState = "closed", tooltip = "", hint = ""}) => {

    const [open, setOpen] = useState<boolean>(defaultState === "open");

    return (
        <div>
            <Wrapper size={spacing}>
                <div className={clsx("ui-divider", open && "active")}>
                    <h4 className="panel-h4" onClick={() => {setOpen(!open);}} title={tooltip}>{header} 
                    {open ? <FaCaretDown size={"2rem"}/> : <FaCaretRight size={"2rem"}/>}
                    </h4>
                    { hint &&
                        <div className="panel-hint">
                            <div>{hint}</div>
                        </div>
                    }
                </div>
                { hint &&
                    <div className="panel-hint-spacer">&nbsp;</div>
                }
            </Wrapper>
            <Collapse in={open}>
                {children}
            </Collapse>
        </div>
    )
}