
import React, { FC, useState, useCallback, Fragment } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { buildGetFetch } from "../../services/base";
import { Patient } from "../../types/patient";
import { formatDT } from "../../utils/dateutils";
import { Badge, Form } from "react-bootstrap";

type AcPatientProps = {
    onSelect: (patients: Array<Patient>) => void
    name?: string;
    valid?: boolean;
    invalid?: boolean
    disabled?: boolean;
    selected?: Array<Patient>;
}

/**
 * Ac patient compatible with formik - bootstrap
 * Expose updateSelected on ref for updating programmatically the selected patients
 * @param param0 
 */
const AcPatient: FC<AcPatientProps> = ({selected, onSelect, name, valid, invalid, disabled = false}, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<Array<Patient>>([]);

    const handleSearchPatient = useCallback((query: string) => {
        setIsLoading(true);
        const [search] = buildGetFetch<Array<Patient>>("/patient/search", {queryParams: {value: query}}, setOptions, setIsLoading);
        search();
    }, []);
    
    const onChange = useCallback((selected: Array<Patient>) => {
        onSelect(selected);
    }, [onSelect]);

    return(
        <AsyncTypeahead 
        disabled={disabled} 
        promptText="" 
        emptyLabel="Nessun paziente trovato" 
        searchText="Ricerca pazienti in corso..."
        selected={selected}
        labelKey={option => `${option.name} ${option.surname} - ${option.taxcode} - ${formatDT(option.date_of_birth)}`} 
        isLoading={isLoading} 
        options={options} 
        minLength={3} 
        id="async-patient-ac" 
        onChange={onChange}
        onSearch={handleSearchPatient} 
        placeholder="Cerca per nome, cognome o codice fiscale"
        //@ts-ignore -> renderInput non definito nei typings di AsyncTypeahead
        renderInput={({
            inputRef,
            referenceElementRef,
            ...inputProps
          }: {inputRef: any, referenceElementRef: any}) => (
              <Form.Control name={name} isInvalid={invalid} isValid={valid}
                {...inputProps}
                ref={(node: any) => {
                  inputRef(node);
                  referenceElementRef(node);
                }}/>)}
        renderMenuItemChildren={(option, props) =>(
            <Fragment>
              <span>{option.name} {option.surname} <Badge variant="info">{option.taxcode}</Badge> <b>{formatDT(option.date_of_birth)}</b></span>
            </Fragment>
          )
        }>
        </AsyncTypeahead>
    )
};


export {AcPatient};