import React, { FC, useState, useEffect, useContext } from 'react';
import { Invitation, InvitationPermission } from '../../types/invitation';
import { ExamContext } from './examcontext';
import { buildGetFetch } from '../../services/base';
import { Accordion, Alert, Badge, Button, Card } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { NewPermission } from './newpermission';
import { Loading } from '../common/loading';
import { formatTS } from '../../utils/dateutils';
import { UserBadge } from '../common/userbadge';
import { EXAM_PERMISSION_ENUM } from '../../types/enums';
import { Auth } from '../../services/auth';

interface ExamInvitationsProps {
}

const ExamInvitations: FC<ExamInvitationsProps> = () => {
    const { exam, isOwner } = useContext(ExamContext);
    const [invitations, setInvitations] = useState<Array<Invitation>>([]);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [updateInvites, setUpdateInvites] = useState(false);
    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<Array<Invitation>>(`/user/exam/${exam.id}/invitation`, {}, setInvitations, setLoading, setError);
        setUpdateInvites(false);
        fetch();
        return () => {
            cancel();
        };
    }, [updateInvites, exam.id]);

    const ls=invitations.map((i: Invitation, index: number) => {
        
        let bg = "primary";
        let msg = "In attesa";
        if(i.accepted_by_other) {
            bg = "danger";
            msg = "Annullato";
        } else if (i.accepted) {
            bg = "success";
            msg = "Accettato";
        } else if (i.refused) {
            bg = "danger";
            msg = "Rifiutato";
        }

        return (
            <Card key={i.id} bg="light">
                <Accordion.Toggle as={Card.Header} eventKey={index.toString()} className="clickable">
                    <div className="d-flex justify-content-between">
                        <div>
                            {/* 
                    //@ts-ignore */}
                    Invito a <UserBadge user={i.user} /> <Badge variant={bg}>{msg}</Badge>
                        </div>
                        <div>
                            {formatTS(i.insert_ts)}
                        </div>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body>
                        <h5 className="mt-3">Permessi impostati: </h5>
                        <ul>
                            {i.invitation_permissions?.map((p: InvitationPermission) => {
                                return <li key={i.id + '_ip_' + p.id}>{p.permission.desc}</li>
                            })}
                        </ul>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>)
        });

    const enableInvitation:boolean=isOwner || Auth.isAdmin() ; // hasPermission(EXAM_PERMISSION_ENUM.REPORT_WRITE); // was isOwner
    return (
        <Card className="mb-2">
            <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <div>Inviti per refertare / consulenza</div>
                    { enableInvitation &&
                        <Button variant="info" onClick={() => { setShowPermissionModal(true) }}>
                            <FaPlus size={'1rem'}/> Invita utente
                        </Button>
                    }
                </div>
            </Card.Header>
            <Card.Body>
                <div className="mt-4">
                    {showPermissionModal && 
                        <NewPermission
                            onInvite={(invitation: Invitation) => {
                                setShowPermissionModal(false);
                                /**
                                 * Update invite list
                                 */
                                setUpdateInvites(true);
                            }}
                            onError={() => { }}
                            onCancel={() => { 
                                setShowPermissionModal(false); 
                            }} />
                    }
                    <Loading show={loading} message="Caricamento inviti..."/>
                    {!loading && !error && 
                        <Accordion>
                            {invitations.length === 0 && 
                                <Alert variant="info">Nessun utente invitato a partecipare alla visita</Alert>
                            }
                            { ls }
                        </Accordion>
                    }
                </div>
            </Card.Body>
        </Card>
    );
}

export { ExamInvitations };
