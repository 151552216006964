
import React, { FC, useCallback } from "react";
import { Form, Table } from "react-bootstrap";
import { useFormikContext } from "formik";
import { PatientData } from "../../types/patient";
import { MedicalData } from "../../types/medicaldata";
import { get } from "lodash";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { YesNo } from "../common/yesNo";

type OtherPatologiesFormProps = {
    medicalData: MedicalData;
    editing: boolean;
    /**
     * Questo componenente va usato all'interno di un context formik. values possono essere direttamaente le medicalData oppure puo 
     * esser un oggetto piu complesso. nel secondo caso va specificato il path per arrivare all'oggetto medicalData.
     * Negli step di creazione visita base non viene usata perchè il form è già costruito a partire da medicalData. Invece all'interno
     * della visita il form contiene un oggetto piu complesso (info paziente, medical data e lesioni) quindi in quel caso specifichiamo
     * la path base da cui deve partire il componenente per settare "values" e "name" correttamente
     */
    base?: string;
}

export const OtherPatologiesForm: FC<OtherPatologiesFormProps> = ({base, medicalData, editing}) => {

    const { values, handleChange, setFieldValue } = useFormikContext<PatientData>();

    const buildFieldName = useCallback((name: string) => {
        if(base){
            return `${base}.${name}`;
        }
        return name;
    }, [base]);

    const enable2=editing ? get(values, buildFieldName('ph_therapies')) : medicalData.ph_therapies;
    const enable3=editing ? get(values, buildFieldName('ph_products')) : medicalData.ph_products;

    const localChange=(x:any) => {
        setFieldValue("medicalData.ph_present",true);        
        handleChange(x);
    }

    return (<div>
        <Table size="sm" className="form-table medical-data">
            <tbody>
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <label>Da quanto tempo sono iniziate le lesioni?</label>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('ph_time')} onChange={localChange} 
                                                value={get(values, buildFieldName('ph_time'))} />
                            }
                            { !editing && 
                                <p className="form-value">{medicalData.ph_time}</p>
                            }
                        </Form.Group>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <label>Restano fisse nello stesso posto o si manifestano anche in altre zone del corpo?</label>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('ph_fixed_text')} onChange={localChange} 
                                                value={get(values, buildFieldName('ph_fixed_text'))} />
                            }
                            { !editing && 
                                <p className="form-value">{medicalData.ph_fixed_text}</p>
                            }
                        </Form.Group>
                    </td>                    
                </tr>
                <tr>
                    <td colSpan={2}>
                            <Form.Group>
                                <label>Ogni quanto si ripresentano?</label>
                                { editing &&
                                    <Form.Control rows={5} as="textarea" name={buildFieldName('ph_period')} onChange={localChange} 
                                                    value={get(values, buildFieldName('ph_period'))} />
                                }
                                { !editing && 
                                    <p className="form-value">{medicalData.ph_period}</p>
                                }
                            </Form.Group>
                        </td>
                    </tr>    
                <tr className="form_section_space">
                    <td colSpan={2}></td>
                </tr>
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <label>Le è mai capitato prima?</label>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('ph_already')} onChange={localChange} 
                                                value={get(values, buildFieldName('ph_already'))} />
                            }
                            { !editing && 
                                <p className="form-value">{medicalData.ph_already}</p>
                            }
                        </Form.Group>
                    </td>
                </tr>    
                <tr className="form_section_space">
                    <td colSpan={2}></td>
                </tr>    
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <label>Solo lei ne è affetto in famiglia?</label>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('ph_other_people')} onChange={localChange} 
                                                value={get(values, buildFieldName('ph_other_people'))} />
                            }
                            { !editing && 
                                <p className="form-value">{medicalData.ph_other_people}</p>
                            }
                        </Form.Group>
                    </td>
                </tr> 
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <label>Ci sono altre problematiche dermatologiche diverse da questa in famiglia?</label>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('ph_other_ph_in_family')} onChange={localChange} 
                                                value={get(values, buildFieldName('ph_other_ph_in_family'))} />
                            }
                            { !editing && 
                                <p className="form-value">{medicalData.ph_other_ph_in_family}</p>
                            }
                        </Form.Group>
                    </td>
                </tr>    
                <tr className="form_section_space">
                    <td colSpan={2}></td>
                </tr>  
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <label>In generale: elenco dei farmaci assunti per qualsiasi patologia presente</label>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('ph_drugs')} onChange={localChange} 
                                                value={get(values, buildFieldName('ph_drugs'))} />
                            }
                            { !editing && 
                                <p className="form-value">{medicalData.ph_drugs}</p>
                            }
                        </Form.Group>
                    </td>
                </tr> 
                <tr className="form_section_space">
                    <td colSpan={2}></td>
                </tr> 
                <tr>
                    <td className="text-center minimal-td">
                            <YesNo onChange={(v: any) => {
                                if(editing){
                                    setFieldValue("medicalData.ph_present",true);
                                    setFieldValue(buildFieldName('ph_therapies'), v);
                                }
                            }} disabled={!editing} checked={editing ? get(values, buildFieldName('ph_therapies')) : medicalData.ph_therapies} />
                    </td>
                    <td>
                        <label>Dopo che è sorta la patologia oggetto della visita ha seguito delle terapie?</label><br/>
                        <i>(es. applicazione creme, assunzione farmaco con prescrizione del farmacista o del medico di base, ecc..)</i>
                    </td>
                </tr>
                { enable2 &&
                    <tr>
                        <td colSpan={2}>                        
                            <Form.Group>
                                <label>
                                    Quali?
                                </label>
                                { editing &&
                                    <Form.Control rows={5} as="textarea" name={buildFieldName('ph_therapies_desc')} onChange={localChange} 
                                                    value={get(values, buildFieldName('ph_therapies_desc'))} />
                                }
                                { !editing && 
                                    <p className="form-value">{medicalData.ph_therapies_desc}</p>
                                }
                            </Form.Group>
                        </td>
                    </tr> 
                }
                <tr>
                    <td className="text-center minimal-td">
                        <YesNo onChange={(v: any) => {
                                if(editing){
                                    setFieldValue("medicalData.ph_present",true);
                                    setFieldValue(buildFieldName('ph_products'), v);
                                }
                            }} disabled={!editing} checked={editing ? get(values, buildFieldName('ph_products')) : medicalData.ph_products} />
                    </td>
                    <td>
                        <label>Prima dell'insorgere della malattia ha applicato qualche prodotto nel punto nel quale è insorta la patologia?</label><br/>
                        <i>(es. applicazione creme, assunzione farmaco con prescrizione del farmacista o del medico di base, ecc..)</i>
                    </td>
                </tr>
                { enable3 &&
                    <tr>
                        <td colSpan={2}>                        
                            <Form.Group>
                                <label>
                                    Quali?
                                </label>
                                { editing &&
                                    <Form.Control rows={5} as="textarea" name={buildFieldName('ph_products_desc')} onChange={localChange} 
                                                    value={get(values, buildFieldName('ph_products_desc'))} />
                                }
                                { !editing && 
                                    <p className="form-value">{medicalData.ph_products_desc}</p>
                                }
                            </Form.Group>
                        </td>
                    </tr> 
                }
                <tr className="form_section_space">
                    <td colSpan={2}></td>
                </tr>   
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <div>
                                <label>Ha dei sintomi associati quali prurito, dolore o bruciore? Se si, quanto?</label><br/>
                                <i>(scala da sintomo non presente a sintomo insopportabile)</i>
                            </div>
                            <div className="mr-4 ml-4 mb-4 mt-4">
                                <Slider min={0} max={100} step={5} dots={false} disabled={!editing} className="color-even-disabled"
                                    defaultValue={get(values, buildFieldName('ph_symptom_degree'))} 
                                    onChange={(v: any) => {
                                        if(editing){
                                            setFieldValue("medicalData.ph_present",true);
                                            setFieldValue(buildFieldName('ph_symptom_degree'), v);
                                        }
                                    }} 
                                    marks={{ "0": { label: "0"},
                                             "5": { label: "5"}, "10": { label: "10"},  "15": { label: "15"}, "20": { label: "20"},
                                             "25": { label: "25"}, "30": { label: "30"}, "35": { label: "35"}, "40": { label: "40"},
                                             "45": { label: "45"}, "50": { label: "50"}, "55": { label: "55"}, "60": { label: "60"},
                                             "65": { label: "65"}, "70": { label: "70"}, "75": { label: "75"}, "80": { label: "80"},
                                             "85": { label: "85"}, "90": { label: "90"}, "95": { label: "95"}, "100": { label: "100"} }}
                                    />
                            </div>
                        </Form.Group>
                    </td>
                </tr> 
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <label>
                                Per quanto riguarda il solo sintomo del prurito, è più presente di giorno o di notte?
                            </label>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('ph_itching_when')} onChange={localChange} 
                                                value={get(values, buildFieldName('ph_itching_when'))} />
                            }
                            { !editing && 
                                <p className="form-value">{medicalData.ph_itching_when}</p>
                            }
                        </Form.Group>
                    </td>
                </tr> 
                <tr className="form_section_space">
                    <td colSpan={2}></td>
                </tr>  
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <label>
                                E' in un momento peggiore o migliore della manifestazione?
                            </label>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('ph_situation')} onChange={localChange} 
                                                value={get(values, buildFieldName('ph_situation'))} />
                            }
                            { !editing && 
                                <p className="form-value">{medicalData.ph_situation}</p>
                            }
                        </Form.Group>
                    </td>
                </tr> 
                <tr className="form_section_space">
                    <td colSpan={2}></td>
                </tr>  
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <label>
                                E' in un periodo di particolare stress o ha mangiato qualcosa di diverso in questo periodo?
                            </label>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('ph_stress')} onChange={localChange} 
                                                value={get(values, buildFieldName('ph_stress'))} />
                            }
                            { !editing && 
                                <p className="form-value">{medicalData.ph_stress}</p>
                            }
                        </Form.Group>
                    </td>
                </tr> 
                <tr className="form_section_space">
                    <td colSpan={2}></td>
                </tr>     
                <tr>
                    <td className="text-center">
                        <YesNo onChange={(v: any) => {
                                if(editing){
                                    setFieldValue("medicalData.ph_present",true);
                                    setFieldValue(buildFieldName('ph_blood_analysis'), v);
                                }
                            }} disabled={!editing} checked={editing ? get(values, buildFieldName('ph_blood_analysis')) : medicalData.ph_blood_analysis}/>
                    </td>
                    <td>
                        <label>Ha eseguito negli ultimi 6 mesi degli esami del sangue?</label>
                    </td>
                </tr>
                <tr className="form_section_space">
                    <td colSpan={2}></td>
                </tr>
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <p>Altre annotazioni</p>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('ph_generic_notes')} onChange={localChange} 
                                                value={get(values, buildFieldName('ph_generic_notes'))} />
                            }
                            { !editing && 
                                <p className="form-value">{medicalData.ph_generic_notes}</p>
                            }
                        </Form.Group>
                    </td>
                </tr>
            </tbody>
        </Table>
    </div>)
}