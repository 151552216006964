
import React, {FC} from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";
import { Patients } from "./patientslist";
import { PatientDetail } from "./patientdetail";

const PatientCenter: FC<{}> = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
          <Route exact path={`${path}`}>
            <Patients/>
          </Route>
          <Route exact path={`${path}/:patientId`}>
            <PatientDetail/>
          </Route>
        </Switch>
    )
}

export {PatientCenter};