
import { EventEmitter } from 'events';
import { NotificationService, USER_CHANNEL } from './notificationservice';
import { toast } from 'react-toastify';

interface UMService {
    /**
     * Get current unread messages
     */
    getUnreadMessages: () => number;
    /**
     * pending unread messages change notifier
     */
    emitter: () => EventEmitter;
    /**
     * Decrement unread messages shortcut
     */
    decrementUnreadMessages: () => void;
    /**
     * Increment unread messages shortcut
     */
    incrementUnreadMessages: () => void;
    /**
     * Pending unread messages setter
     */
    setUnreadMessages: (inv: number) => void;
}

export const CHANGE_EVENT = 'UNREAD_MESSAGES_CHANGE';

export const UnreadMessagesService: UMService = (() => {
    
    let _unreadMessages: number = 0;

    let _emitter = new EventEmitter();

    const _incrementUnreadMessages = () => {
        _unreadMessages++;
        _emitter.emit(CHANGE_EVENT);
    };

    const _decrementUnreadMessages = () => {
        _unreadMessages--;
        if(_unreadMessages<0) _unreadMessages=0;
        _emitter.emit(CHANGE_EVENT);
    }

    NotificationService.on(USER_CHANNEL.events.UnreadMessage, (e: {
        user_id: number
    }) => {
        toast.info("Nuovo messaggio ricevuto");
        _incrementUnreadMessages();
    });
    
    return {
        setUnreadMessages: (inv: number) => {
            let toEmit=(_unreadMessages!=inv);
            _unreadMessages = inv;
            if(toEmit) _emitter.emit(CHANGE_EVENT);
        },
        getUnreadMessages: () => {
            return _unreadMessages;
        },
        emitter: () => {
            return _emitter;
        },
        decrementUnreadMessages: _decrementUnreadMessages,
        incrementUnreadMessages: _incrementUnreadMessages
    }

})();
