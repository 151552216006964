
import React, { FC, ComponentType, useRef, useEffect, useCallback, Fragment, useState } from "react";
import styled from "styled-components";

export interface WithZoomProps {
    /**
     * tunable zoom level
     */
    zoomLevel: number;
    /**
     * tunable zoom size
     */
    zoomSize: number;
    /**
     * Optionaly on/off for the zoom. Default true
     */
    zoomEnabled?: boolean;
}

const ZoomWrapper = styled.div<WithZoomProps & {ref: React.Ref<HTMLDivElement>}>` 
    pointer-events: none;
    z-index: 2050;
    position: fixed;
    display: none;
    width: ${props => props.zoomSize + 'px'};
    height: ${props => props.zoomSize + 'px'};
    overflow: hidden;
    background-color: transparent;
    border-radius: 0 
        /* cosi' per zoom circolare ${props => (props.zoomSize / 2) + 'px'};*/
`;

/**
 * Component Enhancer for an image which adds a tunable zoom capability. Requires that wrapped component forwards the img ref (in order to bind the required mouse events and get the src).
 * 
 * Example: 
 * 

    const Image = React.forwardRef<HTMLImageElement, ImageProps>(
    ({ path }, ref) => {
        return (
            <img ref={ref} src={path} style={{ maxHeight: 350, width: 'auto', position: 'relative' }} />
        )
    });

    In this way withZoom(Image) will works perfectly because the Image component correcly forwardd the ref of the <img> node

 * @param Comp The component that will be extended with zoom capabilities. 3 props will be added to control the zoom (check WithZoomProps). THe component should forward
 * the ref of the <img> used to get the src of the image and bind the correct mouse listeners
 */
const withZoom = (Comp: ComponentType<any & { ref: React.Ref<HTMLImageElement> }>): FC<any & WithZoomProps> => ({ zoomLevel, zoomSize, zoomEnabled = true, ...props }) => {

    const zoomRef = useRef<HTMLDivElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const srcImgRef = useRef<HTMLImageElement>(null);
    const [path, setPath] = useState<string|null>(null);

    const getRotation = (el:any) => {
        let st = window.getComputedStyle(el, null);
        let tr = st.getPropertyValue("transform");
        let values = tr.split('(')[1].split(')')[0].split(',');
        let a = parseFloat(values[0]); 
        let b = parseFloat(values[1]);
        let angle = Math.round(Math.atan2(b, a) * (180/Math.PI));
        return angle;
    }

    
    const move = useCallback((event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {

        if (zoomRef.current !== null && imgRef.current !== null && srcImgRef.current !== null) {

            // @ts-ignore
            const image: HTMLImageElement = event.target;

            // Recupero i parametri di rotazione
            /*
            let rotation=getRotation(srcImgRef.current);
            let rad=rotation/180*Math.PI;
            let ss=Math.sin(-rad);
            let cc=Math.cos(-rad);
            */

            // Posizioniamo il riquadro dello zoom in base alla posizione del mouse
            zoomRef.current.style.display = 'block';
            zoomRef.current.style.left = (event.clientX - (zoomSize / 2)) + 'px';
            zoomRef.current.style.top = (event.clientY- (zoomSize / 2)) + 'px';

            // Impostiamo lo zoom 
            const { left, top, width, height } = image.getBoundingClientRect();
            const newWidth = (width * zoomLevel);
            const newHeight = (height * zoomLevel);
            imgRef.current.style.width = newWidth + 'px';
            imgRef.current.style.height = newHeight + 'px';

            // Recuperiamo le impostazioni CSS della rotazione - dovremmo reimpostarle sulla immagine zoomata
            let imageTransform=srcImgRef.current.style.transform;            

            // Posizioniamo l'immagine zoomata
            let nx=(newWidth / 4) + (zoomSize / 2);
            let ny=(newHeight / 4) + (zoomSize / 2);
            imgRef.current.style.left = nx + 'px';
            imgRef.current.style.top = ny + 'px';

            let rx=(event.clientX-left)*zoomLevel;
            let ry=(event.clientY-top)*zoomLevel;
            let fn2=""
                + ` scale(${zoomLevel})` 
                + ` translate(${'-' + rx + 'px'},${'-' + ry + 'px'})`
                + imageTransform;
            imgRef.current.style.transform =  fn2;
            console.log(fn2);
        }
    }, [zoomLevel, zoomSize]);

    const remove = useCallback(() => {
        if (zoomRef.current !== null) {
            zoomRef.current.style.display = 'none';
        }
    }, []);

    useEffect(() => {
        if (srcImgRef.current) {
            if (zoomEnabled) {
                // @ts-ignore
                srcImgRef.current.onmousemove = move;
                srcImgRef.current.onmouseleave = remove;
                srcImgRef.current.style.pointerEvents = "all";
            } else {
                srcImgRef.current.onmousemove = null;
                srcImgRef.current.onmouseleave = null;
                srcImgRef.current.style.pointerEvents = "none";
            }
            if(path === null){
                setPath(srcImgRef.current.src);
            }
        } else {
            if(path !== null){
                setPath(null);
            }
        }
    }, [zoomEnabled,move,path,remove]);

    return (<Fragment>
        {path && <ZoomWrapper ref={zoomRef} zoomLevel={zoomLevel} zoomSize={zoomSize} className="zoom-wrapper">
            <img alt="Immagine" ref={imgRef} style={{ position: 'absolute' }} src={path} />
        </ZoomWrapper>}
        <Comp ref={srcImgRef} {...props} />
    </Fragment>)

}

export { withZoom };
