import React, { FC, useContext } from "react"
import { Card } from "react-bootstrap"
import { EXAM_PERMISSION_ENUM } from "../../types/enums"
import { Panel } from "../common/panel"
import { ExamBindings } from "./exambindings"
import { ExamContext } from "./examcontext"
import { ExamMediaContainer } from "./exammedia"
import { WithPermission } from "./withpermission"

const ExamExecution: FC<{}> = () => {

    const {isOwner} = useContext(ExamContext);

    return(
        <Card border="info" className="mb-2">
            <Card.Header>Esame</Card.Header>
            <Card.Body>                
                {isOwner && 
                    <Panel spacing="big" header="COLLEGAMENTO A VISITE PRECEDENTI" defaultState="closed" hint="Clicca qui se intendi collegare la visita attuale ad altre visite precedenti">
                        <div>
                            <ExamBindings />
                        </div>
                    </Panel>
                }
                <WithPermission permission={EXAM_PERMISSION_ENUM.MEDIA_READ}>
                    <Panel spacing="big" header="IMMAGINI" defaultState="open" hint="Per caricare le immagini usa lo smartphone oppure usa il tuo PC cliccando l'icona 'Gestione e caricamento immagini' sotto">
                        <div>
                            <ExamMediaContainer/>                                                
                        </div>
                    </Panel>
                </WithPermission>
            </Card.Body>
        </Card>        
    )
}

export { ExamExecution }