import { clone } from "lodash";
import React, {FC, useState, useEffect, Fragment} from "react";
import { Alert, Button, Form, Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import { buildGetFetch, buildPostFetch } from "../../services/base";
import { UserSetting } from "../../types/useretting";
import { Loading } from "../common/loading";

const Settings: FC<{}> = () => {

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [header, setHeader] = useState<UserSetting|null>(null);

    useEffect(() => {
        const [fetch] = buildGetFetch<UserSetting>('/user/settings', {}, setHeader, setLoading);
        fetch();
    }, []);

    const doSave=() =>{
        setSaving(true);
        
        // sanity check
        if(!header) return;
        if(!header.row_1) header.row_1="";
        if(!header.row_2) header.row_2="";
        if(!header.row_3) header.row_3="";
        if(!header.row_4) header.row_4="";
        if(!header.row_5) header.row_5="";

        const [update] = buildPostFetch<any>('/user/settings', header);
        update().then(() => {
            setSaving(false);
            toast.success("Salvataggio effettuato");
        });
    }

    const handleChange=(field:string,val:string) => {
        if(header) {
            let hh:any=clone(header);
            hh[field]=val;
            setHeader(hh);
        }
    }

    return (
        <Tabs id="tabs">
            <Tab eventKey="headers" title="Intestazione">
                <div className="mt-3">
                    { loading &&
                        <Loading show={true} message="Caricamento impostazioni..." />
                    }
                    { !loading &&
                        <Fragment>
                            <Alert variant="dark" title="Suggerimento">
                                Impostazione delle righe di intestazione dei documenti generati (es. parere)
                            </Alert>
                            <Form onSubmit={doSave}>
                                <Form.Group controlId="formRow1">
                                    <Form.Label>Riga 1 : nome, cognome</Form.Label>
                                    <Form.Control type="text" value={header?.row_1} onChange={(e) => handleChange('row_1',e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="formRow2">
                                    <Form.Label>Riga 2 : indirizzo e numero civico</Form.Label>
                                    <Form.Control type="text" value={header?.row_2} onChange={(e) => handleChange('row_2',e.target.value)} />
                                </Form.Group>     
                                <Form.Group controlId="formRow3">
                                    <Form.Label>Riga 3 : cap e città</Form.Label>
                                    <Form.Control type="text" value={header?.row_3} onChange={(e) => handleChange('row_3',e.target.value)} />
                                </Form.Group>     
                                <Form.Group controlId="formRow4">
                                    <Form.Label>Riga 4 : c.fiscale / p.iva</Form.Label>
                                    <Form.Control type="text" value={header?.row_4} onChange={(e) => handleChange('row_4',e.target.value)} />
                                </Form.Group>     
                                <Form.Group controlId="formRow5">
                                    <Form.Label>Riga 5 : altri dati liberi (tel., mail, ecc.)</Form.Label>
                                    <Form.Control type="text" value={header?.row_5} onChange={(e) => handleChange('row_5',e.target.value)} />
                                </Form.Group>     
                                <Button disabled={saving} variant="info" className="mt-3" onClick={()=>doSave()}>
                                    Salva
                                </Button>                                    
                            </Form>
                        </Fragment>
                    }
                </div>
            </Tab>
        </Tabs>
    );
}


export {Settings}