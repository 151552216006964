
import React, { FC, useState, useEffect, useCallback } from "react";
import { Badge } from "react-bootstrap";
import { UnreadMessagesService, CHANGE_EVENT } from "../services/messageservice";

export const UnreadMessagesBadge: FC<{}> = () => {

    const [unreadMessages, setUnreadMessages] = useState<number>(UnreadMessagesService.getUnreadMessages());

    const onUnreadMessagesChange = useCallback(() => {    
        setUnreadMessages(UnreadMessagesService.getUnreadMessages());
    }, []);

    useEffect(() => {
        UnreadMessagesService.emitter().on(CHANGE_EVENT, onUnreadMessagesChange);
        return () => {
            UnreadMessagesService.emitter().off(CHANGE_EVENT, onUnreadMessagesChange);
        }
    }, [onUnreadMessagesChange]);

    if(unreadMessages === 0){
        return (<span/>)
    }

    return (<Badge variant="warning">{unreadMessages}</Badge>)

}