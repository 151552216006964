
import { EventEmitter } from 'events';
import { NotificationService, USER_CHANNEL } from './notificationservice';
import { toast } from 'react-toastify';

interface RService {
    /**
     * Get current reminders
     */
    getReminders: () => number;
    /**
     * reminder change notifier
     */
    emitter: () => EventEmitter;
    /**
     * Decrement reminders shortcut
     */
    decrementReminders: () => void;
    /**
     * Increment reminders shortcut
     */
    incrementReminders: () => void;
    /**
     * Reminders setter
     */
    setReminders: (inv: number) => void;
}

export const CHANGE_EVENT = 'REMINDER_CHANGE';

export const RemindersService: RService = (() => {
    
    let _reminders: number = 0;

    let _emitter = new EventEmitter();

    const _incrementReminders = () => {
        _reminders++;
        _emitter.emit(CHANGE_EVENT);
    };

    const _decrementReminders = () => {
        _reminders--;
        if(_reminders<0) _reminders=0;
        _emitter.emit(CHANGE_EVENT);
    }

    NotificationService.on(USER_CHANNEL.events.UnreadMessage, (e: {
        user_id: number
    }) => {
        toast.info("Nuovo richiamo ricevuto");
        _incrementReminders();
    });
    
    return {
        setReminders: (inv: number) => {
            let toEmit=(_reminders!=inv);
            _reminders = inv;
            if(toEmit) _emitter.emit(CHANGE_EVENT);
        },
        getReminders: () => {
            return _reminders;
        },
        emitter: () => {
            return _emitter;
        },
        decrementReminders: _decrementReminders,
        incrementReminders: _incrementReminders
    }

})();
