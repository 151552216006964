
import React, { FC, Fragment, useContext, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { ExamContext } from "./examcontext";
import { PatientCard } from "../patient/patientcard";
import { PatientData } from "../../types/patient";
import { EXAM_PERMISSION_ENUM } from '../../types/enums';
import { Auth } from "../../services/auth";

const ExamMedicalData: FC = () => {
    const { exam,patientData, isClosed, onDataUpdate, hasPermission } = useContext(ExamContext);

    const onPatientUpdate = (patientData: PatientData) => {
        onDataUpdate({
            patientValue: patientData,
            patientDataPath: "$ALL$"
        })
    }

    const isAdmin:boolean = Auth.isAdmin();
    const [user] = useState(Auth.user()?.id);
    const isInvited=(!isAdmin && exam?.owner_id !== user);

    return (<Fragment>
        
        <Row>
            <Col lg={12}>
                <PatientCard showBasicInfo={false} showMedicalData={true} showExamList={!isInvited}
                    canEdit={!isClosed && hasPermission(EXAM_PERMISSION_ENUM.GENERIC_WRITE)} 
                    patientData={patientData} onUpdate={onPatientUpdate}>
                </PatientCard>
            </Col>
        </Row>
    </Fragment>)
}


export { ExamMedicalData };