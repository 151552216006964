
import React, { FC, useCallback } from "react";
import { FormGroup, Form, Col, Table } from "react-bootstrap";
import Switch from 'react-switch';
import { useFormikContext } from "formik";
import { PatientData } from "../../types/patient";
import { MedicalData } from "../../types/medicaldata";
import { get } from "lodash";

type SkinNotesFormProps = {
    neiNote: MedicalData;
    editing: boolean;
    /**
     * Questo componenente va usato all'interno di un context formik. values possono essere direttamaente le medicalData oppure puo 
     * esser un oggetto piu complesso. nel secondo caso va specificato il path per arrivare all'oggetto medicalData.
     * Negli step di creazione visita base non viene usata perchè il form è già costruito a partire da medicalData. Invece all'interno
     * della visita il form contiene un oggetto piu complesso (info paziente, medical data e lesioni) quindi in quel caso specifichiamo
     * la path base da cui deve partire il componenente per settare "values" e "name" correttamente
     */
    base?: string;
}

export const NeviNoteForm: FC<SkinNotesFormProps> = ({base, neiNote, editing}) => {

    const { values, handleChange, setFieldValue } = useFormikContext<PatientData>();

    const buildFieldName = useCallback((name: string) => {
        if(base){
            return `${base}.${name}`;
        }
        return name;
    }, [base]);

    const localChange=(x:any) => {
        setFieldValue("medicalData.nv_present",true);        
        handleChange(x);
    }

    return (<div>
        <Table bordered hover size="sm" className="form-table">
            <tbody>
                <tr>
                    <td>
                        Familiarità: Ha uno o più familiari di 1° grado (genitori, fratelli o figli) con pregressa neoplasia maligna della pelle
                    </td>
                    <td className="text-center">
                        <Switch handleDiameter={25} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={26} width={55} onChange={(v: any) => {
                                if(editing){
                                    setFieldValue("medicalData.nv_present",true);
                                    setFieldValue(buildFieldName('fam'), v);
                                }
                            }} checked={editing ? get(values, buildFieldName('fam')) : neiNote.fam} className="react-switch"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Ha avuto in passato un tumore cutaneo 
                    </td>
                    <td className="text-center">
                        <Switch handleDiameter={25} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={26} width={55} onChange={(v: any) => {
                                if(editing){
                                    setFieldValue("medicalData.nv_present",true);
                                    setFieldValue(buildFieldName('tumor'), v);
                                }
                            }} checked={editing ? get(values, buildFieldName('tumor')) : neiNote.tumor} className="react-switch"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Presenta segni di pregresse ustioni solari (e presenta quindi lentiggini sulla pelle o dietro il collo)
                    </td>
                    <td className="text-center">
                        <Switch handleDiameter={25} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={26} width={55} onChange={(v: any) => {
                                if(editing){
                                    setFieldValue("medicalData.nv_present",true);
                                    setFieldValue(buildFieldName('solar_dmg'), v);
                                }
                            }} checked={editing ? get(values, buildFieldName('solar_dmg')) : neiNote.solar_dmg} className="react-switch"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Presenta segni di fotodanno (presenza di campo di cancerizzazione o cheratosi attiniche)
                    </td>
                    <td className="text-center">
                        <Switch handleDiameter={25} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={26} width={55} onChange={(v: any) => {
                                if(editing){
                                    setFieldValue("medicalData.nv_present",true);
                                    setFieldValue(buildFieldName('foto_dmg'), v);
                                }
                        }} checked={editing ? get(values, buildFieldName('foto_dmg')) : neiNote.foto_dmg} className="react-switch"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Si è sottoposto spesso ad abbronzatura artificiale                    
                    </td>
                    <td className="text-center">
                        <Switch handleDiameter={25} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={26} width={55} onChange={(v: any) => {
                            if(editing){
                                setFieldValue("medicalData.nv_present",true);
                                setFieldValue(buildFieldName('artificial_tan'), v);
                            }
                        }} checked={editing ? get(values, buildFieldName('artificial_tan')) : neiNote.artificial_tan} className="react-switch"/>
                    </td>
                </tr>
                <tr>
                    <td>
                        Ha un neo congenito di grandi dimensioni                    
                    </td>
                    <td className="text-center">
                        <Switch handleDiameter={25} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={26} width={55} onChange={(v: any) => {
                                if(editing){
                                    setFieldValue("medicalData.nv_present",true);
                                    setFieldValue(buildFieldName('big_neo'), v);
                                }
                            }} checked={editing ? get(values, buildFieldName('big_neo')) : neiNote.big_neo} className="react-switch"/>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        In quale classe di numero di nei si posiziona?             
                        <FormGroup>
                            <Col>
                                <Form.Check id="neo_class_1" type="radio" name="neo_class" label="Minori di 50" onChange={() => {
                                    if(editing){
                                        setFieldValue("medicalData.nv_present",true);
                                        setFieldValue(buildFieldName('neo_class'), 1);
                                    }
                                }} checked={editing ? get(values, buildFieldName('neo_class')) === 1 : neiNote.neo_class === 1 }/>
                                <Form.Check id="neo_class_2" type="radio" name="neo_class" label="Maggiore di 50 e minori di 100" onChange={() => {
                                    if(editing){
                                        setFieldValue("medicalData.nv_present",true);
                                        setFieldValue(buildFieldName('neo_class'), 2);
                                    }
                                }} checked={editing ? get(values, buildFieldName('neo_class')) === 2 : neiNote.neo_class === 2 }/>
                                <Form.Check id="neo_class_3" type="radio" name="neo_class" label="Maggiori di 100 o alcuni grandi nei irregolari" onChange={() => {
                                    if(editing){
                                        setFieldValue("medicalData.nv_present",true);
                                        setFieldValue(buildFieldName('neo_class'), 3);
                                    }
                                }} checked={editing ? get(values, buildFieldName('neo_class')) === 3 : neiNote.neo_class === 3 }/>
                            </Col>
                        </FormGroup>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <p>Caratteristiche dermoscopiche di rilievo</p>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('neo_notes')} onChange={localChange} 
                                                value={get(values, buildFieldName('neo_notes'))} />
                            }
                            { !editing && 
                                <p className="form-value">{neiNote.neo_notes}</p>
                            }
                        </Form.Group>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>                        
                        <Form.Group>
                            <p>Altre annotazioni</p>
                            { editing &&
                                <Form.Control rows={5} as="textarea" name={buildFieldName('generic_notes')} onChange={localChange} 
                                                value={get(values, buildFieldName('generic_notes'))} />
                            }
                            { !editing && 
                                <p className="form-value">{neiNote.generic_notes}</p>
                            }
                        </Form.Group>
                    </td>
                </tr>
            </tbody>
        </Table>
    </div>)
}