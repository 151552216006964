import React, { FC, useContext, Fragment, useState, useEffect, useCallback } from "react";
import { BASE, API_ROUTE, buildGetFetch, buildPostFetch } from "../../services/base";
import { ExamContext } from "./examcontext";
import { AiOutlineFilePdf } from "react-icons/ai";
import { MdAdd } from "react-icons/md";
import { Button, Card } from "react-bootstrap";
import { Panel } from "../common/panel";
import { FaFile, FaFilePdf, FaFileUpload, FaFileWord, FaImage, FaFileCsv, FaFileAlt, FaDownload } from "react-icons/fa";
import { toast } from 'react-toastify';
import { EXAM_PERMISSION_ENUM } from '../../types/enums';
import { Auth } from "../../services/auth";

export const ExamDocument: FC<{}> = () => {

    const { exam, changeTab, hasPermission } = useContext(ExamContext);
    const [hasReport, setHasReport] = useState<boolean>(false);
    const [hasParentReport, setHasParentReport] = useState<boolean>(false);
    const [fileToUpload, setFileToUpload] = useState<any>();
    const [uploading, setUploading] = useState<boolean>(false);
    const [docs, setDocs] = useState<any>(null);
    const [parentDocs, setParentDocs] = useState<any>(null);

    const isAdmin:boolean = Auth.isAdmin();
    const isSpecialist:boolean = Auth.isSpecialist();
    const isBaseDoctor:boolean = Auth.isBaseDoctor();
    const isOperator:boolean = Auth.isOperator();

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<any>(`/user/exam/${exam.id}/report`, { silent: true }, (data: any) => {
            setHasReport(data !== null);
        });        
        fetch();

        const [fetchDocs] = buildGetFetch<any>(`/user/exam/${exam.id}/docs`, {}, (data: any) => {
            setDocs(data);
        });        
        fetchDocs();

        if(exam.parent_exam_id) {
            const [fetchParent] = buildGetFetch<any>(`/user/exam/${exam.parent_exam_id}/report`, { silent: true }, (data: any) => {
                setHasParentReport(data !== null);
            });        
            fetchParent();

            const [fetchParentDocs] = buildGetFetch<any>(`/user/exam/${exam.parent_exam_id}/docs`, {}, (data: any) => {
                setParentDocs(data);
            });        
            fetchParentDocs();
        }

        return () => {
            cancel();
        };
    }, [exam]);

    const showUpload = useCallback(() => {
        setUploading(true);
    }, []);

    const hideUpload = useCallback(() => {
        setUploading(false);
    }, []);

    const onFileSelected=useCallback((e) => {
        setFileToUpload(e.target.files[0]);
    },[]);

    function getIcon(d:any) {
        let icon=<FaFile />;
        if(d.content_type.indexOf("image")>=0) icon=<FaImage />;
        else if(d.content_type.indexOf("pdf")>=0) icon=<FaFilePdf />;
        else if(d.content_type.indexOf("word")>=0) icon=<FaFileWord />;
        else if(d.content_type.indexOf("excel")>=0) icon=<FaFileCsv />;
        else if(d.content_type.indexOf("csv")>=0) icon=<FaFileCsv />;
        else if(d.content_type.indexOf("text")>=0) icon=<FaFileAlt />;
        return icon;
    }

    const doUpload = useCallback(() => {
        setUploading(true);
        
        const formData = new FormData();
        formData.append("file",fileToUpload);
        formData.append("fileName",fileToUpload.name);
        const [storeUpload] = buildPostFetch<Document>(`/exam/${exam.id}/docs/upload`, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }, (d:Document) => {
            setUploading(false);
            if(d === null){
                toast.error("Errore in upload");
            } else {
                toast.success("Documento salvato con successo");
                const [fetchDocs] = buildGetFetch<any>(`/user/exam/${exam.id}/docs`, {}, (data: any) => {
                    setDocs(data);
                });        
                fetchDocs();
            }
        });
        storeUpload();
    },[fileToUpload,exam.id]);

    let docList=null;
    if(docs!=null)
        docList=docs.map((d:any) => {
            let icon=getIcon(d);
            let res=<div key={d.id}>
                    <a className="btn btn-light download-btn" href={`${BASE}${API_ROUTE}/exam/${exam.id}/docs/download/${d.id}`} download target="_blank">
                        <FaDownload />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="btn btn-light download-btn" href={`${BASE}${API_ROUTE}/exam/${exam.id}/docs/open/${d.id}`} download target="_blank">
                        {icon} {d.name}
                    </a>
                </div>;
            return res;
        });
    let docCount:string="...";
    if(docList!=null) docCount="" + docList.length;

    let parentDocList=null;
    if(parentDocs!=null)
        parentDocList=parentDocs.map((d:any) => {
            let icon=getIcon(d);
            let res=<div key={d.id}>
                    <a className="btn btn-light download-btn" href={`${BASE}${API_ROUTE}/exam/${exam.parent_exam_id}/docs/download/${d.id}`} download target="_blank">
                        <FaDownload />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="btn btn-light download-btn" href={`${BASE}${API_ROUTE}/exam/${exam.parent_exam_id}/docs/open/${d.id}`} download target="_blank">
                        {icon} {d.name}
                    </a>
                </div>;
            return res;
        });
    let parentDocCount:string="...";
    if(parentDocList!=null) parentDocCount="" + parentDocList.length;

    return (
        <div>
            <Card border="info" className="mb-2">
                <Card.Header>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            Documenti
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>                                       
                    { (isAdmin || isSpecialist || isBaseDoctor) &&
                        <Fragment>
                            <Panel spacing="big" header="PARERE MEDICO" defaultState="open">
                                <Fragment>
                                    { hasReport &&
                                        <div className="mb-3">                                
                                            <a className="btn btn-light download-btn mr-4" download={`parere_${exam.id}.pdf`} href={`${BASE}${API_ROUTE}/user/exam/${exam.id}/report/download`}>
                                                <FaDownload size={"1.5rem"} /> Scarica
                                            </a>
                                            <a className="btn btn-info" style={{marginRight:'1rem'}} target="_blank" href={`${BASE}${API_ROUTE}/user/exam/${exam.id}/report/pdf`}>
                                                <AiOutlineFilePdf size={"1.5rem"} /> Anteprima
                                            </a>
                                        </div>
                                    }
                                    { !hasReport &&
                                        <div>
                                            <p>
                                                Parere medico non ancora disponibile. 
                                                { !isBaseDoctor && !isOperator &&
                                                    <span>
                                                        Per stilare il parere medico passa alla vista 
                                                        <a className="internal-link" onClick={() => { changeTab("report") }}>Parere medico</a>
                                                    </span>
                                                }
                                            </p>
                                        </div>
                                    }
                                </Fragment>
                            </Panel>
                            { hasParentReport &&
                                <Panel spacing="big" header="PARERE VISITA DI RIFERIMENTO" defaultState="open">
                                    <Fragment>
                                        <div className="m-4">
                                            <a className="btn btn-light mr-4 download-btn" download={`parere_${exam.parent_exam_id}.pdf`} href={`${BASE}${API_ROUTE}/user/exam/${exam.parent_exam_id}/report/download`}>
                                                <FaDownload size={"1.5rem"} /> Scarica
                                                </a>
                                            <a className="btn btn-info" style={{marginRight:'1rem'}} target="_blank" href={`${BASE}${API_ROUTE}/user/exam/${exam.parent_exam_id}/report/pdf`}>
                                                <AiOutlineFilePdf size={"1.5rem"} /> Anteprima
                                                </a>
                                        </div>
                                    </Fragment>
                                </Panel>
                            }
                        </Fragment>                            
                    }
                    <Panel spacing="big" header={"DOCUMENTI PRESENTI (" + docCount + ")"} defaultState="open">  
                        <div>
                            { hasPermission(EXAM_PERMISSION_ENUM.GENERIC_WRITE) &&
                                <Button variant="warning" onClick={showUpload}><MdAdd/> Carica documento</Button>
                            }

                            { uploading &&                        
                                <div className="alert alert-info" style={{marginTop:'1rem'}}>
                                    <div className="close-x" onClick={hideUpload}>&times;</div>
                                    <div style={{fontWeight:600,textDecoration:'underline'}}>Selezione documento</div>
                                    <form method="post" action="#" id="#">
                                        <div className="form-group files">
                                            <label className="btn btn-info custom-file-upload">
                                                <input type="file" name="file" className="form-control" onChange={onFileSelected}/>
                                                <FaFile /> Seleziona documento
                                            </label>&nbsp;
                                            <button type="button" className="btn btn-success" disabled={!fileToUpload} onClick={doUpload}>
                                                <FaFileUpload /> Conferma                                    </button>
                                        </div>
                                    </form>
                                </div>
                            } 
                        
                            <div className="file-list">
                                { !docList && 
                                    <p>In caricamento...</p>
                                }
                                { (docList && docList.length>0) &&
                                    <div>{docList}</div>
                                }
                                { (docList && docList.length===0) &&
                                    <p>Nessun documento</p>
                                }
                            </div>
                        </div>
                    </Panel>
                    {exam.parent_exam_id &&
                        <Panel spacing="big" header={"DOCUMENTI VISITA DI RIFERIMENTO (" + parentDocCount + ")"} defaultState="closed">
                            <div className="file-list">
                                { !parentDocList && 
                                    <p>In caricamento...</p>
                                }
                                { (parentDocList && parentDocList.length>0) &&
                                    <div>{parentDocList}</div>
                                }
                                { (parentDocList && parentDocList.length===0) &&
                                    <p>Nessun documento</p>
                                }
                            </div>
                        </Panel>
                    }
                </Card.Body>
            </Card>

        </div>
    )
}
