
import React, { FC, useEffect } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { Backdrop } from "./backdrop";
import { motion, useCycle } from "framer-motion";

type LoadingProps = {
    message?: string;
    show: boolean;
    backdrop?: boolean;
}

export const Loading: FC<LoadingProps> = ({show, message, backdrop = false}) => {

    const [color, setColor] = useCycle("#999", "#333");

    useEffect(() => {
        const interval = setInterval(() => {
            setColor();
        }, 400);
        return () => clearInterval(interval);
    }, [setColor]);

    if(!show){
        return <div/>
    }
    if(backdrop){
        return <Backdrop>
            <Spinner style={{margin:'auto'}} animation="border" variant="info" /> 
            <span color="white">{message || 'Caricamento...'}</span>
        </Backdrop>
    }
    return <Alert variant="info">
        <div className="d-flex align-items-center">
            <Spinner animation="border" variant="info" /> 
            <div>
                <motion.h5 transition={{duration: 0.4}} style={{margin: '0rem 0rem 0rem 1rem'}} animate={{color}}>{message || 'Caricamento...'}</motion.h5>
            </div>
        </div></Alert>
}
