
import { PatientData } from "../../types/patient"
import React, { FC, useContext } from "react"
import { Genre } from "../common/genre"
import { Age } from "../common/age"
import { formatDT } from "../../utils/dateutils"
import { FormGroup, Form, Row, Col } from "react-bootstrap"
import { useFormikContext, FastField } from "formik"
import { DatePickerField } from "../common/date_picker"
import { GenderInput } from "../common/genderInput"
import { Auth } from "../../services/auth"
import { ExamContext } from "../exam/examcontext"

type PatientFormProps = {
    entityData: PatientData;
    editing: boolean;
}

/**
 * Questo componenente va usato all'interno di un context formik!
 */
export const PatientForm: FC<PatientFormProps> = ({ entityData, editing }) => {

    const { values, handleChange } = useFormikContext<PatientData>();
    const examCtx:any = useContext(ExamContext);

    const user=Auth.user()?.id;    
    const isAdmin:boolean = Auth.isAdmin();
    const isTemporary:boolean=Auth.isTemporary();
    const isInvited=(!isAdmin && examCtx!=null && examCtx?.exam?.owner_id !== user);

    const isFullView=!isTemporary || isAdmin;

    return (
        <div>
            {entityData.patient && 
                <div>
                    { isFullView &&
                        <FormGroup as={Row}>
                            <Form.Label column sm="4" lg="3">COGNOME: </Form.Label>
                            <Col sm="8" lg="9" className="form-value">
                                {editing ? <FastField as={Form.Control} name="patient.surname" onChange={handleChange} value={values.patient.surname}/> : <p>{entityData.patient.surname}</p>}
                            </Col>
                        </FormGroup>
                    }
                    { isFullView &&
                        <FormGroup as={Row}>
                            <Form.Label column sm="4" lg="3">NOME: </Form.Label>
                            <Col sm="8" lg="9" className="form-value">
                                {editing ? <FastField as={Form.Control} name="patient.name" onChange={handleChange} value={values.patient.name}/> : <p>{entityData.patient.name}</p>}
                            </Col>
                        </FormGroup>
                    }
                    <FormGroup as={Row}>
                        <Form.Label column sm="4" lg="3">GENERE: </Form.Label>
                        <Col sm="8" lg="9" className="form-value">
                            {editing ? <GenderInput name="patient.genre"/> : <p><Genre genre={entityData.patient.genre}></Genre></p>}
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                        <Form.Label column sm="4" lg="3">DATA DI NASCITA: </Form.Label>
                        <Col sm="8" lg="9" className="form-value">
                            {editing ? <DatePickerField name="patient.date_of_birth" placeholder="DD/MM/YYYY"/> : <p>{formatDT(entityData.patient.date_of_birth)}</p>}
                        </Col>
                    </FormGroup>
                    <Row className="form-group">
                        <Col sm="4" lg="3"><label className="col-form-label">ET&Agrave;:</label></Col>
                        <Col sm="8" lg="9" className="form-value">
                            <Age birth={entityData.patient.date_of_birth}></Age>
                        </Col>
                    </Row>
                    { isFullView &&
                        <FormGroup as={Row}>
                            <Form.Label column sm="4" lg="3">CODICE FISCALE: </Form.Label>
                            <Col sm="8" lg="9" className="form-value">
                                {editing ? <FastField as={Form.Control} maxLength={16} name="patient.taxcode" onChange={handleChange} value={values.patient.taxcode}/> : <p>{entityData.patient.taxcode}</p>}
                            </Col>
                        </FormGroup>
                    }
                </div>
            }
            {entityData.address && values.address && isFullView &&
                <div className="d-flex fieldsets">
                    <fieldset>
                        <legend>Indirizzo</legend>
                        <FormGroup as={Row}>
                            <Form.Label column sm="4">INDIRIZZO 1: </Form.Label>
                            <Col sm="8" className="form-value">
                                {editing ? <FastField as={Form.Control} name="address.address_line1" onChange={handleChange} value={values.address.address_line1}/> : <p>{entityData.address.address_line1}</p>}
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column sm="4">INDIRIZZO 2: </Form.Label>
                            <Col sm="8" className="form-value">
                                {editing ? <FastField as={Form.Control} name="address.address_line2" onChange={handleChange} value={values.address.address_line2}/> : <p>{entityData.address.address_line2}</p>}
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column sm="4">CAP: </Form.Label>
                            <Col sm="8" className="form-value">
                                {editing ? <FastField as={Form.Control} name="address.zipcode" onChange={handleChange} value={values.address.zipcode}/> : <p>{entityData.address.zipcode}</p>}
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column sm="4">CITT&Agrave;: </Form.Label>
                            <Col sm="8" className="form-value">
                                {editing ? <FastField as={Form.Control} name="address.city" onChange={handleChange} value={values.address.city}/> : <p>{entityData.address.city}</p>}
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column sm="4">STATO: </Form.Label>
                            <Col sm="8" className="form-value">
                                {editing ? <FastField as={Form.Control} name="address.country" onChange={handleChange} value={values.address.country}/> : <p>{entityData.address.country}</p>}
                            </Col>
                        </FormGroup>
                    </fieldset>
                    <fieldset>
                        <legend>Contatti</legend>
                        <FormGroup as={Row}>
                            <Form.Label column sm="4">EMAIL: </Form.Label>
                            <Col sm="8" className="form-value">
                                {editing ? <FastField as={Form.Control} name="address.email" onChange={handleChange} value={values.address.email}/> : <p>{entityData.address.email}</p>}
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column sm="4">TELEFONO FISSO: </Form.Label>
                            <Col sm="8" className="form-value">
                                {editing ? <FastField as={Form.Control} name="address.cell1" onChange={handleChange} value={values.address.cell1}/> : <p>{entityData.address.cell1}</p>}
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Form.Label column sm="4">CELLULARE: </Form.Label>
                            <Col sm="8" className="form-value">
                                {editing ? <FastField as={Form.Control} name="address.cell2" onChange={handleChange} value={values.address.cell2}/> : <p>{entityData.address.cell2}</p>}
                            </Col>
                        </FormGroup>
                    </fieldset>
                </div>
            }
        </div>
    )

}