
import React, {FC, useCallback, useState, useEffect, useContext, useMemo} from 'react';
import { useNotification } from '../../utils/hooks';
import { Row, Button, Alert, Modal, ModalBody } from 'react-bootstrap';
import { Media } from '../../types/media';
import { Loading } from '../common/loading';
import { USER_CHANNEL } from '../../services/notificationservice';
import { MediaAdd } from '../../types/events/mediaadd';
import { toast } from 'react-toastify';
import { buildGetFetch, buildDeleteFetch } from '../../services/base';
import { MediaComparator } from './mediacomparator';
import { ImageCard } from './imagecard';
import { ExamContext } from './examcontext';
import { IoMdImages } from 'react-icons/io';
import { Panel } from '../common/panel';
import { MdAccessibility,MdLinkedCamera } from 'react-icons/md';
import { BodyCollector } from './bodycollector';

interface ExamMediaContainerProps {
}

const ExamMediaContainer: FC<ExamMediaContainerProps> = () => {
    const {exam, changeTab,isOwner} = useContext(ExamContext);
    const [images, setImages] = useState<Array<Media>>([]);
    const [imagesRef, setImagesRef] = useState<Array<Media>>([]);
    const [error, setError] = useState<boolean>(false);
    const [showCompare, setShowCompare] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [compare, setCompare] = useState<Array<Media>>([]);
    const [showTakeImages, setShowTakeImages] = useState<boolean>(false);
    const [showBody, setShowBody] = useState<boolean>(false);

    const refreshMedia=useCallback(() => {
        const [fetch, cancel] = buildGetFetch<Array<Media>>(`/user/exam/${exam.id}/media`, {}, setImages, setLoading, setError);
        fetch().then(() => {
            const [fetchRef] = buildGetFetch<Array<Media>>(`/user/exam/${exam.id}/related_media`, {}, setImagesRef);
            fetchRef();
        });
        return () => {cancel();}
    },[exam]);

    useEffect(() => {
        refreshMedia();
    }, [refreshMedia])

    const doRefreshMedia=useCallback(() => {
        changeTab('execution');
        refreshMedia();
    },[changeTab,refreshMedia]);
    
    const doHideTakeImages=useCallback(() => {
        setShowTakeImages(false)
    },[setShowTakeImages]);    
    
    useNotification(USER_CHANNEL.events.ImageAdd, (e: MediaAdd) => {
        if(images.findIndex( (m) => m.id === e.media.id) < 0 ){
            changeTab('execution');
            toast.info(`Nuova immagine caricata`);
            e.media.isNotified = true;
            setImages((prevImages) => [e.media, ...prevImages]);
        }
    });

    const deleteMedia = useCallback((m: Media) => {
        const [deleteFetch] = buildDeleteFetch(`/media/${m.id}`);
        deleteFetch().then((success: boolean) => {
            if(success){
                setImages((prevImages) => prevImages.filter((i: Media)=> i.id !== m.id));
                toast.success("Immagine eliminata");
            } else {
                toast.error("Errore nell'eliminare l'immagine");
            }
        })
    }, []);

    const toggleCompare = useCallback((media: Media, state: boolean) => {
        if(state) {
            //add
            setCompare((prev) => [...prev, media]);
        } else {
            //remove
            setCompare((prev) => prev.filter((m) => m.id !== media.id));
        }
    }, []);

    const doShowBody = useCallback((e) => {
        setShowBody(true);
    }, []);

    const doHideBody = useCallback(() => {
        setShowBody(false);
        refreshMedia();
    }, []);

    const imagesList = useMemo(() => {
        return images.filter((m) => m.user_id !== null).map((m: Media) => {
            return (
                <ImageCard key={m.id} onDelete={() => {deleteMedia(m)}} toggleCompare={(state: boolean) => {toggleCompare(m, state)}} media={m} notified={m.isNotified || false}/>
            );
        });
    }, [images,deleteMedia,toggleCompare]);

    const imagesPatientList = useMemo(() => {
        return images.filter((m) => m.patient_id !== null).map((m: Media) => {
            return (
                    <ImageCard key={m.id} onDelete={() => {deleteMedia(m)}} toggleCompare={(state: boolean) => {toggleCompare(m, state)}} media={m} notified={m.isNotified || false}/>
                );
        });
    }, [images,toggleCompare]);

    const imagesRefList = useMemo(() => {
        return imagesRef.map((m: Media) => {
            return (
                    <ImageCard key={m.id} toggleCompare={(state: boolean) => {toggleCompare(m, state)}} media={m} />
                );
        });
    }, [imagesRef,toggleCompare]);

    return (
        <div>
            <Loading message="Caricamento immagini..." show={loading}/>
            {!loading && 
                <div>
                    <div className="action-menu">
                        <Button variant="primary" onClick={doShowBody}>
                            <MdAccessibility /> Gestione e caricamento immagini
                        </Button>

                        {/*
                        <label htmlFor="image-upload" className="custom-file-upload">
                            <MdLinkedCamera /> Carica immagini
                        </label>
                        <input type="file" id="image-upload" onChange={(e) => doUploadImage(e)} />
                        */}

                        {/*
                        <Button variant="primary" onClick={doAlertTakeImages}>
                            <MdLinkedCamera /> Carica immagini
                        </Button>
                        */}
                        <Button variant="info" disabled={compare.length <= 1} onClick={() => {
                                if(compare.length > 0 && !showCompare){
                                    setShowCompare(true);
                                }
                            }}><IoMdImages size={"1.5rem"}/>&nbsp;{compare.length <= 1 ? 'Seleziona più immagini per confrontare' : `Confronta immagini (${compare.length})`}
                        </Button>   
                    </div>
                    {showCompare && <MediaComparator media={compare} close={() => {setShowCompare(false);}}></MediaComparator>}
                    {imagesList.length === 0 && !loading &&
                        <Alert variant="info">
                            <Alert.Heading>
                                Nessuna immagine presente
                            </Alert.Heading>
                            <hr/>
                            Utilizza l'app per caricare le immagini
                        </Alert>}
                    {imagesList.length > 0 && <div className="m-3">
                        <Row className="image-slider">{imagesList}</Row>
                    </div>}
                    {imagesRefList.length > 0 && <div className="m-3">
                        <Panel defaultState="closed" spacing="big" header="Immagini visite collegate">
                            <Row className="image-slider">{imagesRefList}</Row>            
                        </Panel>
                    </div>}
                    {imagesPatientList.length > 0 && <div className="m-3">
                        <Panel defaultState="closed" spacing="big" header="Immagini raccolte dal paziente">
                            <Row className="image-slider">{imagesPatientList}</Row>            
                        </Panel>
                    </div>}
                </div>
            }
            { showTakeImages &&
                <Modal size="xl" show={true} backdrop="static" keyboard={false}>
                    <Modal.Body>
                        <h5>
                        Procedi al caricamento delle immagini tramite il dermatoscopio in dotazione
                        </h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => doHideTakeImages() }>Chiudi</Button>
                    </Modal.Footer>
                </Modal>
            }

            { showBody &&
                <Modal show={true} size='xl'>
                    <ModalBody>
                        <BodyCollector />
                    </ModalBody>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => doHideBody() }>Chiudi</Button>
                    </Modal.Footer>
                </Modal>
            }
        </div>)
}

export {ExamMediaContainer};